import React, { useEffect, useRef, useState } from 'react'
import img from '../../Images/HomePage/Rectangle 735.png';
import { HiOutlineHome } from 'react-icons/hi';
import { CiSearch } from 'react-icons/ci';
import { FaChevronDown } from 'react-icons/fa';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { teal } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import { Divider } from '@mui/material'
import { useAuth } from '../../Context/ContextProvider'
import { price_range, area_range, bedrooms } from '../../AgentDashBorad/Component/dummyData'
import { useNavigate, useParams } from 'react-router-dom'
function Banner({ setData, data, setPagination, setPropertyName, city_value, setCityValue }) {
  const parms = useParams()
  const handleChange1 = (event) => {
    setData({ ...data, unit_area: event.target.value });
  };
  const controlProps1 = (item) => ({
    checked: data?.unit_area === item,
    onChange: handleChange1,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });
  const { setCurrentCity, currentCity, type, area_unit, currency, setcityData, allcity, cityData, setPropertyData, serchProperty, getTypePublic, getcateGoryPublic, formatPriceWithCommas, getCity, getLOcation } = useAuth()
  const [category, setCategory] = useState([])
  const [sub_category, setsub_category] = useState([])
  const [moreOption, setMoreOption] = useState(false)
  const [propertyType, setProperty] = useState(false)
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [location, setLocation] = useState([])
  const [slugProperty, setSlugProperty] = useState()
  const [isSearch, setIsSerch] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const open4 = Boolean(anchorEl4);
  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClose4 = () => {
    setAnchorEl4(null);
  };
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const open5 = Boolean(anchorEl5);
  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl5(null);
  };
  const [active_categorie, setActive_categorie] = useState()
  const wrapperRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      setCityValue((prev) => ({
        ...prev,
        city: currentCity?.city_name || "Lahore",
      }));
      const selectedTypeId =
        type?.find((item) => item?.name === parms?.id)?.id || type?.[0]?.id;
      const selectedTypeSlug =
        type?.find((item) => item?.name === parms?.id)?.slug || type?.[0]?.slug;
      const selectedTypeName =
        type?.find((item) => item?.name === parms?.id)?.name || type?.[0]?.name;
      const selectedTypeCategory =
        type?.find((item) => item?.name === parms?.id)?.categories || [];
      setPropertyName(selectedTypeName);
      setSlugProperty(selectedTypeSlug);
      let updatedData = {
        ...data,
        property_type_id: selectedTypeId,
        city_code: currentCity?.app_code || "PP016",
      };
      setData(updatedData)
      setActive_categorie(selectedTypeCategory[0]?.id)
      try {
        const results = await serchProperty(updatedData);
        if (results?.success) {
          setPagination(results?.data?.data?.totalPages);
        }
        if (selectedTypeCategory?.length) {
          setCategory(selectedTypeCategory);
          let selectedTypeSubCategory = selectedTypeCategory[0].sub_categories
          if (selectedTypeSubCategory) {
            setsub_category(selectedTypeSubCategory || []);
          }
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (type?.length) {
      fetchData();
    }
  }, [parms?.id, type]);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShow1(false);
      setShow(false);
      if (!data?.city_code && !data?.location_id) {
        setCityValue({
          city: currentCity?.city_name || "Lahore",
          location: ""
        })
      }
      else if (!data?.city_code) {
        setCityValue({
          ...city_value,
          city: currentCity?.city_name || "Lahore",
        })
      }
      else {
        setCityValue({
          ...city_value,
          location: "",
        })
      }
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [parms?.id]);
  const navigate =useNavigate()
  const unitsToRemove = ["Square Yards", "Square Feet", "Square Meters"];
  return (
    <div className='container-fluid main_banner_home'>

      <div className='banner_section img_banner'>
        <img src={img} id="bgImage" alt='banner-image' />
        {/* <div cl style={{background:"black",position:"absolute",width:"100%",height:"100%"}}></div> */}
        {/* <img src={img} id="bgVideo" alt='banner-image'/> */}
        <div style={{background:"none"}} class="content">
          <div className='p-0 m-0 w-100'>
            <h1>EXPLORE, DISCOVER, LIVE</h1>
            <p>We are a real estate agency that will help you find the best residence you dream of,<br /> let’s discuss for your dream house?</p>
          </div>
          <div className='tab_banner p-0 m-0'>
            <div>
              {type?.map((item) => {
                return (
                  <button
                    onClick={async () => {
                      let result1 = type?.find((items) => items.id === item.id)?.categories;
                      setSlugProperty(item?.slug)
                      if (result1?.length) {
                        const subcategories = result1[0].sub_categories;
                        if (subcategories?.length) {
                          setData({ ...data, property_type_id: item?.id, category_id: result1[0].id })
                          setCategory(result1);
                          setsub_category(subcategories)
                        }
                        else {
                          setData({ ...data, property_type_id: item?.id, category_id: result1[0].id })
                          setCategory(result1);
                        }
                      }
                      else {
                        setData({ ...data, property_type_id: item?.id })
                      }
                    }
                    } className='tb_btn' style={{ background: `${data?.property_type_id === item?.id ? "#1C9093" : ""}`, color: `${data?.property_type_id === item?.id ? "white" : ""}` }}>{item?.name === "Sell" ? "Buy" : item?.name}</button>
                )
              })}
              {/* <button className='tb_btn' style={{ background: `${!buy ? "white" : ""}` }} onClick={() => setBuy(false)}>Rent</button> */}
            </div>
            <div className='d-flex flex-wrap justify-content-between tab_section'>
              <div className='d-flex'>
                <div style={{ position: "relative" }} className='select_banner'>
                  <HiOutlineHome className='text-dark' />
                  <input onClick={() => setShow(true)}
                    value={city_value?.city} onChange={async (e) => {
                      setCityValue({ ...city_value, city: e.target.value })
                      if (e.target.value) {
                        setShow(true)
                        let array = allcity?.filter((item) => item?.city?.toLowerCase().includes(e.target.value?.toLowerCase()));
                        setcityData(array)
                      }
                      else {
                        setShow(false)
                        setcityData(allcity)
                      }
                    }} type='text' placeholder='Search City' /> <FaChevronDown style={{ color: "lightgray" }} />
                  {show && <ul ref={wrapperRef} style={{ position: "absolute", top: "110%", color: "black", textAlign: "start" }}>
                    {cityData?.map((item) => {
                      return (
                        <li onClick={() => {
                          setCityValue({ ...city_value, city: item?.city })
                          setData({ ...data, city_code: item?.app_code })
                          setShow(false)
                          setCurrentCity({
                            city_name: item?.city,
                            app_code: item?.app_code
                          })
                        }}>{item?.city}</li>
                      )
                    })}
                  </ul>}
                </div>
                <Divider style={{ borderColor: "black", height: "20px", border: "1px solid", marginTop: "10px", }} orientation="vertical" flexItem />
                <div style={{ position: "relative" }} className='select_banner'>
                  <CiSearch className='text-dark' />
                  <input value={city_value?.location} onClick={() => setShow1(true)}
                    onChange={async (e) => {
                      setCityValue({ ...city_value, location: e.target.value })
                      if (e.target.value) {
                        setShow1(true)
                      }
                      else {
                        setShow1(false)
                      }
                      let result = await getLOcation({
                        city_code: data?.city_code,
                        keyword: e.target.value,
                      });
                      if (result?.success) {
                        setLocation(result?.data.status === false ? [] : result?.data);
                      }
                    }}
                    type='search' placeholder='Search by location' /> <FaChevronDown style={{ color: "lightgray" }} />
                  {show1 && <ul ref={wrapperRef} style={{ position: "absolute", top: "110%", color: "black", textAlign: "start" }}>
                    {location?.map((item) => {
                      return (
                        <li onClick={() => {
                          setCityValue({ ...city_value, location: item?.name })
                          setData({ ...data, location_id: item?.id })
                          setShow1(false)
                        }}>{item?.name}</li>
                      )
                    })}
                  </ul>}
                </div>
              </div>
              <div className='select_banner_btn'>
                <button onClick={() => setMoreOption(!moreOption)}>{moreOption ? "Less" : "More"} Options <FaChevronDown className={`${moreOption ? "move_icon move_icon_ani" : "move_icon_ani"}`} style={{ color: "white" }} /></button>
                <Divider className='mt-1' style={{ borderColor: "lightgray", height: "15px", border: "1px solid" }} orientation="vertical" flexItem />
                <button onClick={async () => {
                  let result = await serchProperty(data)
                  if (result?.success) {
                    type?.map((item) => {
                      if (item?.id === data?.property_type_id) {
                        navigate(`/listing-property/${item?.name}`)
                      }
                      return null
                    })
                  }
                }}>Search</button>
              </div>
            </div>
          </div>
          {moreOption && <>
            <div className='mor_option d-flex flex-wrap justify-content-between my-2'>
              <div onClick={() => setProperty(!propertyType)} className='more_div mt-2'>
                {/* Property Type<FaChevronDown /> */}
                <div>
                  <label> Property Type</label>
                  <div>

                    <input readOnly value={data?.category_id ? (category?.find((item) => item.id === parseInt(data?.category_id))?.name) : "All"} style={{ width: "100%", height: "20px", border: "none", outline: "none", fontSize: "14px", cursor: "pointer" }} />
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-end", height: "100%", fontSize: "13px", paddingBottom: ".3rem" }}><FaChevronDown /></div>
              </div>
              <div id="basic-button"
                aria-controls={open2 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? 'true' : undefined}
                onClick={handleClick2} className='more_div mt-2'>
                {/* {data?.price?.min && data?.price?.max?`${data?.price?.min+" "+"to"+" "+ data?.price?.max}`: "Price"} ({currency?.find((item) => item.id === parseInt(data?.currency))?.name})<FaChevronDown /> */}
                <div>
                  <label>Price {data?.currency ?
                    currency?.find((item) => item.id === data?.currency)?.name
                    : "(any)"
                  } </label>
                  <div>
                    <input readOnly value={data?.price?.min !== "" ? data?.price?.min : "0"} style={{ width: "60px", height: "20px", border: "none", outline: "none", fontSize: "14px", cursor: "pointer" }} /> <span>to</span> <input readOnly value={data?.price?.max !== "" ? data?.price?.max : "Any"} style={{ width: "60px", height: "20px", border: "none", outline: "none", fontSize: "14px", cursor: "pointer", paddingLeft: ".5rem" }} />
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-end", height: "100%", fontSize: "13px", paddingBottom: ".3rem" }}><FaChevronDown /></div>
              </div>
              <div
                id="basic-button"
                aria-controls={open3 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open3 ? 'true' : undefined}
                onClick={handleClick3} className='more_div mt-2'>
                {/* {data?.area_size?.min && data?.area_size?.max?`${data?.area_size?.min+" "+"to"+" "+ data?.area_size?.max}`: "Area"} ({area_unit?.find((item) => item.id === data?.unit_area)?.name})<FaChevronDown /> */}
                <div>
                  <label>Area  {data?.unit_area ? unitsToRemove.reduce((acc, unit) => {
                    return acc.replace(unit, "");
                  }, area_unit?.find((item) => item.id === data?.unit_area)?.name || "")
                    : "(any)"
                  }</label>
                  <div>
                    <input readOnly value={data?.area_size?.min !== "" ? data?.area_size?.min : "0"} style={{ width: "60px", height: "20px", border: "none", outline: "none", fontSize: "14px", cursor: "pointer" }} /> <span>to</span> <input readOnly value={data?.area_size?.min !== "" ? data?.area_size?.min : "Any"} style={{ width: "60px", height: "20px", border: "none", outline: "none", fontSize: "14px", cursor: "pointer", paddingLeft: ".5rem" }} />
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-end", height: "100%", fontSize: "13px", paddingBottom: ".3rem" }}><FaChevronDown /></div>
              </div>
              {slugProperty !== "lease" && <div
                id="basic-button"
                aria-controls={open4 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open4 ? 'true' : undefined}
                onClick={handleClick4} className='more_div mt-2'>
                <div>
                  <label>Bedrooms</label>
                  <div>
                    <input readOnly value={data?.rooms !== "" ? data?.rooms : "All"} style={{ width: "60px", height: "20px", border: "none", outline: "none", fontSize: "14px", cursor: "pointer" }} />
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-end", height: "100%", fontSize: "13px", paddingBottom: ".3rem" }}><FaChevronDown /></div>
              </div>}
              {slugProperty === "lease" && <div
                id="basic-button"
                aria-controls={open5 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open5 ? 'true' : undefined}
                onClick={handleClick5} className='more_div mt-2'>
                <div>
                  <label>Lease Type</label>
                  <div>
                    <input readOnly value={data?.rooms !== "" ? data?.rooms : "Select Lease Type"} style={{ width: "60px", height: "20px", border: "none", outline: "none", fontSize: "14px", cursor: "pointer" }} />
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-end", height: "100%", fontSize: "13px", paddingBottom: ".3rem" }}><FaChevronDown /></div>
              </div>}
            </div>
            {propertyType && <div className='tab_pan_main' style={{ background: "white", borderRadius: "5px", padding: "1rem 0" }}>
              <div className='tab_pan d-flex justify-content-center'>
                {category?.map((item) => {
                  return (
                    <h6
                      onClick={() => {
                        setData({ ...data, category_id: item?.id })
                        setsub_category(item?.sub_categories)
                      }}
                      className={`${item?.id === active_categorie && "active_tab"}`} >{item?.name}</h6>
                  )
                })}
              </div>
              <div className='option_tabs'>
                {sub_category?.map((item) => {
                  return (
                    <p style={{ cursor: "pointer" }} onClick={() => {
                      setData({ ...data, sub_category_id: item?.id })
                    }} className={`${item?.id === data?.sub_category_id ? "active_btn_home" : ""}`}
                    >{item?.name}</p>

                  )
                })}

              </div>

            </div>}

          </>}
          <div className='unit_ul my-2'>
            <ul>
              <li
              ><button id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick} style={{ padding: "0", margin: "0", background: "none", border: "none", color: "white" }}>Change Area Unit</button></li>
              <li  ><button id="basic-button"
                aria-controls={open1 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={handleClick1} style={{ padding: "0", margin: "0", background: "none", border: "none", color: "white" }}>Change Currency</button>
              </li>
              <li onClick={async () => {
                if (isSearch) {
                  let result = await serchProperty({
                    city_code: "PP016",
                    location_id: "",
                    property_type_id: type?.find((item) => item?.name === parms?.id)?.id || type[0]?.id,
                    sub_category_id: "",
                    currency: "",
                    unit_area: "",
                    status: "",
                    rooms: "",
                    price: {
                      min: "",
                      max: ""
                    },
                    area_size: {
                      min: "",
                      max: ""
                    },
                  })
                  setPropertyName(type?.find((item) => item?.name === parms?.id)?.name || type[0]?.name)
                  setData({
                    city_code: "PP016",
                    location_id: "",
                    property_type_id: type?.find((item) => item?.name === parms?.id)?.id || type[0]?.id,
                    sub_category_id: "",
                    currency: "",
                    unit_area: "",
                    status: "",
                    rooms: "",
                    price: {
                      min: "",
                      max: ""
                    },
                    area_size: {
                      min: "",
                      max: ""
                    },
                  })
                  setCityValue({ city: "Lahore", location: "" })
                  if (result?.success) {
                    setPropertyData(result?.data?.data?.properties)
                    setIsSerch(false)
                  }
                }

              }} style={{ border: "none", cursor: `${isSearch ? "pointer" : "not-allowed"}` }}>Reset Search</li>
            </ul>
            <div className='menu_item'>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl1}
                open={open1}
                style={{ paddingTop: "1rem" }}
                onClose={handleClose1}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {currency?.map((item) => {
                  return (
                    <MenuItem><p className='m-0'><Radio
                      {...controlProps1(item?.id)}
                      sx={{
                        color: teal[800],
                        margin: "0",
                        padding: "0",
                        '&.Mui-checked': {
                          color: teal[600],
                        },
                      }}
                    /> {item?.name}</p></MenuItem>
                  )
                })}
              </Menu>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                style={{ paddingTop: "1rem" }}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {area_unit?.map((item) => {
                  return (
                    <MenuItem><p className='m-0'><Radio
                      {...controlProps1(item?.id)}
                      sx={{
                        color: teal[800],
                        margin: "0",
                        padding: "0",
                        '&.Mui-checked': {
                          color: teal[600],
                        },
                      }}
                    /> {item?.name}</p></MenuItem>
                  )
                })}

              </Menu>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div className='price_filter'>
                  <div className='d-flex filter_title justify-content-between align-items-center'>
                    <p className='p-0 m-0'>Budget Range</p>
                    <button id="basic-button"
                      style={{ position: "relative" }}
                      aria-controls={open1 ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open1 ? 'true' : undefined}
                      onClick={handleClick1}>{currency?.find((item) => item.id === parseInt(data?.currency))?.name} <FaChevronDown /></button>
                  </div>
                  <div className='row m-0 my-2'>
                    <div className='col-6'>
                      <h6>Min</h6>
                    </div>
                    <div className='col-6'>
                      <h6>Max</h6>

                    </div>
                  </div>
                  <div className='row m-0 mb-2'>
                    <div className='col-6 p-0 px-2'>
                      <input style={{ width: "100%", border: "1px solid lightgray", borderRadius: "5px", outline: "none" }} placeholder='0' value={data?.price?.min ? data?.price?.min : 0} onChange={(e) => {
                        let newData = data?.price
                        newData.min = e.target?.value
                        setData({ ...data, price: newData })
                      }} />
                    </div>
                    <div className='col-6 p-0 px-2'>
                      <input style={{ width: "100%", border: "1px solid lightgray", borderRadius: "5px", outline: "none" }} placeholder='Any' value={data?.price?.max ? data?.price?.max : 0} onChange={(e) => {
                        let newData = data?.price
                        newData.max = e.target?.value
                        setData({ ...data, price: newData })
                      }} />

                    </div>
                  </div>
                  <div className='row m-0'>
                    {price_range?.map((item) => {
                      return (
                        <div className='col-12 m-0 p-0 px-2 d-flex flex-wrap justify-content-between' >
                          <h5 onClick={() => setData({ ...data, price: { ...data?.price, min: item?.min } })} style={{ width: "45%", background: `${item?.min === data?.price?.min ? "#1C9093" : ""}`, color: `${item?.min === data?.price?.min ? "white" : ""}` }}>{item?.min}</h5>
                          <h5 onClick={() => setData({ ...data, price: { ...data?.price, max: item?.max } })} style={{ width: "45%", background: `${item?.max === data?.price?.max ? "#1C9093" : ""}`, color: `${item?.max === data?.price?.max ? "white" : ""}` }}>{item?.max}</h5>
                        </div>
                      )
                    })}



                  </div>
                </div>
              </Menu>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl3}
                open={open3}
                onClose={handleClose3}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div className='price_filter'>
                  <div className='d-flex filter_title justify-content-between align-items-center'>
                    <p className='p-0 m-0'>Area Range</p>
                    <button id="basic-button"
                      style={{ position: "relative" }}
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}>{data?.unit_area ? unitsToRemove.reduce((acc, unit) => {
                        return acc.replace(unit, "");
                      }, area_unit?.find((item) => item.id === data?.unit_area)?.name || "")

                        : "(any)"
                      } <FaChevronDown /></button>
                  </div>
                  <div className='row m-0 my-2'>
                    <div className='col-6'>
                      <h6>Min</h6>
                    </div>
                    <div className='col-6'>
                      <h6>Max</h6>

                    </div>
                  </div>
                  <div className='row m-0 mb-2'>
                    <div className='col-6 p-0 px-2'>
                      <input style={{ width: "100%", border: "1px solid lightgray", borderRadius: "5px", outline: "none" }} placeholder='0' value={formatPriceWithCommas(data?.area_size?.min)} onChange={(e) => {
                        let newData = data?.area_size
                        newData.min = e.target?.value
                        setData({ ...data, area_size: newData })
                      }} />
                    </div>
                    <div className='col-6 p-0 px-2'>
                      <input style={{ width: "100%", border: "1px solid lightgray", borderRadius: "5px", outline: "none" }} placeholder='Any' value={formatPriceWithCommas(data?.area_size?.max)} onChange={(e) => {
                        let newData = data?.area_size
                        newData.max = e.target?.value
                        setData({ ...data, area_size: newData })
                      }} />

                    </div>
                  </div>
                  <div className='row m-0'>
                    {area_range?.map((item) => {
                      return (
                        <div className='col-12 m-0 p-0 px-2 d-flex flex-wrap justify-content-between' >
                          <h5 onClick={() => setData({ ...data, area_size: { ...data?.area_size, min: item?.min } })} style={{ width: "45%", background: `${item?.min === data?.area_size?.min ? "#1C9093" : ""}`, color: `${item?.min === data?.area_size?.min ? "white" : ""}` }}>{item?.min}</h5>
                          <h5 onClick={() => setData({ ...data, area_size: { ...data?.area_size, max: item?.max } })} style={{ width: "45%", background: `${item?.max === data?.area_size?.max ? "#1C9093" : ""}`, color: `${item?.max === data?.area_size?.max ? "white" : ""}` }}>{item?.max}</h5>
                        </div>
                      )
                    })}

                  </div>
                </div>
              </Menu>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl4}
                open={open4}
                onClose={handleClose4}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div className='price_filter'>
                  <div className='row m-0'>
                    <div className='col-12 m-0 p-0 px-2'>
                      <h5>All</h5>
                      {bedrooms?.map((item) => {
                        return (
                          <h5 onClick={() => setData({ ...data, rooms: item })} style={{ background: `${item === data?.rooms ? "#1C9093" : ""}`, color: `${item === data?.rooms ? "white" : ""}` }}>{item}</h5>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Menu>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl5}
                open={open5}
                onClose={handleClose5}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div className='price_filter'>
                  <div className='row m-0'>
                    <div className='col-12 m-0 p-0 px-2'>

                      <h5 onClick={() => setData({ ...data, lease_type: "now" })} style={{ background: `${"now" === data?.lease_type ? "#1C9093" : ""}`, color: `${"now" === data?.lease_type ? "white" : ""}`, cursor: "pointer" }}>Available</h5>
                      <h5 onClick={() => setData({ ...data, lease_type: "soon" })} style={{ background: `${"soon" === data?.lease_type ? "#1C9093" : ""}`, color: `${"soon" === data?.lease_type ? "white" : ""}`, cursor: "pointer" }}>Available Soon</h5>

                    </div>
                  </div>
                </div>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
