import React, { useEffect, useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Footer from '../Camponent/NavBarFooter/Footer'
import Banner from '../Camponent/profile/Banner'
// import Treands from '../Camponent/profile/Treands'
import SimilerProertey from '../Camponent/profile/SimilerProertey'
import { useAuth } from '../Context/ContextProvider'
import { useLocation } from 'react-router-dom'

function Profile() {
  const {getPropertyDetail,serchProperty}=useAuth()
  const [data,setData]=useState()
  const location = useLocation();
  const parms = { id: location.pathname.split("/")[2] };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await getPropertyDetail(parms?.id);
        if (result?.success) {
          setData(result.data?.data);
          await serchProperty({
            city_code: result?.data?.data?.property?.city_code,
          });
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };

    fetchData();
  }, [location.pathname]); // Dependency on the pathname

  return (
    <>
      <div >
      <NavBar/>
      <div className="container-fluid main_banner_home">
      <Banner profileData={data?.property}/>
      <SimilerProertey data={data}/>
      </div>
      <Footer/>
      </div>

    </>
  )
}

export default Profile
