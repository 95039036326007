import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../Context/ContextProvider';
import NavBar from '../Camponent/NavBarFooter/NavBar';
import { FiCheckCircle } from 'react-icons/fi';
import Footer from '../Camponent/NavBarFooter/Footer';

function SiteMap() {
      const { type, projecttype } = useAuth();
  return (
    <>
       <NavBar />
    <div style={{padding:"0 19px"}} className='row m-0 site-map my-5'>
    <div className='col-lg-3 col-md-6 col-12'>
    <h5>Pages</h5>
        <ul className='p-0'>
            <li className='my-2'><FiCheckCircle /><Link className='link mx-2' to={'/'}>Home</Link></li>
            <li className='my-2'><FiCheckCircle /><Link className='link mx-2' to={'/about-us'}>About us</Link></li>
            <li className='my-2'><FiCheckCircle /><Link className='link mx-2' to={'/about-us'}>Contact us</Link></li>
            <li className='my-2'><FiCheckCircle /><Link className='link mx-2' to={'/agencies'}>Real Estate Agencies</Link></li>
            <li className='my-2'><FiCheckCircle /><Link className='link mx-2' to={'/project-listing'}>Project Listing</Link></li>
            <li className='my-2'><FiCheckCircle /><Link className='link mx-2' to={'/agencies-list'}>Real Estate Agencies List</Link></li>
            <li className='my-2'><FiCheckCircle /><Link className='link mx-2' to={'/intstant-valuation'}>Instant Valuation</Link></li>
            <li className='my-2'><FiCheckCircle /><Link className='link mx-2' to={'/faqs'}>FAQs</Link></li>
            <li className='my-2'><FiCheckCircle /><Link className='link mx-2' to={'/term-and-condition'}>Terms & Condition</Link></li>
            <li className='my-2'><FiCheckCircle /><Link className='link mx-2' to={'/privacy-policy'}>Privacy Policy</Link></li>
        </ul>
    </div>
    <div className='col-lg-3 col-md-6 col-12'>
    <h5>Property type</h5>
        <ul className='p-0'>
        {type?.map((item, i) => {
              return (    <li className='my-2'><FiCheckCircle /><Link className='link mx-2' to={`/property/property-for-${item?.slug}`}>  {item?.name === "Sell" ? "Buy" : item?.name} Property</Link></li>
           
              );
            })}
        
          
        </ul>
    </div>
    <div className='col-lg-3 col-md-6 col-12'>
    <h5>Projects type</h5>
        <ul className='p-0'>
        {projecttype?.map((item, i) => {
              return (    <li  className='my-2'><FiCheckCircle /><Link className='link mx-2' to={`/project/${item?.id}`}>{item?.name}</Link></li>
           
              );
            })}
        
          
        </ul>
    </div>
    </div>
    <Footer/>
    </>
  )
}

export default SiteMap