import React, { useState } from "react";
import img from "../../Images/About/ven.png";
import img1 from "../../Images/About/Property 1=Frame 1300193073.svg";

import { aboutData } from "../../AgentDashBorad/Component/dummyData";
import { FallbackImage } from "../FallbackImage";
function Entrance() {
  const [active, setActive] = useState("High Rise");
  const [activeData, setActiveData] = useState(aboutData[0]);

  return (
    <>
      <div className="container-fluid mx-0 entrance-container main_banner_home p-xl-0">
        <div className="row my-md-4  mb-0 mx-0 p-0">
          {/* <div className="main-img d-flex">
                        <div className="animated-img d-flex">

                        <img className="w-100 h-100" src={img} alt="..." />
                        <img  style={{marginLeft:'-30px'}} className="w-100 h-100 enterNextImg" src={img1} alt="..." />
                        </div>
                    </div> */}
          <div className="col-12 my-2 d-flex justify-content-center">
          <div class="marquee-w mob_marquee">
  <div class="marquee">
    
      <span>
        <img src={img} alt="" />
        Welcome to pakistanproperty.com - your gateway to a new era of real estate
      </span>
     
   
  </div>
  <div class="marquee marquee2">
    
      <span>
        <img src={img} alt="" />
        Welcome to pakistanproperty.com - your gateway to a new era of real estate
      </span>
     
   
  </div>
</div>


        </div>
        </div>
        <div className="row mt-md-4 pt-1 px-0 mx-0">
          <div className="col-xl-2 col-md-3 col-12 m-0 p-0">
            <div className="item-box my-md-4 my-1 mx-0">
              <div className="first-item mb-2 me-2"></div>
              {aboutData?.map((item) => {
                return (
                  <>
                    {active === item?.name ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setActive(item?.name);
                          setActiveData(item);
                        }}
                        className="second-item px-2 mb-3 me-2"
                      >
                        <span className="item-line"></span> {item?.name}
                      </div>
                    ) : (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setActive(item?.name);
                          setActiveData(item);
                        }}
                        className="first-item my-2"
                      >
                        {item?.name}
                      </div>
                    )}
                  </>
                );
              })}
              {/* <div className="first-item my-2">High Rise</div>
                            <div className="first-item my-2">High Rise</div> */}

              {/* <div className="first-item item-third">Lease</div>
                            <div className="first-item my-3">Instant Valuation</div>
                            <div className="first-item ">Post Property</div> */}
            </div>
          </div>

          <div className="col-xl-5 col-md-9  col-12 details-col ">
            <div className="main-parabox aboutTextbox textbox-1">
              <div className="textbox1-heading  aboutTextbox">
                {activeData?.heading}
              </div>

              <p className="para mt-3 aboutTextboxpara ">{activeData?.pera1}</p>
              <p className="para mt-3 aboutTextboxpara">{activeData?.pera2}</p>
              <p className="para mt-3 aboutTextboxpara">{activeData?.pera3}</p>
              <p className="para mt-3 aboutTextboxpara">{activeData?.pera4}</p>
            </div>
          </div>

          <div
            className="col-xl-5 col-12 colorbox-col aboutIMHS"
            style={{ paddingLeft: "75px", paddingRight: "22px" }}
          >
            <div className="colored-box">
              <FallbackImage
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
                src={activeData?.img}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Entrance;
