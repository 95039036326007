import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useAuth } from '../../Context/ContextProvider';
import logo from '../../Images/logo.png'
import logo1 from '../../Images/3275434.jpg'
import CustomizedSnackbars from './CustomizedSnackbars';
function ForgetPassword() {
    const [fieldErrors, setFieldErrors] = useState({})
    const { loading ,forget_password} = useAuth()
    const [password, setPassword] = useState(false)
    const [password1, setPassword1] = useState(false)
    let urldata = window.location.search.split("?data=")
    let token = urldata[1]
    const [data, setData] = useState({
        password_confirmation: "",
        password: ""
    })
    const validateStep = () => {
        let errors = {

        };
        if (!data.password) {
            errors.password = "Password is required.";
        }
        else if (data.password.length < 8 || data.password.length > 10) {
            errors.password = "Password must be between 8 and 10 characters long.";
        }
        else if (!hasSpecialCharacter(data.password)) {
            errors.password = "Please enter a valid email address.";
        }
        if (!data.password_confirmation) {
            errors.password_confirmation = "Confirm Password is required.";
        } else if (data.password !== data.password_confirmation) {
            errors.password_confirmation = "Passwords do not match.";
        }
        setFieldErrors(errors);

        return Object.keys(errors).length === 0;
    };
    const hasSpecialCharacter = (password) => {

        const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
        return specialCharacterRegex.test(password);
    };
    const submit = async (event) => {
        event.preventDefault();
        if (validateStep()) {
              const result = await forget_password({...data,data:token});
            //   if (result?.success) {
            //     setData({
            //       email: "",
            //       password: ""
            //     })
            //     navigate('/agent/dashboard');
            //   } else {
            //     if (result?.error?.errors?.email) {
            //       setFieldErrors({
            //         email: result?.error?.errors?.email[0]
            //       })
            //     }
            //     if (result?.error?.message) {
            //       setFieldErrors({
            //         message: result?.error?.message
            //       })
            //     }

            //     console.log('Login failed:', result);
            //   }
        }
    }
    return (
        <div className='row m-0'>
            <div className='col-lg-5 col-md-6 col-12'>
                <div className='login_modal px-4 py-5'>
                    <div className='d-flex justify-content-center p-3'>
                        <img src={logo} alt='...' />

                    </div>
                    <div className='d-flex justify-content-between mt-2'>
                        <div style={{ position: "relative" }}>
                            <p>Please reset your password</p>
                            {fieldErrors?.message && <span style={{ top: "70%", whiteSpace: "nowrap" }} className='text_error'>{fieldErrors?.message}</span>}
                        </div>

                    </div>
                    <form onSubmit={submit}>

                        <div className='inp_login'>
                            <label>New Password</label>
                            <div style={{ position: "relative" }}>
                                <input type={`${password ? "text" : "password"}`} onChange={(e) => setData({ ...data, password: e.target.value })} placeholder='New Password' />
                                {!password ? <FaRegEyeSlash onClick={() => setPassword(!password)} style={{ position: "absolute", right: "20px", top: "15px" }} /> :
                                    <FaRegEye onClick={() => setPassword(!password)} style={{ position: "absolute", right: "20px", top: "15px" }} />}
                            </div>
                            {fieldErrors?.password && <span className='text_error'>{fieldErrors?.password}</span>}
                        </div>
                        <div className='inp_login'>
                            <label>Confirm Password</label>
                            <div style={{ position: "relative" }}>
                                <input type={`${password1 ? "text" : "password"}`} onChange={(e) => setData({ ...data, password_confirmation: e.target.value })} placeholder='Confirm Password' />
                                {!password1 ? <FaRegEyeSlash onClick={() => setPassword1(!password1)} style={{ position: "absolute", right: "20px", top: "15px" }} /> :
                                    <FaRegEye onClick={() => setPassword1(!password1)} style={{ position: "absolute", right: "20px", top: "15px" }} />}
                            </div>
                            {fieldErrors?.password_confirmation && <span className='text_error'>{fieldErrors?.password_confirmation}</span>}
                        </div>

                        <button type='submit' className='Sign_inBtn'>{loading ? <div className="spinner-border text-light button_loading" role="status">

                        </div> : "Submit"}</button>
                    </form>

                </div>
            </div>
            <div style={{ boxShadow: "0 0 20px lightgray", height: "100vh" }} className='col-lg-7 col-md d-md-flex justify-content-center align-item-center d-none'>
                <img src={logo1} style={{ width: "70%" }} alt='...' />
            </div>
            <CustomizedSnackbars/>
        </div>
    )
}

export default ForgetPassword