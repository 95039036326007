import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/ContextProvider";
import img from "../../Images/dummy/placeholder.png";
import img1 from "../../Images/Agents/Frame 1300192747.png";
import img2 from "../../Images/Agents/Frame 1300192750.png";
import {
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoLocationOutline,
} from "react-icons/io5";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FallbackImage } from "../FallbackImage";
import { Link, useNavigate } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
import NoReacodFound from "../NavBarFooter/NoReacodFound";

function SecondSection() {
  const { planAgency, loading } = useAuth();
  const [featureAgencyList, setFeatureAgencyList] = useState();
  useEffect(() => {
    const fetchCart = async () => {
      try {
        let result = await planAgency();
        if (result?.success) {
          let propertyData = result?.data;
          setFeatureAgencyList(propertyData);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };

    fetchCart();
    // eslint-disable-next-line
  }, []);
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className="slick-prev custom-arrow"
        onClick={onClick}
        style={{
          borderRadius: "50%",
          fontSize: "20px",
          position: "absolute",
          top: "50%",
          left: "30px",
          zIndex: 1,
          transform: "translateY(-50%)",
        }}
      >
        <IoChevronBackOutline />
      </button>
    );
  };
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className="slick-next custom-arrow"
        onClick={onClick}
        style={{
          borderRadius: "50%",
          fontSize: "20px",
          position: "absolute",
          top: "50%",
          right: "30px",
          zIndex: 1,
          transform: "translateY(-50%)",
        }}
      >
        <IoChevronForwardOutline />
      </button>
    );
  };
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const navigate = useNavigate();

  return (
    <div className="agents conatiner-fluid main_banner_home mt-3">
      <div className="row my-sm-4 my-2 pt-sm-3 pt-2">
        <div className="col-10">
          <h2 className="agencies_heading">
            {featureAgencyList?.data?.package?.name} Agencies
          </h2>
        </div>
      </div>

      {loading ? (
        <div className="row" style={{ gap: "20px" }}>
          {[...Array(4)].map((_, colIndex) => (
            <div className="col-3 my-2" key={colIndex}>
              <span
                className="placeholder"
                style={{ width: "100%", height: "100px", borderRadius: "5px" }}
              ></span>
            </div>
          ))}
        </div>
      ) : (
        <>
          {featureAgencyList?.data?.agencies?.length > 4 && (
            <Slider {...settings}>
              {featureAgencyList?.data?.agencies?.map((card, index) => (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/agent-profile/${card?.id}`)}
                  className="card-1"
                >
                  <div className="row m-0">
                    <div className="col-3 p-0 d-flex align-items-center">
                      <div className="card-img">
                        <FallbackImage
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            objectFit: "contain",
                          }}
                          src={card?.agency_profile?.agency_image}
                          alt={"agency ptofile"}
                          fallbackSrc={img}
                        />
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="card-text px-sm-1">
                        <div className="first-heading py-0">
                          {card?.agency_profile?.agency_name}
                        </div>
                        <div className="location d-flex align-items-center">
                          <div className="location-icon m-0 p-0">
                            <IoLocationOutline className="m-0 p-0" />
                          </div>
                          <div className="location-details m-0 mt-2 p-0">
                            Lahore{" "}
                          </div>
                        </div>
                        <div className="house-box d-flex ">
                          <div className="box-1 d-flex align-items-center">
                            <div className="box1-img">
                              {" "}
                              <img className="w-100" src={img1} alt="" />
                            </div>
                            <div className="box1-text">
                              {" "}
                              {card?.sell_count} for Sale
                            </div>
                          </div>

                          <div className="box-1 d-flex align-items-center">
                            <div className="box1-img">
                              {" "}
                              <img className="w-100" src={img2} alt="" />
                            </div>
                            <div className="box1-text">
                              {card?.rent_count} for Rent
                            </div>
                          </div>
                        </div>
                        <Link className="link mx-2">
                          View Agency Properties <GoArrowUpRight />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}
          {featureAgencyList?.data?.agencies?.length <= 4 && (
            <div className="row m-0 px-sm-1" style={{ gap: "20px" }}>
              {featureAgencyList?.data?.agencies?.length ? (
                featureAgencyList?.data?.agencies?.map((item) => {
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      className="col-xl-3 col-lg-4 col-md-6 col-sm-8 col-12 special-col p-sm-1 p-0"
                    >
                      <div
                        onClick={() => navigate(`/agent-profile/${item?.id}`)}
                        className="card-1 border rounded"
                      >
                        <div className="row m-0 agentFeature" >
                          <div className="col-sm-4 col-4 p-0 d-flex align-items-center">
                            <div
                              className="card-img h-100 d-flex align-items-center justify-content-center p-2"
                              style={{ borderRight: "1px solid lightgray" }}
                            >
                              <FallbackImage
                                style={{
                                  width: "70px",
                                  height: "100%",
                                  objectFit: "contain",
                                  borderRadius: "5px",
                                }}
                                src={item?.agency_profile?.agency_image}
                                alt={"agency ptofile"}
                                fallbackSrc={img}
                              />
                            </div>
                          </div>
                          <div className="col-sm-8 col-8 col-12">
                            <div className="card-text px-1 p-2 m-0">
                              <div className="first-heading py-0 my-0">
                                {item?.agency_profile?.agency_name}
                              </div>
                              <div className="location d-flex">
                                <div className="location-icon">
                                  <IoLocationOutline />
                                </div>
                                <div className="location-details">Lahore </div>
                              </div>
                              <div className="house-box d-flex ">
                                <div className="box-1 d-flex">
                                  <div className="box1-img">
                                    {" "}
                                    <img className="w-100" src={img1} alt="" />
                                  </div>
                                  <div className="box1-text">
                                    {item?.sell_count} for Sale
                                  </div>
                                </div>

                                <div className="box-1 d-flex ">
                                  <div className="box1-img">
                                    {" "}
                                    <img className="w-100" src={img1} alt="" />
                                  </div>
                                  <div className="box1-text">
                                    {item?.rent_count} for Rent
                                  </div>
                                </div>
                              </div>
                              <Link
                                className="link mx-2"
                                style={{
                                  textDecoration: "none",
                                  fontSize: "14px",
                                }}
                              >
                                View Agency Properties <GoArrowUpRight />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoReacodFound />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default SecondSection;
