import React, { useEffect, useRef, useState } from "react";

import img from "../../Images/dummy/placeholder.png";
import img1 from "../../Images/Frame 1300192747.png";
import img2 from "../../Images/Frame 1300192750.png";
import flag from "../../Images/listing/pak.png";
import img3 from "../../Images/AgentProfile/Group 1000014158.png";
import img_2 from "../../Images/listing/Ellipse 374.png";
import { IoDiamond } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
// import {  FaShareAlt } from 'react-icons/fa'
import { MdArrowOutward } from "react-icons/md";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import whatsapp from "../../Images/listing/whatsapp.png";
import { IoIosCall } from "react-icons/io";
import { FallbackImage } from "../FallbackImage";
import Call from "../profile/Call";
import { useAuth } from "../../Context/ContextProvider";
import { useParams } from "react-router-dom";

function Profile({ agencyData }) {
  const { agencyInquery, loading } = useAuth();

  const [fieldErrors, setFieldErrors] = React.useState({});
  const [data, setData] = useState({
    email: "",
    password: "",
    name: "",
    contact: "",
  });
  const validateStep = () => {
    let errors = {};
    if (!data.name) {
      errors.name = "Name is required.";
    }
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data.subject) {
      errors.subject = "Subject is required.";
    }
    if (!data.message) {
      errors.message = "Message is required.";
    }

    if (!data.contact) {
      errors.contact = "Contact is required.";
    } else if (!isValidPakistaniPhoneNumber(data.contact)) {
      errors.contact = "Please enter a valid  phone number.";
    }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const formatPakistaniPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber.replace(/\D/g, ""); // Remove non-digit characters

    // Handle different input formats
    if (phone.startsWith("92")) {
      phone = phone.slice(2);
    } else if (phone.startsWith("+92")) {
      phone = phone.slice(3);
    } else if (phone.startsWith("0")) {
      phone = phone.slice(1);
    }

    // Ensure the phone number is no longer than 10 digits
    if (phone.length > 10) {
      phone = phone.slice(0, 10);
    }
    return phone; // Return processed phone number
  };
  const isValidPakistaniPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters except '+'
    // eslint-disable-next-line
    let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, "");

    // Log the cleaned phone number
    const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;
    // Regular expression for Pakistani phone number
    // const pakistaniPhoneRegex = /^\+92\d{11}$/;

    // Validate phone number format and length
    const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);

    return isValid;
  };
  const parms = useParams();
  const submit = async (event) => {
    event.preventDefault();
    if (validateStep()) {
      let result = await agencyInquery({
        ...data,
        id: agencyData?.agency_profile?.agency_profile_id,
        user_id: agencyData?.agency_profile?.agency_id,
        contact: "+92" + data?.contact,
      });
      if (result?.success) {
        setData({
          email: "",
          password: "",
          name: "",
          contact: "",
        });
      }
    }
  };

  const [open1, setOpen1] = useState(false);

  const handleOpen = () => setOpen1(true);

  const [showFullText, setShowFullText] = useState(false);

  const boxRef = useRef(null);
  const boxRef1 = useRef(null);
  // const [showFullText, setShowFullText] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [isTruncated1, setIsTruncated1] = useState(false);

  useEffect(() => {
    if (boxRef.current) {
      const lineHeight = parseInt(
        getComputedStyle(boxRef.current).lineHeight,
        10
      );
      const scrollHeight = boxRef.current.scrollHeight;
      const visibleHeight = showFullText ? scrollHeight : 60; // Adjust based on the current state

      setIsTruncated(
        scrollHeight > visibleHeight && scrollHeight > lineHeight * 3
      );
    }

    if (boxRef1.current) {
      const lineHeight = parseInt(
        getComputedStyle(boxRef.current).lineHeight,
        10
      );
      const scrollHeight = boxRef.current.scrollHeight;
      const visibleHeight = showFullText ? scrollHeight : 60; // Adjust based on the current state

      setIsTruncated1(
        scrollHeight > visibleHeight && scrollHeight > lineHeight * 3
      );
    }
  }, [showFullText, agencyData]);

  return (
    <>
      {loading ? (
        <div className="container-fluid mt-4 px-3">
          <div className="row px-4">
            <div className="col-lg-8 col-12 p-0">
              <div className="col-12 my-2">
                <span
                  className="placeholder"
                  style={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "5px",
                  }}
                ></span>
              </div>
              {[...Array(4)].map((_, rowIndex) => (
                <span
                  key={rowIndex}
                  className="placeholder"
                  style={{
                    width: "100%",
                    height: "50px",
                    marginTop: "15px",
                    borderRadius: "5px",
                  }}
                ></span>
              ))}
            </div>
            <div
              className="col-lg-4 col-12 my-lg-0 mt-5 mb-4 new_special_col"
              style={{ paddingLeft: "26px" }}
            >
              <div className="col-12 my-2">
                <div className="d-flex justify-content-center align-items-center">
                  <span
                    className="placeholder"
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50%",
                    }}
                  ></span>
                </div>
                {[...Array(4)].map((_, rowIndex) => (
                  <span
                    key={rowIndex}
                    className="placeholder"
                    style={{
                      width: "100%",
                      height: "50px",
                      marginTop: "15px",
                      borderRadius: "5px",
                    }}
                  ></span>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid mt-4 px-3">
          <div className="row px-4">
            <div className="col-lg-8 col-12 p-0">
              <div className="row m-0 py-3 px-2 main-row">
                <div className="col-md-11 col-12 p-2 d-md-flex d-block">
                  <div className="img-box mb-md-0 mb-3 ms-md-0 ms-3">
                    <FallbackImage
                      style={{
                        width: "100px",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                      src={
                        agencyData?.agency_profile?.agency_image
                          ? agencyData?.agency_profile?.agency_image
                          : img
                      }
                      alt={"agency ptofile"}
                      fallbackSrc={img}
                    />
                  </div>
                  <div className="imgbox-text mx-2 px-1">
                    <div className="first-box d-flex justify-content-md-start justify-content-between">
                      <div className="heading">
                        {agencyData?.agency_profile?.agency_name}
                      </div>
                      {agencyData?.agency_profile?.package && (
                        <div className="primium-card mx-3 d-sm-flex d-none">
                          <IoDiamond className="mx-1" />{" "}
                          {agencyData?.agency_profile?.package?.name}
                        </div>
                      )}
                    </div>
                    <div className="second-box my-1">
                      <div className="location">
                        <span>
                          <CiLocationOn />{" "}
                        </span>{" "}
                        {agencyData?.agency_profile?.address}
                      </div>
                    </div>
                    <div className="third-box d-flex">
                      <div
                        className="house-box"
                        style={{ marginRight: "16px" }}
                      >
                        <span>
                          <img src={img1} alt="" />
                        </span>
                        {agencyData?.agency_profile?.sell_count} for Sale
                      </div>
                      <div
                        className="house-box"
                        style={{ marginRight: "16px" }}
                      >
                        <span>
                          <img src={img2} alt="" />
                        </span>
                        {agencyData?.agency_profile?.rent_count} for Rent
                      </div>
                      <div className="house-box">
                        <span>
                          <img src={img2} alt="" />
                        </span>
                        {agencyData?.agency_profile?.lease_count} for Lease
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-2 col-12 pt-1 d-flex justify-content-lg-start align-items-start  justify-content-end ">
                                <button className='btn share-btn my-1' style={{ marginLeft: "8px" }}> <FaShareAlt /> Share</button>
                            </div> */}
              </div>

              <div className="row second-row px-0 mx-0 mt-4 pt-1">
                <div className="col-12 p-0 m-0">
                  <div className="properties">
                    Properties By {agencyData?.agency_profile?.agency_name}
                  </div>
                </div>
              </div>

              <div className="row third-row p-0 m-0">
                {agencyData?.total_properties?.map((item) => {
                  if (item?.total) {
                    return (
                      <div
                        className="col-md-6 col-12  py-2 special-col"
                        style={{ paddingRight: "12px", paddingLeft: "0" }}
                      >
                        <div className="house-card pb-2">
                          <div className="house-box d-flex justify-content-between align-items-center px-2 py-2">
                            <div className="house-img d-flex align-items-center">
                              <span>
                                {" "}
                                <img src={img1} alt="" />
                              </span>
                              {item?.total} for{" "}
                              {item?.name === "Sell" ? "Sale" : item?.name}
                            </div>
                            {/* <div className="view d-flex align-items-center">
                                                        <a href="/">View All <MdArrowOutward /></a>
                                                    </div> */}
                          </div>
                          <div className="card-details d-flex flex-wrap px-1 my-1">
                            {item?.categories?.map((items) => {
                              if (items?.total !== 0) {
                                return (
                                  <div className="details-box mx-1 mt-2">
                                    <div className="quantity">
                                      {items?.total}
                                    </div>
                                    <div className="house-type">
                                      {items?.name}
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>

              {/* <div className="row forth-row px-0 mx-0 mt-3 pt-1">
                            <div className="col-12 p-0 m-0">
                                <div className="team">
                                    Agency Team
                                </div>
                            </div>
                        </div> */}

              {/* <div className="row mx-0 mt-3 p-0 d-flex justify-content-md-start justify-content-center">
                            <div className="col-md-3 col-8 p-0 ">
                                <div className="team-card text-center py-2">
                                    <div className="user-img mt-1">
                                        <img className='shadow-img' src={img3} alt="" />
                                    </div>
                                    <div className="user-name text-center mt-2 mb-1">Ehsaan Elahi</div>
                                    <div className="user-post mb-2">CEO</div>
                                    <div className="contact mb-1">
                                        <div className="contact-box" style={{ marginRight: "5px" }}> <IoIosMail /> Email</div>
                                        <div className="contact-box call-box" style={{ marginLeft: "5px" }}> <span><FaPhoneAlt /></span> Call</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-8 p-0 mx-3 my-md-0 my-4">
                                <div className="team-card text-center py-2">
                                    <div className="user-img mt-1">
                                        <img src={img3} alt="" />
                                    </div>
                                    <div className="user-name text-center mt-2 mb-1">Ehsaan Elahi</div>
                                    <div className="user-post mb-2">Plots Agent</div>
                                    <div className="contact mb-1">
                                        <div className="contact-box" style={{ marginRight: "5px" }}> <IoIosMail /> Email</div>
                                        <div className="contact-box call-box" style={{ marginLeft: "5px" }}> <span><FaPhoneAlt /></span> Call</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-8 p-0 ">
                                <div className="team-card text-center py-2">
                                    <div className="user-img mt-1">
                                        <img src={img3} alt="" />
                                    </div>
                                    <div className="user-name text-center mt-2 mb-1">Ehsaan Elahi</div>
                                    <div className="user-post mb-2">Home Agent</div>
                                    <div className="contact mb-1">
                                        <div className="contact-box" style={{ marginRight: "5px" }}> <IoIosMail /> Email</div>
                                        <div className="contact-box call-box" style={{ marginLeft: "5px" }}> <span><FaPhoneAlt /></span> Call</div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

              <div className="row fifth-row mt-4 pt-3 mx-0 px-0">
                <div className="col-8 p-0">
                  <div className="agency">About Agency</div>
                </div>

                <div
                  ref={boxRef}
                  className={`agency-text ${
                    showFullText ? "show-full-text" : "clamp-text"
                  }`}
                  style={{
                    maxHeight: showFullText ? "none" : "60px", // Adjust for toggling
                    overflow: "hidden", // Hide overflowing content
                    lineHeight: "20px", // Ensure consistent line height
                  }}
                >
                  {agencyData?.agency_profile?.description}
                  {/* sadasdasdhsakjhdkjsahdkjahsjdsahjdhsakjhdsadjsahdjhsajdhsajhdjsahjdhsadjhsakjdhjshdjsahdjhsajdhjsahdjsahdkjhsajdshadjhsajdhjsahdjhsajdhsajhdjshadjhsajdhjsahdjsaadsdsadasdas */}
                </div>
                {agencyData?.agency_profile?.description?.length > 350 && (
                  <div className="d-flex justify-content-end read-more-container">
                    {isTruncated ? (
                      <button
                        className="read-function py-0"
                        onClick={() => setShowFullText(!showFullText)}
                      >
                        {showFullText ? (
                          <>
                            Read Less
                            <IoMdArrowDropup />
                          </>
                        ) : (
                          <>
                            Read More
                            <IoMdArrowDropdown />
                          </>
                        )}
                      </button>
                    ) : (
                      <button
                        className="read-function py-0"
                        onClick={() => setShowFullText(!showFullText)}
                      >
                        {showFullText ? (
                          <>
                            Read Less
                            <IoMdArrowDropup />
                          </>
                        ) : (
                          <>
                            Read More
                            <IoMdArrowDropdown />
                          </>
                        )}
                      </button>
                    )}
                  </div>
                )}
                <div className="hr mt-4" />
              </div>
              <div className="row new_fifth_row fifth-row mt-4 pt-3 mx-0 px-0">
                <div className="col-md-8 col-12 p-0">
                  <div className="agency">
                    <div className="user-image border me-sm-3">
                      <FallbackImage
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                        src={
                          agencyData?.agency_profile?.ceo_image
                            ? agencyData?.agency_profile?.ceo_image
                            : img3
                        }
                        alt={"agency ptofile"}
                        fallbackSrc={img3}
                      />
                    </div>
                    <div>
                      {agencyData?.agency_profile?.ceo_full_name} (CEO)
                      <div
                        ref={boxRef1}
                        className={`agency-text ${
                          showFullText ? "show-full-text" : "clamp-text"
                        }`}
                        style={{
                          maxHeight: showFullText ? "none" : "60px", // Adjust for toggling
                          overflow: "hidden", // Hide overflowing content
                          lineHeight: "20px", // Ensure consistent line height
                        }}
                      >
                        {agencyData?.agency_profile?.ceo_desc}
                      </div>
                      {agencyData?.agency_profile?.ceo_desc?.length > 230 && (
                        <div className="d-flex justify-content-end read-more-container">
                          {isTruncated1 && (
                            <button
                              className="read-function py-0"
                              onClick={() => setShowFullText(!showFullText)}
                            >
                              {showFullText ? (
                                <>
                                  Read Less
                                  <IoMdArrowDropup />
                                </>
                              ) : (
                                <>
                                  Read More
                                  <IoMdArrowDropdown />
                                </>
                              )}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="hr mt-4" />
              </div>
            </div>
            <div
              className="col-lg-4 col-12 my-lg-0 mt-5 mb-4 new_special_col"
              style={{ paddingLeft: "26px" }}
            >
              <div className="logo_prop p-3">
                <div className="logo_info">
                  <div className="logo_img">
                    <FallbackImage
                      src={
                        agencyData?.agency_profile?.agency_image
                          ? agencyData?.agency_profile?.agency_image
                          : img3
                      }
                      alt={"..."}
                      fallbackSrc={img3}
                    />
                  </div>

                  <p className="heading_info">
                    {agencyData?.agency_profile?.agency_name}
                  </p>
                  <p className="content_info">
                    {agencyData?.agency_profile?.email}
                  </p>
                  <div className="info_btns">
                    <button
                      onClick={() => {
                        handleOpen();
                      }}
                    >
                      <IoIosCall className="me-2" />
                      Call
                    </button>
                    <a
                      href={`https://wa.me/${agencyData?.agency_profile?.phone_number}`}
                      target="_blank"
                    >
                      <button>
                        <img src={whatsapp} alt="" className="me-2" />
                        WhatsApp
                      </button>
                    </a>
                  </div>
                </div>
                <form onSubmit={submit}>
                  <div className="inp_login">
                    <label className="logo_label">Name</label>
                    <input maxLength={40}
                      value={data?.name}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                      type="text"
                      placeholder="Name"
                    />
                    {fieldErrors?.name && (
                      <span className="text_error">{fieldErrors?.name}</span>
                    )}{" "}
                  </div>
                  <div className="inp_login">
                    <label className="logo_label">Email</label>
                    <input
                      value={data?.email}
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                      type="email"
                      placeholder="Email"
                    />
                    {fieldErrors?.email && (
                      <span className="text_error">{fieldErrors?.email}</span>
                    )}
                  </div>
                  <div className="inp_login">
                    <label className="logo_label">Subject</label>
                    <input
                      value={data?.subject}
                      onChange={(e) =>
                        setData({ ...data, subject: e.target.value })
                      }
                      type="text"
                      placeholder="General Inquiry"
                    />
                    {fieldErrors?.subject && (
                      <span className="text_error">{fieldErrors?.subject}</span>
                    )}
                  </div>

                  <div className="inp_login">
                    <label className="logo_label">Contact</label>
                    <div className="contact_contry">
                      <div className="img_container">
                        <img src={flag} alt="..." />
                        <span>+92</span>
                      </div>
                      <input
                        value={data?.contact}
                        style={{
                          borderRadius: " 0 5px 5px 0 ",
                          borderLeft: "none",
                        }}
                        onChange={(e) =>
                          setData({
                            ...data,
                            contact: formatPakistaniPhoneNumber(e.target.value),
                          })
                        }
                        type="text"
                        placeholder="e.g 3324751060"
                      />
                    </div>
                    {fieldErrors?.contact && (
                      <span className="text_error">{fieldErrors?.contact}</span>
                    )}
                  </div>
                  <div className="inp_login">
                    <label className="logo_label">Message</label>
                    <textarea
                      value={data?.message}
                      style={{
                        minHeight: "100px",
                        width: "100%",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        padding: ".5rem",
                      }}
                      onChange={(e) =>
                        setData({ ...data, message: e.target.value })
                      }
                      type="email"
                      placeholder="Write your message....."
                    />
                    {fieldErrors?.message && (
                      <span className="text_error">{fieldErrors?.message}</span>
                    )}
                  </div>
                  <button type="submit" className="Sign_inBtn py-2">
                    Send Email
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <Call
        profileData={{
          user: {
            phone_number: agencyData?.agency_profile?.phone_number,
            avatar: agencyData?.agency_profile?.agency_image,
            landline: agencyData?.agency_profile?.phone_number,
            email: agencyData?.agency_profile?.email,
            name: agencyData?.agency_profile?.agency_name,
          },
        }}
        open={open1}
        setOpen={setOpen1}
      />
    </>
  );
}

export default Profile;
