import React, { useEffect, useState } from 'react'
import Main from './Section/Main'
import { useAuth } from '../../../Context/ContextProvider';

function HomeSection({data,setData,newData}) {
    const [value,setValue]=useState({name:"",amenity_category_id:""})
    const [show,setShow]=useState([])
    const [feature,setFeature]=useState([])

    const {getAmintites,aminitiesLoding}=useAuth()
    useEffect(() => {
        const fetchData = async () => {
          try {
            let result=await  getAmintites(newData?.property_sub_type)
            if (result?.success) {
                setFeature(result?.data?.data?.categories)
                if(result?.data?.data?.categories?.length){
                    setValue({
                        name:result?.data?.data?.categories[0]?.name,
                        amenity_category_id:result?.data?.data?.categories[0]?.id
                    })
                    setShow(result?.data?.data?.categories[0]?.amenities)
                }
                
            }
          } catch (error) {
            console.error("An error occurred while fetching data:", error);
          }
        };
        fetchData();
      }, [newData?.property_sub_type]);
    // useEffect(()=>{
    //     if(value?.name!==""){
    //         feature?.map((item)=>{
    //             if(item?.category_id===newData?.property_type){
    //                 setValue({
    //                     name:item?.data[0]?.name,
    //                     amenity_category_id:item?.data[0]?.amenity_category_id,
    //                 })
    //                 setShow(item?.data[0]?.data)
    //             }
    //             return null
    //         })
    //     }
    //     // setValue(feature[0]?.)
    //     // eslint-disable-next-line
    // },[])
    return (
        <>{aminitiesLoding? <div className='row m-0 h-100'>
            <div className='col-3'>
            {[...Array(4)].map((_, rowIndex) => (
                <span
                  key={rowIndex}
                  className="placeholder"
                  style={{
                    width: "100%",
                    height: "40px",
                    marginTop: "15px",
                    borderRadius: "5px",
                  }}
                ></span>
              ))}
            </div>
            <div className='col-9 page p-3'>
            {[...Array(4)].map((_, rowIndex) => (
                <span
                  key={rowIndex}
                  className="placeholder"
                  style={{
                    width: "100%",
                    height: "50px",
                    marginTop: "15px",
                    borderRadius: "5px",
                  }}
                ></span>
              ))}
            </div>
        </div>: <div className='row m-0 h-100'>
            <div className='col-3'>
                <ul className='p-0'>
                    {feature?.map((item)=>{
                                return(
                                    <li onClick={()=>{
                                        setValue({
                                            name:item?.name,
                                            amenity_category_id:item.id
                                        })
                                        setShow(item?.amenities)
                                    }} className={`${value?.name===item?.name?"active_li":""}`}>{item?.name}</li>
    
                                )
                 
                        
               
                    })}
                </ul>
            </div>
            <div className='col-9 page p-3'>
                <Main newData={newData} show={show} data={data} value={value} setData={setData} />
            </div>
        </div>}
        </>
       
    )
}

export default HomeSection
