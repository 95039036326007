import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { RxCross2 } from 'react-icons/rx';
import img from '../../../src/Images/Ellipse 374.png';
import mobile from '../../../src/Images/Vector (8).png';
import telephone from '../../../src/Images/Group 1000014111.png';
import copy from '../../../src/Images/Group 1000014110.png';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../Context/ContextProvider';
import { FallbackImage } from '../FallbackImage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  boxShadow: 24,
};

function Call({ open, setOpen, profileData }) {
  const { whatsappImpretion } = useAuth();
  const [copied, setCopied] = useState(false); // State for showing "Copied!" message
  const handleClose = () => setOpen(false);
  const parms = useParams();

  const ToCopyData = (e) => {
    const number = e.currentTarget.previousSibling.textContent.trim();
    navigator.clipboard.writeText(number);
    whatsappImpretion("calls", parms?.id);

    setCopied(true); // Show "Copied!" message
    setTimeout(() => {
      setCopied(false); // Hide after 2 seconds
    }, 2000);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='call_modal'>
          <div className="call-card">
            <div className="head">
              <div className="heading">Call the Agent</div>
              <div className="call-cancel-btn" onClick={handleClose}>
                <RxCross2 />
              </div>
            </div>
            <div className="hr"></div>
            <div className="call-card-dealer-details">
              <div className="img-box flex-class">
                <FallbackImage
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  src={profileData?.user?.avatar}
                  fallbackSrc={img}
                  alt={'...'}
                />
              </div>
              <div className="dealer-company-name flex-class mt-sm-3 mt-2 mb-1">
                {profileData?.user?.name}
              </div>
              <div
                style={{ fontSize: "14px", color: "#646C6D" }}
                className="dealer-name flex-class"
              >
                {profileData?.user?.email}
              </div>
            </div>
            <div className="hr"></div>
            <div className="contact-box">
              <div className="row m-0 p-1 d-flex justify-content-center">
                <div className="col-sm-6 col-10 contact-col">
                  <div className="mobile-box">
                    <div className="img-box flex-class align-items-center">
                      <img src={mobile} alt="Mobile icon" />
                      <span className="mx-2">Mobile</span>
                    </div>
                    <div className="mobile-number flex-class align-items-center">
                      <span className="num">{profileData?.user?.phone_number}</span>
                      <img
                        className="mx-2"
                        onClick={ToCopyData}
                        src={copy}
                           style={{cursor:"pointer"}}
                        alt="Copy icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-10 contact-col-1">
                  <div className="mobile-box-1">
                    <div className="img-box flex-class align-items-center">
                      <img src={telephone} alt="Telephone icon" />
                      <span className="mx-2">Landline</span>
                    </div>
                    <div className="mobile-number flex-class align-items-center mt-3">
                      <span className="num">{profileData?.user?.landline}</span>
                      <img
                        className="mx-2"
                        onClick={ToCopyData}
                        src={copy}
                        style={{cursor:"pointer"}}
                        alt="Copy icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="hr"></div>
            </div>
            {/* Show the "Copied!" message */}
            {copied && (
              <div
                style={{
                  position: "fixed",
                  top: "83%",
                  left: "20px",
                  // transform: "translateX(-50%)",
                  color: "#4caf50",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  zIndex: 9999,
                }}
              >
                Copied!
              </div>
            )}
            <div className="last-details mt-2 pt-1 mb-3 pb-1"></div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Call;
