import React, { useEffect, useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/AgencyList/Banner'
import ListAgents from '../Camponent/AgencyList/ListAgents'
import Footer from '../Camponent/NavBarFooter/Footer'
import { useParams } from 'react-router-dom'
import { useAuth } from '../Context/ContextProvider'

function AgencyList() {
  const { agencyList,
    allcity,type,dataAgency,featureAgencyList, setFeatureAgencyList  , currentCityAgency,
     } = useAuth()

  const [isSearch,setIsSearch]=useState(false)
 
  const parms=useParams()
  useEffect(() => {
      

      const fetchCart1 = async () => {
     let  updateData={...dataAgency,city_code:currentCityAgency?.app_code}
          try {
              let result = await agencyList(updateData);
              if (result?.success) {
                  let propertyData = result?.data?.data?.agencies;
                  setFeatureAgencyList(propertyData)

              }
          } catch (error) {
              console.error("An error occurred while fetching data:", error);
          }
      };
  

      fetchCart1()
      
  }, [])
  const searchData = async (dataValue) => {
          
    try {
        let result = await agencyList(dataValue);
        if (result?.success) {
            let propertyData = result?.data?.data?.agencies;
            setFeatureAgencyList(propertyData)
           
            setIsSearch(true)
        }
    } catch (error) {
        console.error("An error occurred while fetching data:", error);
    }
};
  return (
    <div>
        <NavBar/>
        <Banner id={parms?.id} isSearch={isSearch} setIsSearch={setIsSearch} searchData={searchData} type={type} allcity={allcity} currentCity={currentCityAgency} />
        <ListAgents featureAgencyList={featureAgencyList} currentCity={currentCityAgency}/>
        <Footer/>
    </div>
  )
}

export default AgencyList