import React, { useEffect, useState } from "react";
import LocationPurpose from "../Component/LocationPurpose";
import FeatureAmenities from "../Component/FeatureAmenities";
import PriceArea from "../Component/PriceArea";
import AdInformation from "../Component/AdInformation";
import PropertyImageVideo from "../Component/PropertyImageVideo";
import ContactInformation from "../Component/ContactInformation";
import PlatFormSection from "../Component/PlatFormSection";
import { useAuth } from "../../Context/ContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import { LuSave } from "react-icons/lu";

function AddProperty() {
  const [lable, setLable] = useState("Home");
  const formatPakistaniPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber?.replace(/\D/g, ""); // Remove non-digit characters

    // Handle different input formats
    if (phone?.startsWith("92")) {
      phone = phone.slice(2);
    } else if (phone?.startsWith("+92")) {
      phone = phone.slice(3);
    } else if (phone?.startsWith("0")) {
      phone = phone.slice(1);
    }

    // Ensure the phone number is no longer than 10 digits
    if (phone?.length > 10) {
      phone = phone.slice(0, 10);
    }
    return phone; // Return processed phone number
  };
  const parms = useParams();
  const [city_value, setCityValue] = useState({
    city: "",
    location: "",
  });
  const [latitude, setlatitude] = useState({
    lat: "31.549700000000001409716787748038768768310546875",
    lng: "74.3435999999999950205165077932178974151611328125",
  });
  const [load, setLoad] = useState(false);
  const [image, setImages] = useState([]);
  const {
    handleAddProperty,
    getProprteyById,
    handleUpdateProperty,
    userData,
    button_loading,
  } = useAuth();
  const [data, setData] = useState({
    type: "",
    property_type: "",
    property_sub_type: "",
    city_id: "",
    state_id: "",
    plot_number: "",
    area: "",
    area_unit: "Marla",
    price: "",
    currency: "PKR",
    installment: false,
    possession: false,
    bedrooms: "",
    bathrooms: "",
    amenities: [],
    ad_title: "",
    ad_description: "",
    images: [],
    video_links: ["", ""],
    email: userData?.data?.user?.email,
    mobile: [
      userData?.data?.user?.phone_number
        ? formatPakistaniPhoneNumber(userData?.data?.user?.phone_number)
        : "",
    ],
    land_line: userData?.data?.user?.landline,
    plateform: true,
    lease: {
      duration_type: "month",
    },
  });

  useEffect(() => {
    const fetchCart = async () => {
      try {
        let result = await getProprteyById(parms?.id);
        if (result?.success) {
          let propertyData = result?.data?.data?.property;
          console.log(propertyData);
          const transformedAmenities = propertyData?.amenities?.map(
            (category) => {
              const transformedData = category.data.map((item) => {
                return {
                  amenity_id: item.amenity_id,
                  lable: item.label, // Fixing the typo 'lable' as per your request
                  type: item.type,
                  selected_options: [item.selected_options], // Wrapping selected_options in an array
                };
              });

              return {
                amenity_category_id: category.amenity_category_id,
                category_name: category.category_name,
                data: transformedData,
                property_type_id: 3, // Assuming you're setting property_type_id to 3
              };
            }
          );
          setData((prevData) => ({
            ...prevData,
            type: propertyData?.property_type_id,
            property_type: propertyData?.category_id,
            property_sub_type: propertyData?.sub_category_id,
            city_id: propertyData?.city_code,
            state_id: propertyData?.location_id,
            plot_number: propertyData?.plot_number,
            area: propertyData?.area_size,
            area_unit: propertyData?.unit_area,
            price: propertyData?.price,
            currency: propertyData?.currency,
            installment: propertyData?.installments_available,
            possession: propertyData?.ready_for_possession,
            monthly_installments: propertyData?.monthly_installments,
            number_of_installments: propertyData?.number_of_installments,
            advanced_amount: propertyData?.advanced_amount,
            bedrooms: propertyData?.bedrooms,
            bathrooms: propertyData?.bathrooms,
            amenities: transformedAmenities,
            ad_title: propertyData?.title,
            ad_description: propertyData?.description,
            video_links: propertyData?.video_links||["",""],
            email: propertyData?.email
              ? propertyData?.email
              : userData?.data?.user?.email,
            mobile: propertyData?.contacts
              ? [...propertyData?.contacts.map(formatPakistaniPhoneNumber)]
              : [
                  formatPakistaniPhoneNumber(
                    userData?.data?.user?.phone_number
                  ),
                ],
            land_line: propertyData?.land_line
              ? formatPakistaniPhoneNumber(propertyData?.land_line)
              : formatPakistaniPhoneNumber(userData?.data?.user?.landline),
            plateform: true,
            id: propertyData?.id,
          }));
          setCityValue({
            city: propertyData?.city?.name,
            location: propertyData?.location?.name,
          });
          setlatitude({
            lat: propertyData?.location?.latitude,
            lng: propertyData?.location?.longitude,
          });
          setImages(propertyData?.images);
          setLoad(true);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };

    if (parms?.id) {
      fetchCart();
    } else {
      setData({
        type: "",
        property_type: "",
        property_sub_type: "",
        city_id: "",
        state_id: "",
        plot_number: "",
        area: "",
        area_unit: "Marla",
        price: "",
        currency: "PKR",
        installment: false,
        possession: false,
        bedrooms: "",
        bathrooms: "",
        amenities: [],
        ad_title: "",
        ad_description: "",
        images: [],
        video_links: ["", ""],
        email: userData?.data?.user?.email,
        mobile: [
          userData?.data?.user?.phone_number
            ? formatPakistaniPhoneNumber(userData?.data?.user?.phone_number)
            : "",
        ],
        land_line: formatPakistaniPhoneNumber(userData?.data?.user?.landline),
        plateform: true,
        lease: {
          duration_type: "month",
        },
      });
    }
    // eslint-disable-next-line
  }, [parms?.id]);

  const [fieldErrors, setFieldErrors] = React.useState({});
  const validateStep = () => {
    let errors = {};
    errors.mobile = [];
    if (!data.city_id) {
      errors.city_id = "City is required.";
    }
    if (!data.state_id) {
      errors.state_id = "State is required.";
    }
    if (!data.area) {
      errors.area = "Area is required.";
    }
    if (!data.area_unit) {
      errors.area_unit = "Area unit is required.";
    }
    if (!data.plot_number) {
      errors.plot_number = "Plot no is required.";
    }
    if (!data.price) {
      errors.price = "Price is required.";
    }
    if (!data.ad_title) {
      errors.ad_title = "Title is required.";
    }
    if (image?.length>15) {
      errors.images = "You can upload a maximum of 15 images per property";
    }
    if (!data.ad_description) {
      errors.ad_description = "Description is required.";
    }
    if (data.installment) {
      if (!data.monthly_installments) {
        errors.monthly_installments = "Monthly Installments is required.";
      }
      if (!data.number_of_installments) {
        errors.number_of_installments = "No of Installments is required.";
      }
      if (!data.advanced_amount) {
        errors.advanced_amount = "Advance Amount is required.";
      }
    }
    if (data?.slug === "lease") {
      if (!data?.lease?.building_area_size) {
        errors.building_area_size = "Building Size is required.";
      }
      if (!data?.lease?.duration) {
        errors.duration = "Lease Duration is required.";
      }
      if (!data?.lease?.type) {
        errors.lease_type = "Lease type is required.";
      }
      if (!data?.lease?.availability) {
        errors.availability = "Lease availability is required.";
      }
    }
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (data?.land_line) {
      if (data.land_line?.length !== 9 && data.land_line?.length !== 10) {
        errors.land_line = "Please enter a valid  Lanline.";
      }
    }
    data.mobile.forEach((item, index) => {
      if (!item || item.trim() === "") {
        // If the item is empty, set the error message.
        errors.mobile[index] = "Mobile is required.";
      } else if (item.length !== 9 && item.length !== 10) {
        // If the length is not 9 or 10, set the error message.
        errors.mobile[index] = "Please enter a valid mobile.";
      } else {
        // If no error, ensure the error array remains empty for this index.
        errors.mobile[index] = null; // Or you can omit this to keep errors clean.
      }
    });
    errors.mobile = errors.mobile.filter((error) => error !== null);
    if (Object.keys(errors).length === 1 && errors.mobile?.length === 0) {
      errors = {};
    }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const navigate = useNavigate();
  const submit = async (event) => {
    event.preventDefault();
    if (validateStep()) {
      if (data?.id) {
        let result = await handleUpdateProperty(data);
        console.log(result);

        if (result?.success) {
          navigate("/agent/list-property");
        }
      } else {
        let result = await handleAddProperty(data);
        if (result?.success) {
          if (result?.data?.data?.usedQuota === 0) {
            navigate(
              `/agent/property-pending/${result?.data?.data?.property_id}`
            );
          } else {
            navigate("/agent/list-property");
          }
        }
      }
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  console.log(
    fieldErrors,
    data?.mobile?.find((item) => item === "")
  );

  return (
    <div className="add_propertey_main">
        <div className="d-flex justify-content-between align-items-center mb-2">

      <h3 className="heading iconHeading m-0">
      <svg width="15" height="15" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.192 0C10.7274 0.000353024 10.2819 0.185064 9.95325 0.513585C9.62465 0.842106 9.43983 1.28759 9.43936 1.75225V2.03762L8.23918 0.980584C7.89683 0.68074 7.45722 0.515447 7.00212 0.515447C6.54702 0.515447 6.10741 0.68074 5.76506 0.980584L0.11314 5.94618C0.0802466 5.97516 0.0533837 6.01034 0.0340852 6.0497C0.0147866 6.08907 0.00343042 6.13185 0.000664902 6.1756C-0.00210061 6.21935 0.00377873 6.26322 0.0179673 6.3047C0.0321558 6.34618 0.0543756 6.38446 0.0833581 6.41736C0.141891 6.48379 0.224416 6.52425 0.312779 6.52983C0.356532 6.5326 0.400401 6.52672 0.441882 6.51253C0.483363 6.49834 0.521643 6.47612 0.554536 6.44714L1.05416 6.00752V11.3278C1.05463 11.7385 1.21799 12.1323 1.50841 12.4227C1.79882 12.7131 2.19257 12.8765 2.60327 12.8769H6.9999C7.08832 12.8769 7.17311 12.8418 7.23563 12.7793C7.29815 12.7168 7.33328 12.632 7.33328 12.5436C7.33328 12.4551 7.29815 12.3703 7.23563 12.3078C7.17311 12.2453 7.08832 12.2102 6.9999 12.2102H2.60238C2.36844 12.2099 2.14415 12.1169 1.97873 11.9515C1.81331 11.7861 1.72027 11.5618 1.72004 11.3278V5.42122L6.2029 1.47977C6.42345 1.28655 6.70668 1.18004 6.9999 1.18004C7.29311 1.18004 7.57635 1.28655 7.7969 1.47977L12.374 5.50478L12.3784 5.50834L13.4453 6.44758C13.5117 6.506 13.5986 6.53563 13.6869 6.52996C13.7752 6.52429 13.8576 6.48379 13.916 6.41736C13.9744 6.35093 14.004 6.26401 13.9984 6.17573C13.9927 6.08745 13.9522 6.00504 13.8858 5.94662L12.9434 5.11806V1.75225C12.9428 1.28786 12.7581 0.842643 12.4299 0.514185C12.1016 0.185727 11.6564 0.000823226 11.192 0ZM12.2775 4.53131L10.1061 2.61993V1.75225C10.1119 1.46811 10.2288 1.19754 10.4318 0.99862C10.6347 0.799702 10.9076 0.688286 11.1918 0.688286C11.476 0.688286 11.7489 0.799702 11.9519 0.99862C12.1549 1.19754 12.2718 1.46811 12.2775 1.75225V4.53131Z" fill="#1C9093" />
                    <path d="M5.39062 5.00116C5.39071 5.3194 5.48516 5.63046 5.66203 5.89502C5.83889 6.15958 6.09023 6.36576 6.38427 6.48748C6.6783 6.6092 7.00183 6.641 7.31394 6.57886C7.62605 6.51673 7.91272 6.36344 8.13772 6.13838C8.36271 5.91332 8.51592 5.62661 8.57797 5.31448C8.64003 5.00235 8.60813 4.67884 8.48633 4.38483C8.36453 4.09083 8.15828 3.83955 7.89367 3.66276C7.62907 3.48596 7.31798 3.3916 6.99974 3.3916C6.57305 3.39207 6.16397 3.56182 5.86229 3.86358C5.56061 4.16534 5.39098 4.57447 5.39062 5.00116ZM7.9421 5.00116C7.94201 5.18753 7.88667 5.36968 7.78307 5.52459C7.67947 5.6795 7.53226 5.80021 7.36006 5.87147C7.18786 5.94273 6.9984 5.96133 6.81563 5.92491C6.63286 5.8885 6.46499 5.79872 6.33324 5.66691C6.20149 5.5351 6.11179 5.36719 6.07546 5.1844C6.03914 5.00161 6.05782 4.81216 6.12916 4.63999C6.2005 4.46783 6.32128 4.32068 6.47624 4.21715C6.6312 4.11362 6.81338 4.05836 6.99974 4.05836C7.24968 4.0586 7.4893 4.15802 7.66599 4.33479C7.84267 4.51156 7.94198 4.75123 7.9421 5.00116Z" fill="#1C9093" />
                    <path d="M11.0791 7.03516C10.5014 7.03516 9.93665 7.20646 9.45632 7.52741C8.97599 7.84836 8.60161 8.30453 8.38054 8.83825C8.15947 9.37197 8.10163 9.95926 8.21433 10.5258C8.32703 11.0924 8.60522 11.6129 9.0137 12.0214C9.42219 12.4299 9.94264 12.7081 10.5092 12.8208C11.0758 12.9335 11.6631 12.8756 12.1968 12.6545C12.7305 12.4335 13.1867 12.0591 13.5077 11.5788C13.8286 11.0984 13.9999 10.5337 13.9999 9.95602C13.999 9.18164 13.6909 8.43926 13.1434 7.8917C12.5958 7.34413 11.8534 7.0361 11.0791 7.03516ZM11.0791 12.2097C10.6332 12.2097 10.1974 12.0775 9.82671 11.8298C9.45601 11.5821 9.1671 11.23 8.99651 10.8181C8.82591 10.4062 8.7813 9.95292 8.86831 9.51565C8.95532 9.07839 9.17005 8.67675 9.48533 8.36153C9.80062 8.0463 10.2023 7.83166 10.6396 7.74473C11.0769 7.65781 11.5301 7.70251 11.942 7.87318C12.3539 8.04386 12.7059 8.33284 12.9535 8.70359C13.2011 9.07433 13.3333 9.51018 13.3332 9.95602C13.3325 10.5536 13.0947 11.1265 12.6721 11.549C12.2496 11.9715 11.6766 12.2091 11.0791 12.2097Z" fill="#1C9093" />
                    <path d="M12.1667 9.62273H11.4111V8.86707C11.4111 8.77865 11.376 8.69386 11.3134 8.63134C11.2509 8.56882 11.1661 8.53369 11.0777 8.53369C10.9893 8.53369 10.9045 8.56882 10.842 8.63134C10.7794 8.69386 10.7443 8.77865 10.7443 8.86707V9.62273H9.98865C9.90024 9.62273 9.81544 9.65786 9.75292 9.72038C9.6904 9.7829 9.65527 9.8677 9.65527 9.95612C9.65527 10.0445 9.6904 10.1293 9.75292 10.1919C9.81544 10.2544 9.90024 10.2895 9.98865 10.2895H10.7443V11.0452C10.7443 11.1336 10.7794 11.2184 10.842 11.2809C10.9045 11.3434 10.9893 11.3785 11.0777 11.3785C11.1661 11.3785 11.2509 11.3434 11.3134 11.2809C11.376 11.2184 11.4111 11.1336 11.4111 11.0452V10.2895H12.1667C12.2552 10.2895 12.34 10.2544 12.4025 10.1919C12.465 10.1293 12.5001 10.0445 12.5001 9.95612C12.5001 9.8677 12.465 9.7829 12.4025 9.72038C12.34 9.65786 12.2552 9.62273 12.1667 9.62273Z" fill="#1C9093" />
                </svg> Add Listing</h3>
     
          <button className="submit_btn" onClick={submit}>
         
            {button_loading ? (
              <div
                className="spinner-border text-light button_loading"
                role="status"
              ></div>
            ) : data?.id ? (
              "Update"
            ) : (
              "Submit"
            )}{" "}
               <LuSave/>
          </button>
        </div>
       
      <div className=" new_scroll-box-y addProperties">
        <LocationPurpose
          load={load}
          setlatitude={setlatitude}
          latitude={latitude}
          fieldErrors={fieldErrors}
          setCityValue={setCityValue}
          city_value={city_value}
          data={data}
          setData={setData}
          lable={lable}
          setLable={setLable}
        />
        <PriceArea
          fieldErrors={fieldErrors}
          data={data}
          setData={setData}
          lable={lable}
          setLable={setLable}
        />
        <FeatureAmenities
          fieldErrors={fieldErrors}
          data={data}
          setData={setData}
          lable={lable}
          setLable={setLable}
        />
        <AdInformation
          fieldErrors={fieldErrors}
          data={data}
          setData={setData}
          lable={lable}
          setLable={setLable}
        />
        <PropertyImageVideo
          setImages={setImages}
          image={image}
          fieldErrors={fieldErrors}
          data={data}
          setData={setData}
          lable={lable}
          setLable={setLable}
        />
        <ContactInformation
          fieldErrors={fieldErrors}
          data={data}
          setData={setData}
          lable={lable}
          setLable={setLable}
        />
        <PlatFormSection
          fieldErrors={fieldErrors}
          data={data}
          setData={setData}
          lable={lable}
          setLable={setLable}
        />
      
      </div>
    </div>
  );
}

export default AddProperty;
