import React, { useEffect, useRef } from "react";
import img from "../../Images/AgentProfile/Group 1000014158.png";
import flag from "../../Images/listing/pak.png";
import imgs from "../../Images/dummy/placeholder.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import videoImg from "../../Images/dummy/vidioImage.jpg";
import { FaPhoneAlt } from "react-icons/fa";
import { FallbackImage } from "../FallbackImage";
import {
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoClose,
} from "react-icons/io5";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAuth } from "../../Context/ContextProvider";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  boxShadow: 24,
  outline: "none",
};

function Card({ setOpen, open, profileData, handleOpen }) {
  const [fieldErrors, setFieldErrors] = React.useState({});
  const { properyEnquery } = useAuth();
  const [checkbox, setCheckbox] = useState(false);
  const [radio, setRadio] = useState("Buyer/Tenant");
  const [activeDiv, setActiveDiv] = useState("div1");
  const [activeImg, setActiveImg] = useState();
  const [currentDiv, setCurrentDiv] = useState("original");
  const [data, setData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
    other: "",
  });
  const parms = useParams();
  const validateStep = () => {
    let errors = {};
    if (!data.name) {
      errors.name = "Name is required.";
    }
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!data.message) {
      errors.message = "Message is required.";
    }

    if (!radio) {
      errors.type = "Type is required.";
    }

    if (!data.contact) {
      errors.contact = "Contact is required.";
    } else if (!isValidPakistaniPhoneNumber(data.contact)) {
      errors.contact = "Please enter a valid  phone number.";
    }
    setFieldErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep()) {
      let result = await properyEnquery({
        contact_number: "+92" + data?.contact,
        email: data?.email,
        keep_informed: checkbox,
        message: data?.message,
        name: data?.name,
        property_id: parms?.id,
        type: radio,
      });

      if (result?.success) {
        setData({
          name: "",
          email: "",
          contact: "",
          message: "",
          other: "",
          radio: "",
        });
        setCheckbox(false);
        setRadio("Buyer/Tenant");
      }
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const formatPakistaniPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber.replace(/\D/g, ""); // Remove non-digit characters

    // Handle different input formats
    if (phone.startsWith("92")) {
      phone = phone.slice(2);
    } else if (phone.startsWith("+92")) {
      phone = phone.slice(3);
    } else if (phone.startsWith("0")) {
      phone = phone.slice(1);
    }

    // Ensure the phone number is no longer than 10 digits
    if (phone.length > 10) {
      phone = phone.slice(0, 10);
    }
    return phone; // Return processed phone number
  };
  const isValidPakistaniPhoneNumber = (phoneNumber) => {
    let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, "");

    const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;

    const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);

    return isValid;
  };
  const handleRadioChange = (e) => {
    const value = e.target.value;
    setRadio(value);
  };

  const handleCheckbox = (e) => {
    setCheckbox(e.target.checked);
  };

  const handleClick = (div) => {
    setActiveDiv(div);
  };

  const handleImageClick = (img) => {
    setActiveImg(img);
  };

  const handleChange = () => {
    setCurrentDiv("new");
  };

  const handleDefault = () => {
    setCurrentDiv("original");
  };
  useEffect(() => {
    if (profileData?.images?.length) {
      setActiveImg(
        profileData?.images?.length ? profileData?.images[0]?.url : img
      );
    }
  }, [profileData]);
  const [activeVideo, setActiveVideo] = useState(
    profileData?.video_links[0] || ""
  ); // Default to the first video

  const handleVideoClick = (url) => {
    setActiveVideo(url); // Update the main video URL
  };
  const isYouTubeVideo = (url) =>
    url.includes("youtube.com") || url.includes("youtu.be");
  const getEmbedUrl = (url) => {
    if (url.includes("youtube.com/watch?v=")) {
      const videoId = url.split("v=")[1].split("&")[0];
      return `https://www.youtube.com/embed/${videoId}`;
    }
    return url; // Return the URL as-is for non-YouTube videos
  };
  useEffect(() => {
    if (profileData?.video_links?.length) {
      handleVideoClick(profileData?.video_links[0]);
    }
  }, [profileData?.video_links]);
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className="slick-prev custom-arrow"
        onClick={onClick}
        style={{
          borderRadius: "50%",
          fontSize: "20px",
          position: "absolute",
          top: "50%",
          left: "5px",
          zIndex: 1,
          transform: "translateY(-50%)",
        }}
      >
        <IoChevronBackOutline />
      </button>
    );
  };
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className="slick-next custom-arrow"
        onClick={onClick}
        style={{
          borderRadius: "50%",
          fontSize: "20px",
          position: "absolute",
          top: "50%",
          right: "15px",
          zIndex: 1,
          transform: "translateY(-50%)",
        }}
      >
        <IoChevronForwardOutline />
      </button>
    );
  };
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  function formatPrice(value) {
    if (value >= 10000000) {
      // Convert to Crore
      return (value / 10000000).toFixed(2) + " Crore";
    } else if (value >= 100000) {
      // Convert to Lakh
      return (value / 100000).toFixed(2) + " Lakh";
    } else if (value >= 1000) {
      // Convert to Thousand
      return (value / 1000).toFixed(2) + " Thousand";
    } else {
      // Less than 1000, show as is
      return value?.toString();
    }
  }
  //   const Card = ({ item }) => {

  //       return (
  //         <div style={{height:'404px', overflow:"hidden"}}>
  //           <img
  //             className="multi__image"
  //             src={item}
  //             alt=""
  //             style={{
  //               width: "100%",
  //               height: "100%",
  //               objectFit: "cover",
  //             }}
  //           />
  //         </div>
  //       );
  //     };
  //     const [currentSlide, setCurrentSlide] = useState(0);
  //     const carouselProperties = {
  //       prevArrow: "",
  //       nextArrow: "",
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       infinite: true,
  //       dots: false,

  //       lazyLoad: true,
  // autoplay: true,
  // pauseOnHover: true,
  //       autoplaySpeed: 1500,
  //       beforeChange: (oldIndex, newIndex) => {
  //         setCurrentSlide(newIndex);
  //       },

  //     };
  const NextArrow1 = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        onClick={onClick}
        style={{ ...styles.arrow, right: "10px" }}
      >
        &rarr;
      </div>
    );
  };
  
  const PrevArrow1 = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        onClick={onClick}
        style={{ ...styles.arrow, left: "10px" }}
      >
        &larr;
      </div>
    );
  };
  
  const styles = {
    arrow: {
      position: "absolute",
      top: "40%",
      zIndex: "1",
      fontSize: "30px",
      cursor: "pointer",
      color: "#fff",
      transform: "translateY(-50%)",
    },
  };


  const sliderRef = useRef(null);
  const dotsRef = useRef(null);

  // Reinitialize on modal open
  useEffect(() => {
    if (open) {
      // Delay to ensure modal content is fully rendered
      setTimeout(() => {
        const sliderElement = sliderRef.current;
        if (!sliderElement) return;

        // Initialize slick-dots reference
        const dotsUl = sliderElement.querySelector(".slick-dots");
        if (dotsUl) {
          dotsRef.current = dotsUl;
        }

        // Update scroll position for active dot (0 index is initial)
        const activeIndex = 0; // Adjust based on your logic
        handleDotScroll(activeIndex);
      }, 0);
    }
  }, [open, profileData]); // Trigger effect when modal opens or profileData changes

  const handleDotScroll = (activeIndex) => {
    const dotsUl = dotsRef.current;
    if (!dotsUl) return;

    const liElements = dotsUl.querySelectorAll("li");
    const activeDot = liElements[activeIndex];

    if (activeDot) {
      const ulWidth = dotsUl.scrollWidth;
      const ulClientWidth = dotsUl.clientWidth;
      const dotX = activeDot.getBoundingClientRect().x - dotsUl.getBoundingClientRect().x;

      // Center the active dot in the visible area of the dots UL
      const newScrollLeft = dotsUl.scrollLeft + dotX - ulClientWidth / 2;

      // Ensure scroll position is within bounds
      const maxScrollLeft = ulWidth - ulClientWidth;
      dotsUl.scrollTo({
        left: Math.min(Math.max(newScrollLeft, 0), maxScrollLeft),
        behavior: "smooth",
      });
    }
  };

  const Newsettings = {
    customPaging: function (i) {
      const thumbnailUrl = profileData?.images[i]?.url;
      return (
        <a>
          <img
            style={{ width: "100px", height: "70px", objectFit: "cover" }}
            src={thumbnailUrl}
            alt={`Image ${i + 1}`}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: profileData?.images?.length > 1, // Disable infinite loop for a single image
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow1 />,
    nextArrow: <NextArrow1 />,
    beforeChange: (_, next) => {
      handleDotScroll(next); // Update scroll on slide change
    },
  };
  
  
// const [activeSlide, setActiveSlide] = useState(0);
//   const sliderSingleRef = useRef(null);
//   const sliderNavRef = useRef(null);

//   const settingsSingle = {
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     arrows: true,
//     fade: false,
//     adaptiveHeight: true,
//     infinite: false,
//     speed: 400,
//     cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
//     beforeChange: (current, next) => setActiveSlide(next), // Update active slide on change
//   };

//   const settingsNav = {
//     slidesToShow: 7,
//     slidesToScroll: 7,
//     dots: false,
//     focusOnSelect: false,
//     infinite: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 5,
//           slidesToScroll: 5,
//         },
//       },
//       {
//         breakpoint: 640,
//         settings: {
//           slidesToShow: 4,
//           slidesToScroll: 4,
//         },
//       },
//       {
//         breakpoint: 420,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//         },
//       },
//     ],
//   };

//   const handleNavClick = (index) => {
//     if (sliderSingleRef.current) {
//       sliderSingleRef.current.slickGoTo(index); // Go to the clicked slide in the main slider
//     }
//   };

//   useEffect(() => {
//     if (sliderNavRef.current) {
//       // Sync the navigation slider when the main slider changes
//       const currentNavSlideElem = `.slick-slide[data-slick-index="${activeSlide}"]`;
//       const activeSlideNav = document.querySelector(currentNavSlideElem);
//       if (activeSlideNav) {
//         // Remove 'is-active' class from all navigation slides
//         document.querySelectorAll('.slick-slide.is-active').forEach((el) => {
//           el.classList.remove('is-active');
//         });
//         // Add 'is-active' class to the current navigation slide
//         activeSlideNav.classList.add('is-active');
//       }
//     }
//   }, [activeSlide]);


  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setFieldErrors({});
          setData({
            name: "",
            email: "",
            contact: "",
            message: "",
            other: "",
            radio: "",
          });
        }}
        key={open ? "open" : "close"} // Use key to reset modal state when opened
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="card_modal">
          <div
            style={{
              // height: "560px",
              borderRadius: "5px",
              backgroundColor: "white",
              padding: "12px 0 16px 0",
            }}
            className="card_modalNew"
          >
            {/* <div className="head-box px-4">
              <div className="heading">Gallery & Map</div>
              <button
                onClick={() => {
                  setFieldErrors({});
                  setOpen(false);
                  setData({
                    name: "",
                    email: "",
                    contact: "",
                    message: "",
                    other: "",
                    radio: "",
                  });
                }}
                className="close-btn"
              >
                <IoClose />
              </button>
            </div>
            <hr className="mt-2 mb-0" /> */}
            <div className="row m-0 p-0">
              <div className="col-md-7 col-12 ps-md-4 pt-2">
                <div className="gallery-img-card">
                  <div className="row m-0 ">
                    <div className="col-md-4 col-3 p-0">
                      <div
                        className={`tab ${
                          activeDiv === "div1" ? "active" : "dis-active"
                        } text-center`}
                        onClick={() => {
                          handleClick("div1");
                          handleDefault();
                        }}
                        id="div1"
                      >
                        {" "}
                        Photos ({profileData?.images?.length}){" "}
                      </div>
                    </div>
                    {profileData?.video_links?.length ? (
                      <div className="col-lg-3 col-md-4 col-sm-3 col-4 p-0">
                        <div
                          className={`tab ${
                            activeDiv === "div2" ? "active" : "dis-active"
                          } text-center`}
                          onClick={() => {
                            handleClick("div2");
                            handleChange();
                          }}
                          id="div2"
                        >
                          {" "}
                          Videos ({profileData?.video_links?.length})
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-lg-3 col-md-4 col-sm-3 col-2 p-0">
                      <div
                        className={`tab ${
                          activeDiv === "div3" ? "active" : "dis-active"
                        } text-center`}
                        onClick={() => {
                          handleClick("div3");
                          handleChange();
                        }}
                        id="div2"
                      >
                        {" "}
                        Map{" "}
                      </div>
                    </div>
                    <div
                      className="col-lg-2 d-lg-inline d-none p-0"
                      style={{ borderBottom: "1.5px solid #C7C8C9" }}
                    ></div>
                    <div className="d-md-none d-inline col-3 p-0">
                      <div
                        className={`tab ${
                          activeDiv === "div4" ? "active" : "dis-active"
                        } text-center`}
                        onClick={() => {
                          handleClick("div4");
                          handleChange();
                        }}
                        id="div4"
                      >
                        {" "}
                        Contact{" "}
                      </div>
                    </div>
                  </div>
                  {activeDiv === "div1" && (
                    <>
                      <div className="row m-0 d-flex justify-content-center ">
                        <div className="col-md-12 col-10 p-0 d-flex justify-content-center cardImges">
                          <div
                            className="img-box mt-2 "
                            // style={{
                            //   height: `${
                            //     profileData?.images?.length > 1
                            //       ? "334px"
                            //       : "394px"
                            //   }`,
                            // }}
                            style={{
                              width: "100%",
                              height: "max-content",
                              position: "relative",
                             
                            }}
                          >
                            {/* <FallbackImage
                              src={activeImg}
                              alt={"imgae"}
                              fallbackSrc={imgs}
                              className="w-100 h-100"
                              style={{ objectFit: "cover" }}
                            /> */}
                            
                            
                              <div className="slider-container propertySlide" ref={sliderRef}>
                              <Slider {...Newsettings}>
                                {profileData?.images?.map((item, i) => (
                                  <div key={i}>
                                    <img
                                    className="projectDetailImgs"
                                      src={item?.url}
                                      alt={`Slide ${i + 1}`}
                                    />
                                  </div>
                                ))}
                              </Slider>
                             
                            </div>


                           

                           
                          </div>
                        </div>
                      </div>
                      
                    </>
                  )}
                  {activeDiv === "div2" && (
                    <>
                      {/* Main Video Section */}
                      <div className="row m-0 d-flex justify-content-center">
                        <div className="col-md-12 col-10 p-0 d-flex justify-content-center cardImges">
                          <div
                            className="video-box my-4 cardNewImg"
                            style={{ height: "334px", width: "100%" }}
                          >
                            {isYouTubeVideo(activeVideo) ? (
                              <iframe
                                className="w-100 h-100"
                                src={getEmbedUrl(activeVideo)}
                                title="1 Kanal Ultra Luxury Full Furnished House for SALE in DHA Lahore By Pakistan Property"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen
                              />
                            ) : (
                              <video
                                src={activeVideo}
                                controls
                                autoPlay
                                className="w-100 h-100"
                                style={{ objectFit: "cover" }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {profileData?.video_links?.length > 1 && (
                        <div className="main-img-box">
                          {profileData?.video_links?.length <= 3 && (
                            <div className="img-tab d-flex ps-2">
                              {profileData?.video_links?.map((img) => (
                                <div
                                  className="img-tab"
                                  onClick={() => handleVideoClick(img)}
                                >
                                  <FallbackImage
                                    src={videoImg}
                                    alt={"imgae"}
                                    fallbackSrc={videoImg}
                                    style={{
                                      width: "100px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                          {profileData?.video_links?.length > 3 && (
                            <div className="img-tab ps-2">
                              <Slider {...settings}>
                                {profileData?.video_links?.map((img) => (
                                  <div
                                    className="img-tab"
                                    onClick={() => handleVideoClick(img)}
                                  >
                                    <FallbackImage
                                      src={videoImg}
                                      alt={"imgae"}
                                      fallbackSrc={videoImg}
                                      style={{
                                        width: "100px",
                                        height: "70px",
                                        objectFit: "cover",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </div>
                                ))}
                              </Slider>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {activeDiv === "div3" && (
                    <div className="row d-flex justify-content-center m-0">
                      <div className="col-md-12 col-10 p-0 cardImges">
                        <div className="map-box my-4 px-0 cardMap">
                          <iframe
                            title="map"
                            src={`https://maps.google.com/maps?q=${profileData?.location?.latitude},${profileData?.location?.longitude}&z=15&output=embed`}
                            width="100%"
                            height="400"
                            style={{ border: "0" }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeDiv === "div4" && (
                    <div className="row justify-content-center m-0 ">
                      <div className="col-12 d-md-none d-inline pt-2 mt-sm-0 mt-2  new_modal_row scroll-box-y">
                        <div className="gallery-input-card p-0 m-0">
                          <div className="price">
                            {profileData?.currency}{' '}
                            {formatPrice(
                              profileData?.price ? profileData?.price : 0
                            )}
                          </div>
                          <button
                            onClick={() => handleOpen()}
                            className="call-btn mt-2 mb-3"
                          >
                            <FaPhoneAlt
                              style={{ marginRight: "8px", fontSize: "10px" }}
                            />{" "}
                            Call
                          </button>
                          <div className="company d-flex align-items-center">
                            <FallbackImage
                              src={
                                profileData?.user?.avatar
                                  ? profileData?.user?.avatar
                                  : img
                              }
                              alt={"...."}
                              fallbackSrc={img}
                              style={{ width: "65px", height: "100%" }}
                            />
                            <div className="details mx-2">
                              <div className="name">
                                {profileData?.user?.name}
                              </div>

                              <div className="owner">
                                {profileData?.user?.email}
                              </div>
                            </div>
                          </div>
                          <hr className="mb-0" />
                          <div className="input-box px-2 scroll-box-y">
                            <div className="input-label">Name</div>
                            <input
                              type="text"
                              className={`form-control input ${
                                fieldErrors.name ? "is-invalid" : ""
                              }`}
                              placeholder="Name"
                              value={data.name}
                              onChange={(e) =>
                                setData({ ...data, name: e.target.value })
                              }
                            />
                            <p className="alert-msg px-2 my-1">
                              <i>
                                {fieldErrors.name ? "Name is required" : ""}
                              </i>
                            </p>
                            <div className="input-label">Email</div>
                            <input
                              type="email"
                              className={`form-control input ${
                                fieldErrors.email ? "is-invalid" : ""
                              }`}
                              placeholder="Email"
                              value={data.email}
                              onChange={(e) =>
                                setData({ ...data, email: e.target.value })
                              }
                            />
                            <p className="alert-msg px-2 my-1">
                              <i>
                                {fieldErrors.email ? fieldErrors.email : ""}
                              </i>
                            </p>
                            <div className="input-label">Contact</div>
                            <div className="input-group flex-nowrap d-flex align-items-center">
                              <span
                                className="input-group-text number"
                                id="addon-wrapping"
                              >
                                <img
                                  style={{ marginRight: "8px" }}
                                  src={flag}
                                  alt="..."
                                />
                                +92 <IoMdArrowDropdown />
                              </span>
                              <input
                                type="text"
                                className={`form-control input ${
                                  fieldErrors.contact ? "is-invalid" : ""
                                }`}
                                placeholder="e.g 3324751060"
                                value={data.contact}
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    contact: formatPakistaniPhoneNumber(
                                      e.target.value
                                    ),
                                  })
                                }
                              />
                            </div>
                            <p className="alert-msg px-2 my-1">
                              <i>
                                {fieldErrors.contact
                                  ? "Contact number is required"
                                  : ""}
                              </i>
                            </p>
                            <div className="input-label">Message</div>
                            <textarea
                              type="text"
                              className={`form-control input message-input ${
                                fieldErrors.message ? "is-invalid" : ""
                              }`}
                              placeholder="Message"
                              value={data.message}
                              onChange={(e) =>
                                setData({ ...data, message: e.target.value })
                              }
                            />
                            <p className="alert-msg px-2 my-1">
                              <i>
                                {fieldErrors.message
                                  ? "Message is required"
                                  : ""}
                              </i>
                            </p>
                            <div className="option d-flex justify-content-between align-items-start pe-sm-4 ps-0 my-2">
                              <div
                                className="text cardChooseSer pe-1 pe-sm-0"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                I am a:
                              </div>
                              <div className="d-flex flex-wrap">
                                <div
                                  className="form-check"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioGroup"
                                    id="exampleRadios1"
                                    value="Buyer/Tenant"
                                    checked={radio === "Buyer/Tenant"}
                                    onChange={(e) => {
                                      handleRadioChange(e);
                                      setData({
                                        ...data,
                                        radio: e.target.value,
                                      });
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios1"
                                  >
                                    Buyer/Tenant
                                  </label>
                                </div>
                                <div
                                  className="form-check"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioGroup"
                                    id="exampleRadios2"
                                    value="Agent"
                                    checked={radio === "Agent"}
                                    onChange={(e) => {
                                      handleRadioChange(e);
                                      setData({
                                        ...data,
                                        radio: e.target.value,
                                      });
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios2"
                                  >
                                    Agent
                                  </label>
                                </div>
                                <div
                                  className="form-check"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioGroup"
                                    id="exampleRadios3"
                                    value="Other"
                                    checked={radio === "Other"}
                                    onChange={(e) => {
                                      handleRadioChange(e);
                                      setData({
                                        ...data,
                                        radio: e.target.value,
                                      });
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios3"
                                  >
                                    Other
                                  </label>
                                </div>
                              </div>
                            </div>
                            <p className="alert-msg px-2 my-1">
                              <i>
                                {fieldErrors?.type
                                  ? "Please select an option"
                                  : ""}
                              </i>
                            </p>

                            <div className="form-check last-check my-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault"
                                checked={checkbox}
                                onChange={handleCheckbox}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              >
                                Keep me informed about similar properties
                              </label>
                            </div>
                          </div>
                          <button
                            className="send-btn my-1"
                            onClick={handleSubmit}
                          >
                            Send Email
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-5 col-12  d-md-inline d-none pe-4 pt-2">
                <div className="gallery-input-card p-0 m-0">
                  <div className="modaldiv d-flex justify-content-between">

                  <div className="price">
                    {profileData?.currency}{" "}
                    {formatPrice(profileData?.price ? profileData?.price : 0)}
                  </div>
                  <button
                onClick={() => {
                  setFieldErrors({});
                  setOpen(false);
                  setData({
                    name: "",
                    email: "",
                    contact: "",
                    message: "",
                    other: "",
                    radio: "",
                  });
                }}
                className="close-btn"
              >
                <IoClose />
              </button>
                  </div>
                  <button
                    onClick={() => handleOpen()}
                    className="call-btn mt-2 mb-3"
                  >
                    <FaPhoneAlt
                      style={{ marginRight: "8px", fontSize: "10px" }}
                    />{" "}
                    Call
                  </button>
                  <div className="company d-flex align-items-center">
                    <FallbackImage
                      src={
                        profileData?.user?.avatar
                          ? profileData?.user?.avatar
                          : img
                      }
                      alt={"...."}
                      fallbackSrc={img}
                      style={{ width: "65px", height: "100%" }}
                    />
                    <div className="details mx-2">
                      <div className="name">{profileData?.user?.name}</div>

                      <div className="owner">{profileData?.user?.email}</div>
                    </div>
                  </div>
                  <hr className="mb-0" />
                  <div className="input-box px-2 scroll-box-y">
                    <div className="input-label">Name</div>
                    <input
                      type="text"
                      className={`form-control input ${
                        fieldErrors.name ? "is-invalid" : ""
                      }`}
                      placeholder="Name"
                      value={data.name}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                    />
                    <p className="alert-msg px-2 my-1">
                      <i>{fieldErrors.name ? "Name is required" : ""}</i>
                    </p>
                    <div className="input-label">Email</div>
                    <input
                      type="email"
                      className={`form-control input ${
                        fieldErrors.email ? "is-invalid" : ""
                      }`}
                      placeholder="Email"
                      value={data.email}
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                    />
                    <p className="alert-msg px-2 my-1">
                      <i>{fieldErrors.email ? fieldErrors.email : ""}</i>
                    </p>
                    <div className="input-label">Contact</div>
                    <div className="input-group flex-nowrap d-flex align-items-center">
                      <span
                        className="input-group-text number"
                        id="addon-wrapping"
                      >
                        <img
                          style={{ marginRight: "8px" }}
                          src={flag}
                          alt="..."
                        />
                        +92 <IoMdArrowDropdown />
                      </span>
                      <input
                        type="text"
                        className={`form-control input ${
                          fieldErrors.contact ? "is-invalid" : ""
                        }`}
                        placeholder="e.g 3324751060"
                        value={data.contact}
                        onChange={(e) =>
                          setData({
                            ...data,
                            contact: formatPakistaniPhoneNumber(e.target.value),
                          })
                        }
                      />
                    </div>
                    <p className="alert-msg px-2 my-1">
                      <i>
                        {fieldErrors.contact
                          ? "Contact number is required"
                          : ""}
                      </i>
                    </p>
                    <div className="input-label">Message</div>
                    <textarea
                      type="text"
                      className={`form-control input message-input ${
                        fieldErrors.message ? "is-invalid" : ""
                      }`}
                      placeholder="Message"
                      value={data.message}
                      onChange={(e) =>
                        setData({ ...data, message: e.target.value })
                      }
                    />
                    <p className="alert-msg px-2 my-1">
                      <i>{fieldErrors.message ? "Message is required" : ""}</i>
                    </p>
                    <div className="option d-flex justify-content-around  pe-4 ps-0 my-2">
                      <div className="text" style={{ whiteSpace: "nowrap" }}>
                        I am a:
                      </div>
<div className="identification">


                      <div className="form-check ms-2"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="radioGroup"
                          id="exampleRadios1"
                          value="Buyer/Tenant"
                          checked={radio === "Buyer/Tenant"}
                          onChange={(e) => {
                            handleRadioChange(e);
                            setData({ ...data, radio: e.target.value });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          Buyer/Tenant
                        </label>
                      </div>
                      <div className="form-check ms-2"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="radioGroup"
                          id="exampleRadios2"
                          value="Agent"
                          checked={radio === "Agent"}
                          onChange={(e) => {
                            handleRadioChange(e);
                            setData({ ...data, radio: e.target.value });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          Agent
                        </label>
                      </div>
                      <div className="form-check ms-2"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="radioGroup"
                          id="exampleRadios3"
                          value="Other"
                          checked={radio === "Other"}
                          onChange={(e) => {
                            handleRadioChange(e);
                            setData({ ...data, radio: e.target.value });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios3"
                        >
                          Other
                        </label>
                      </div>
</div>
                    </div>
                    <p className="alert-msg px-2 my-1">
                      <i>
                        {fieldErrors?.type ? "Please select an option" : ""}
                      </i>
                    </p>

                    <div className="form-check last-check my-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        checked={checkbox}
                        onChange={handleCheckbox}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Keep me informed about similar properties
                      </label>
                    </div>
                  </div>
                  <button className="send-btn my-1" onClick={handleSubmit}>
                    Send Email
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Card;
