import React, { useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/InstantValue/Banner'
import Instant from '../Camponent/InstantValue/Instant'
import PropertyVlaue from '../Camponent/InstantValue/PropertyVlaue'
import Footer from '../Camponent/NavBarFooter/Footer'
import { useAuth } from '../Context/ContextProvider'


function InstantValue() {
 const {propertyList,data,setData}=useAuth()
  const [pagination,setPagination]=useState("")
  const [city_value, setCityValue] = useState({
    city: "",
    location: ""
  })
  const [property_name,setPropertyName]=useState()
  return (
    <div>
      <NavBar/>
      <Banner setPropertyName={setPropertyName} data={data} setData={setData} setPagination={setPagination} setCityValue={setCityValue} city_value={city_value} />
      <Instant/>
      <PropertyVlaue/>
      <Footer/>
    </div>
  )
}

export default InstantValue
