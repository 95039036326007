import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useAuth } from "../../Context/ContextProvider";
import { TbHomeDollar, TbHomeRibbon, TbHomeStats } from "react-icons/tb";
import { IoCloseSharp } from "react-icons/io5";

function PopularProperties({
  setPropertyData,
  setPropertyAll,
  propertyListAll,
}) {
  const { type, serchProperty } = useAuth();
  const [search, setSearch] = useState(false);
  const [type_id, setTypeId] = useState();
  useEffect(() => {
    const fetchProperties = async () => {
      if (type?.length) {
        try {
          setTypeId(type[0]?.id); // Set the initial type ID
          const result = await serchProperty({ property_type_id: type[0]?.id });
          if (result?.success) {
            const properties = result?.data?.data?.properties;
            setPropertyData(properties); // Update filtered property data
            setPropertyAll(properties); // Update all property data
          }
        } catch (error) {
          console.error("Error fetching property data:", error);
        }
      }
    };

    fetchProperties();
  }, [type]);

  const handleSearch = async (value) => {
    if (!value) {
      // Reset to full list if the search value is empty

      setPropertyData(propertyListAll);

      return;
    }

    const array = propertyListAll?.filter((item) =>
      item?.title?.toLowerCase().includes(value.toLowerCase())
    );

    setPropertyData(array);
  };
  return (
    <div className="popular_properties container-fluid main_banner_home">
      <h1 className="mt-4 mb-2 text-sm-start text-center">Featured Properties</h1>
      <div className="d-flex flex-wrap justify-content-between pop-input">
        <div className="btn_popular">
          <div className="d-flex" style={{ width: "fit-content" }}>
            {type?.map((item) => {
              return (
                <button
                  onClick={async () => {
                    setTypeId(item?.id);
                    let result = await serchProperty({
                      property_type_id: item?.id,
                    });
                    if (result?.success) {
                      setPropertyData(result?.data?.data?.properties);
                      setPropertyAll(result?.data?.data?.properties);
                    }
                  }}
                  style={{
                    background: `${type_id === item?.id ? "white" : "none"}`,
                    border: `${
                      type_id === item?.id ? "1px solid lightgray" : "none"
                    }`,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item?.slug === "sell" && <TbHomeDollar className="me-2" />}
                  {item?.slug === "rent" && <TbHomeRibbon className="me-2" />}
                  {item?.slug === "lease" && <TbHomeStats className="me-2" />}

                  {item?.name === "Sell" ? "Buy" : item?.name}
                </button>
              );
            })}
          </div>
          {/* <button onClick={()=>setBuy(true)} style={{background:`${buy?"white":"none"}`,border:`${buy?"1px solid lightgray":"none"}`}}><IoKeyOutline/> Rent</button> */}
          <div className="inp_populer projectSearch">
            {search ? (
              <IoCloseSharp
                style={{ fontSize: "23px", padding: "0", margin: "0 .2rem" }}
                onClick={() => setSearch(!search)}
              />
            ) : (
              <CiSearch
                style={{ fontSize: "23px", padding: "0", margin: "0 .2rem" }}
                onClick={() => setSearch(!search)}
              />
            )}
          </div>
        </div>
        <div className="inp_populer mt-md-0 mt-3 projectSearchnew">
          <CiSearch
            style={{ fontSize: "23px", padding: "0", margin: "0 .2rem" }}
          />
          <input
            onChange={(e) => handleSearch(e.target.value)}
            className="p-0"
            placeholder="Search"
            type="search"
          />
        </div>
      </div>
      {
        search?

      <div
        className="new_search_inp_populer projectSearch"
     
      >
        <input
          onChange={(e) => handleSearch(e.target.value)}
          className="p-0"
          placeholder="Search"
          type="search"
        />
      </div>
      :""
      }
    </div>
  );
}

export default PopularProperties;
