import React, { useEffect, useState } from "react";
import { MdRemoveShoppingCart } from "react-icons/md";
import { useAuth } from "../../Context/ContextProvider";
import { AiOutlineMinus } from "react-icons/ai";
import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";

function BuyProduct() {
  const { getProductList, addToCart, loading, getCart, formatPriceWithCommas } =
    useAuth();
  const [list, setList] = useState();
  const [cartData1, setCartData1] = useState([]);
  const [allcartData, setallCartData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [card_id, setCart_id] = useState();
  const fetchCart = async () => {
    try {
      let result = await getCart();
      if (result?.success) {
        setallCartData(
          result?.data?.data?.cart?.products
            ? result?.data?.data?.cart?.products
            : []
        );
        let array = [];
        let array1 = [];
        result?.data?.data?.cart?.products?.filter((item) => {
          if (item?.type == "credits") {
            array1.push(item);
          } else {
            array.push(item);
          }
        });
        setCartData(array);
        setCartData1(array1);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await getProductList();
        if (result?.success) {
          setList(result?.data?.data?.products);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };
    fetchData();
    fetchCart();
    // eslint-disable-next-line
  }, []);
  const handleAddQuantity = async (item) => {
    const updatedCart = allcartData.map((cartItem) =>
      cartItem.product_id === item?.id
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    );
    let array = [];
    let array1 = [];
    updatedCart?.filter((item) => {
      if (item?.type == "credits") {
        array1.push(item);
      } else {
        array.push(item);
      }
    });
    setCartData(array);
    setCartData1(array1);
    setallCartData(updatedCart);

    await addToCart({
      cart_key: "sdsa988239832hqudhas",
      products: updatedCart,
    });
  };

  const handleDecreaseQuantity = async (item) => {
    const updatedCart = allcartData.map((cartItem) =>
      cartItem.product_id === item?.id
        ? {
            ...cartItem,
            quantity: cartItem.quantity > 1 ? cartItem.quantity - 1 : 1,
          }
        : cartItem
    );
    let array = [];
    let array1 = [];
    updatedCart?.filter((item) => {
      if (item?.type == "credits") {
        array1.push(item);
      } else {
        array.push(item);
      }
    });
    setCartData(array);
    setCartData1(array1);
    setallCartData(updatedCart);

    await addToCart({
      cart_key: "sdsa988239832hqudhas",
      products: updatedCart,
    });
  };

  const handleRemoveItem = async (item) => {
    const updatedCart = allcartData.filter(
      (cartItem) => cartItem.product_id !== item?.id
    );
    let array = [];
    let array1 = [];
    updatedCart?.filter((item) => {
      if (item?.type == "credits") {
        array1.push(item);
      } else {
        array.push(item);
      }
    });
    setCartData(array);
    setCartData1(array1);
    setallCartData(updatedCart);
    await addToCart({
      cart_key: "sdsa988239832hqudhas",
      products: updatedCart,
    });
  };
  const navigate = useNavigate();
  return (
    <div className="content_box">
      <div className="row m-0 d-flex justify-content-lg-start justify-content-center">
        <div className="col-lg-9 col-12 ps-0 ">
          <div className="buy_product add_propertey_main">
            <div className="scroll-box-y">
              <div className="locationPurpos">
                <h3 className="heading">Listings</h3>
                <div
                  className="scroll-box"
                  style={{ padding: "0 12px", overflowY: "auto" }}
                >
                  <div className="row table_helping_row">
                    <div className="col-12 p-0">
                      <div className="row table_row d-lg-flex d-none">
                        <div className="col-xxl-7 col-xl-6 col-lg-6 col-md-6 col-6">
                          <h5 className="new_heading">Product</h5>
                        </div>
                        <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-6 ps-0 pe-0">
                          <h5
                            style={{ paddingLeft: "21px" }}
                            className="new_heading"
                          >
                            Price
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 p-0">
                      <div className="table_data">
                        <div className="table_header sticky-header d-lg-none d-flex">
                          <div className="row table_row border m-0">
                            <div className="col-6">
                              <h5 className="new_heading">Product</h5>
                            </div>
                            <div className="col-6">
                              <h5
                                style={{ paddingLeft: "23px" }}
                                className="new_heading"
                              >
                                Price
                              </h5>
                            </div>
                          </div>
                        </div>

                        <div className="table_body">
                          {list?.map((item) => {
                            if (item?.type === "listings") {
                              const isInCart = cartData.some(
                                (cartItem) => cartItem.product_id === item?.id
                              );
                              const cartItem = cartData.find(
                                (cartItem) => cartItem.product_id === item?.id
                              );

                              return (
                                <div
                                  className="row w-100 table_data_item"
                                  key={item?.id}
                                >
                                  <div className="col-xxl-7 col-xl-6 col-lg-6 col-md-6 col-6">
                                    <div className="data_pro py-2">
                                      <div className="img">
                                        <img src={item?.image} alt="..." />
                                      </div>
                                      <div className="d-flex justify-content-center flex-column mx-2 ps-1">
                                        <h6 className="p-0 mb-2">
                                          {item?.name}
                                        </h6>
                                        <span className="pt-1">
                                          {item?.description}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-6 ps-0 pe-0">
                                    <div
                                      className="d-flex justify-content-between align-items-center w-100 h-100 new_table_body"
                                      style={{ paddingLeft: "35px" }}
                                    >
                                      <h6 className="m-0">
                                        Rs {formatPriceWithCommas(item?.price)}
                                      </h6>
                                      {!isInCart ? (
                                        <button
                                          onClick={async () => {
                                            setCart_id(item?.id);
                                            let result = await addToCart({
                                              cart_key: "sdsa988239832hqudhas",
                                              products: [
                                                ...allcartData,
                                                {
                                                  product_id: item?.id,
                                                  quantity: 1,
                                                  price: item?.price,
                                                  name: item?.name,
                                                  type: item.type,
                                                },
                                              ],
                                            });
                                            if (result?.success) {
                                              fetchCart();
                                              setCartData([
                                                ...cartData,
                                                {
                                                  product_id: item?.id,
                                                  quantity: 1,
                                                  price: item?.price,
                                                  name: item?.name,
                                                  type: item.type,
                                                },
                                              ]);
                                            }
                                          }}
                                        >
                                          {loading && item?.id === card_id ? (
                                            <div
                                              className="spinner-border text-light button_loading"
                                              role="status"
                                            ></div>
                                          ) : (
                                            "Add to Cart"
                                          )}
                                        </button>
                                      ) : (
                                        <div className="btn_carts d-flex">
                                          <button
                                            onClick={() => {
                                              setCart_id(item?.id);
                                              handleDecreaseQuantity(item);
                                            }}
                                          >
                                            <AiOutlineMinus />
                                          </button>
                                          <button
                                            className="quantity_btn"
                                            disabled
                                            style={{
                                              color: "lightgray",
                                              borderColor: "lightgray",
                                            }}
                                          >
                                            {cartItem?.quantity}
                                          </button>
                                          <button
                                            onClick={() => {
                                              setCart_id(item?.id);
                                              handleAddQuantity(item);
                                            }}
                                          >
                                            <GoPlus />
                                          </button>
                                          <button className="Deletlist"
                                            onClick={() => {
                                              setCart_id(item?.id);
                                              handleRemoveItem(item);
                                            }}
                                            style={{
                                              color: "lightgray",
                                              borderColor: "#FF3C3C",
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                            >
                                              <path
                                                d="M7.82031 9V15"
                                                stroke="#FF0000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M12.1836 9V15"
                                                stroke="#FF0000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M1.27344 5H18.728"
                                                stroke="#FF0000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M3.45508 5H10.0005H16.546V16C16.546 17.6569 15.0808 19 13.2733 19H6.72781C4.92033 19 3.45508 17.6569 3.45508 16V5Z"
                                                stroke="#FF0000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M6.72656 3C6.72656 1.89543 7.7034 1 8.90838 1H11.0902C12.2952 1 13.272 1.89543 13.272 3V5H6.72656V3Z"
                                                stroke="#FF0000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="locationPurpos my-4">
                <h3 className="heading">
                  Credits{" "}
                  <span className="new_sub_heading">
                    (Only applicable on already posted listings)
                  </span>
                </h3>
                <div className="scroll-box">
                  <div className="row m-0 table_helping_row">
                    <div className="col-12 p-0">
                      <div className="row table_row d-lg-flex d-none">
                        <div className="col-xxl-7 col-xl-6 col-lg-6 col-md-6 col-6">
                          <h5 className="new_heading">Product</h5>
                        </div>
                        <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-6 ps-0 pe-0">
                          <h5
                            style={{ paddingLeft: "21px" }}
                            className="new_heading"
                          >
                            Price
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 p-0">
                      <div className="table_data w-100">
                        <div className="table_header sticky-header d-lg-none d-flex">
                          <div className="row table_row border m-0">
                            <div className="col-sm-7 col-8">
                              <h5 className="new_heading m-0">Product</h5>
                            </div>
                            <div className="col-sm-5 col-4 px-md-3 px-0 d-flex align-items-center">
                              <h5
                                className="new_heading m-0"
                                style={{ paddingLeft: "21px" }}
                              >
                                Price
                              </h5>
                            </div>
                          </div>
                        </div>

                        <div className="table_body">
                          {list?.map((item) => {
                            if (item?.type === "credits") {
                              const isInCart = cartData1.some(
                                (cartItem) => cartItem.product_id === item?.id
                              );
                              const cartItem = cartData1.find(
                                (cartItem) => cartItem.product_id === item?.id
                              );

                              return (
                                <div
                                  className="row w-100 table_data_item"
                                  key={item?.id}
                                >
                                  <div className="col-xxl-7 col-xl-6 col-lg-6 col-md-6 col-6">
                                    <div className="data_pro py-2">
                                      <div className="img">
                                        <img src={item?.image} alt="..." />
                                      </div>
                                      <div className="d-flex justify-content-center flex-column mx-2 ps-1">
                                        <h6 className="p-0 mb-2">
                                          {item?.name}
                                        </h6>
                                        <span className="pt-1">
                                          {item?.description}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-6 ps-0 pe-0">
                                    <div
                                      className="d-flex justify-content-between align-items-center w-100 h-100 new_table_body"
                                      style={{ paddingLeft: "35px" }}
                                    >
                                      <h6 className="m-0">
                                        Rs {formatPriceWithCommas(item?.price)}
                                      </h6>
                                      {!isInCart ? (
                                        <button
                                          onClick={async () => {
                                            setCart_id(item?.id);
                                            let result = await addToCart({
                                              cart_key: "sdsa988239832hqudhas",
                                              products: [
                                                ...allcartData,
                                                {
                                                  product_id: item?.id,
                                                  quantity: 1,
                                                  price: item?.price,
                                                  name: item?.name,
                                                  type: item.type,
                                                },
                                              ],
                                            });
                                            if (result?.success) {
                                              fetchCart();
                                              setCartData([
                                                ...cartData,
                                                {
                                                  product_id: item?.id,
                                                  quantity: 1,
                                                  price: item?.price,
                                                  name: item?.name,
                                                  type: item.type,
                                                },
                                              ]);
                                            }
                                          }}
                                        >
                                          {loading && item?.id === card_id ? (
                                            <div
                                              className="spinner-border text-light button_loading"
                                              role="status"
                                            ></div>
                                          ) : (
                                            "Add to Cart"
                                          )}
                                        </button>
                                      ) : (
                                        <div className="btn_carts d-flex">
                                          <button
                                            onClick={() => {
                                              setCart_id(item?.id);
                                              handleDecreaseQuantity(item);
                                            }}
                                          >
                                            <AiOutlineMinus />
                                          </button>
                                          <button
                                            className="quantity_btn"
                                            disabled
                                            style={{
                                              color: "lightgray",
                                              borderColor: "lightgray",
                                            }}
                                          >
                                            {cartItem?.quantity}
                                          </button>
                                          <button
                                            onClick={() => {
                                              setCart_id(item?.id);
                                              handleAddQuantity(item);
                                            }}
                                          >
                                            <GoPlus />
                                          </button>
                                          <button
                                            onClick={() => {
                                              setCart_id(item?.id);
                                              handleRemoveItem(item);
                                            }}
                                            style={{
                                              color: "lightgray",
                                              borderColor: "#FF3C3C",
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                            >
                                              <path
                                                d="M7.82031 9V15"
                                                stroke="#FF0000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M12.1836 9V15"
                                                stroke="#FF0000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M1.27344 5H18.728"
                                                stroke="#FF0000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M3.45508 5H10.0005H16.546V16C16.546 17.6569 15.0808 19 13.2733 19H6.72781C4.92033 19 3.45508 17.6569 3.45508 16V5Z"
                                                stroke="#FF0000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M6.72656 3C6.72656 1.89543 7.7034 1 8.90838 1H11.0902C12.2952 1 13.272 1.89543 13.272 3V5H6.72656V3Z"
                                                stroke="#FF0000"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 ps-0 pe-3 add_propertey_main">
          <div className="locationPurpos order_summery py-0 px-2 mx-lg-0 mx-1">
            <h6 className=" new_heading head pt-2 my-2">Order Summary</h6>
            <hr className="p-0 m-0" />
            {cartData?.length || cartData1?.length ? (
              <>
                <div className="cart_item pb-3">
                  {cartData?.length ? (
                    <>
                      {/* <div className='d-flex justify-content-between'>
                                    <span style={{ fontWeight: "700", fontSize: "16px" }}>Listing</span>
                                </div> */}
                      {cartData?.map((item) => {
                        return (
                          <div className="d-flex justify-content-between cart_list">
                            {item?.name === "Listing" ? (
                              <>
                                <span>
                                  {item?.name} ({item?.quantity})
                                </span>
                                <span>
                                  Rs {formatPriceWithCommas(item?.price)}
                                </span>
                              </>
                            ) : (
                              <>
                                <div className="mt-2">
                                  <span>{item?.name}</span>
                                  <p
                                    style={{
                                      padding: "0",
                                      margin: "0",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {item?.name?.replace("Listing", "Credits")}{" "}
                                    <span>({item?.quantity}X)</span>
                                  </p>
                                  <p
                                    style={{
                                      padding: "0",
                                      margin: "0",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {"Listing"} <span>({item?.quantity}X)</span>
                                  </p>
                                </div>
                                <span>
                                  Rs {formatPriceWithCommas(item?.price)}
                                </span>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}

                  {cartData1?.length ? (
                    <>
                      {/* <div className='d-flex justify-content-between'>
                                    <span style={{ fontWeight: "700", fontSize: "16px" }}>Credits</span>
                                </div> */}
                      {cartData1?.map((item) => {
                        return (
                          <div className="d-flex justify-content-between cart_list">
                            <span>
                              {item?.name} ({item?.quantity})
                            </span>
                            <span>Rs {formatPriceWithCommas(item?.price)}</span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                  <hr className="p-0 m-0" />
                  <div className="d-flex justify-content-between align-items-center total">
                    <h6>Total</h6>
                    <span style={{ lineHeight: "0" }}>
                      Rs{" "}
                      {formatPriceWithCommas(
                        [...cartData, ...cartData1]?.reduce(
                          (acc, item) => acc + item.price * item.quantity,
                          0
                        )
                      )}
                    </span>
                  </div>
                  <button
                    onClick={async () => {
                      navigate("/agent/payment");
                    }}
                  >
                    {"Proceed To Payment"}
                  </button>
                </div>
              </>
            ) : (
              <div className="no_cart">
                <MdRemoveShoppingCart className="icon" />
                <span className="mt-3">No Item(s) in Cart</span>
                <span>Rs 00</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyProduct;
