import React, { useEffect, useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/HousingSocietyDetailsExp/Banner'
import SimilarsProjects from '../Camponent/HousingSocietyDetailsExp/SimilarsProjects'
import Footer from '../Camponent/NavBarFooter/Footer'
import { useAuth } from '../Context/ContextProvider'
import { useParams } from 'react-router-dom'
function HousingSocietyDetailsExp() {
  const {projectDetail}=useAuth()
  const parms=useParams()
  const [projectData,setProjectData]=useState()
  const [aroundProject,setAroundProject]=useState([])
  const [sameDeveloper,setSameDeveloper]=useState([])
  useEffect(()=>{
    const fetchData = async () => {
      try {
        let result = await projectDetail(parms?.id);
        if (result?.success) {
          const propertyTypes = result?.data?.data?.project;
          setProjectData(propertyTypes)
          setAroundProject(result?.data?.data?.similar_projects_near_by)
          setSameDeveloper(result?.data?.data?.similar_projects_by_developer)
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    }
    fetchData()
  },[])
  return (
    <>
      <NavBar />
      <Banner projectData={projectData}/>
      {aroundProject?.length || sameDeveloper?.length ?<SimilarsProjects  projectData={projectData}  sameDeveloper={sameDeveloper} aroundProject={aroundProject}/>:""}
      {/* <PopularSearches /> */}
      <Footer />
    </>
  )
}
export default HousingSocietyDetailsExp