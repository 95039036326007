import React, { useState } from "react";
import { useAuth } from "../../Context/ContextProvider";
function PriceArea({ data, setData, fieldErrors }) {
  const { area_unit, currency } = useAuth();
  const [prices, setPrices] = useState();
  const formatNumber = (num) => {
    if (num >= 1_00_00_00_000) {
      return `${(num / 1_00_00_00_000).toFixed(2)} Billion`;
    } else if (num >= 1_00_00_000) {
      return `${(num / 1_00_00_000).toFixed(2)} Crore`;
    } else if (num >= 1_00_000) {
      return `${(num / 1_00_000).toFixed(2)} Lakh`;
    } else if (num >= 1_000) {
      return `${(num / 1_000).toFixed(2)} Thousand`;
    } else if (num >= 1_00) {
      return `${(num / 1_00).toFixed(2)} Hundred`;
    }
    return num.toString();
  };
  return (
    <div className="locationPurpos feature_amenities my-sm-3 my-3 addPropInp">
      <div className="d-flex align-items-center">
        <svg
          className="location_svg_2"
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="30"
          viewBox="0 0 34 30"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.17236 8.96387V25.2626C6.17236 26.6128 7.26693 27.7074 8.61717 27.7074H13.5068V29.3373H8.61717C6.36678 29.3373 4.54248 27.513 4.54248 25.2626V8.96387H6.17236ZM27.3607 13.8535V8.96387H28.9906V13.8535H27.3607Z"
            fill="#B7B7B7"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.2988 0.147312C16.5793 -0.0491041 16.9528 -0.0491041 17.2335 0.147312L33.5323 11.5564L32.5975 12.8917L16.7661 1.8097L0.934669 12.8917L0 11.5564L16.2988 0.147312Z"
            fill="#B7B7B7"
          />
          <path
            d="M31.2132 20.2031L26.8161 15.8334C26.2212 15.2387 25.4194 14.9543 24.5917 15.006L20.0654 15.3163C19.4446 15.368 18.9532 15.7817 18.7722 16.3505C18.5394 16.3764 18.2807 16.4539 18.0479 16.5832C17.4531 16.9194 17.1168 17.5399 17.0133 18.419C16.884 19.5826 17.7117 20.3841 18.7463 21.0564V21.6252C18.7463 22.375 19.0567 23.099 19.5998 23.642L22.445 26.4345C22.2898 26.7706 22.1087 27.055 21.8501 27.2619C21.1776 27.8049 20.0137 27.8825 18.4618 27.5205C18.1773 27.4429 17.8928 27.6239 17.841 27.9083C17.7634 28.1927 17.9445 28.4771 18.229 28.5289C18.9273 28.684 19.574 28.7874 20.143 28.7874C21.1259 28.7874 21.9018 28.5547 22.4967 28.0893C22.7812 27.8566 23.0399 27.5722 23.2209 27.236L23.7123 27.7273C24.2296 28.2444 24.928 28.503 25.6263 28.503C26.3247 28.503 26.9972 28.2444 27.5403 27.7273L31.239 24.0299C31.7563 23.5127 32.0408 22.8405 32.0408 22.1165C32.0408 21.3925 31.7304 20.7202 31.2132 20.2031ZM24.54 19.505C24.54 20.229 23.971 20.7978 23.2468 20.7978C22.5226 20.7978 21.9535 20.229 21.9535 19.505C21.9535 18.781 22.5226 18.2122 23.2468 18.2122C23.971 18.2122 24.54 18.781 24.54 19.505ZM18.0738 18.5224C18.1255 17.9795 18.3066 17.6433 18.5652 17.4882C18.617 17.4623 18.6687 17.4365 18.7204 17.4106L18.7463 19.7636C18.3066 19.3757 18.0221 18.962 18.0738 18.5224Z"
            fill="#1C9093"
          />
        </svg>
        <h4 className="new_heading mt-2">Price and Area</h4>
      </div>
      {data?.slug !== "lease" && (
        <div className="row">
          <div className="col-lg-5 col-md-6 col-12">
            <div className="inp_login new_inp_login_1">
              <label className="logo_label">Area Size</label>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  overflow: "hidden",
                  marginTop: "4px",
                  display: "flex",
                }}
              >
                <select
                  value={data?.area_unit}
                  onChange={(e) =>
                    setData({ ...data, area_unit: e.target.value })
                  }
                  style={{
                    width: "max-content",
                    padding: "10.5px 0.4rem 11.5px 0.4rem",
                    border: "none",
                    borderRadius: "0px",
                    background: "#F5F5F5",
                  }}
                >
                  <option hidden>Marla</option>
                  {area_unit?.map((item) => {
                    return (
                      <option title={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
                <input
                  min={0}
                  style={{ border: "none", marginTop: "0" }}
                  value={data?.area}
                  onChange={(e) => setData({ ...data, area: e.target.value })}
                  type="number"
                  placeholder="Enter Unit"
                />
              </div>
              {fieldErrors?.area && (
                <span className="text_error">{fieldErrors?.area}</span>
              )}
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-12">
            <div className="inp_login new_inp_login_1">
              <label className="logo_label">
                {data?.slug === "rent" ? "Monthly" : ""} Price{" "}
              </label>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  overflow: "hidden",
                  display: "flex",
                  marginTop: "4px",
                  display: "flex",
                }}
              >
                <select
                  value={data?.currency}
                  onChange={(e) =>
                    setData({ ...data, currency: e.target.value })
                  }
                  style={{
                    width: "80px",
                    padding: "10.5px 0.4rem 11.5px 0.4rem",
                    border: "none",
                    borderRadius: "0px",
                    background: "#F5F5F5",
                  }}
                >
                  <option hidden>PKR</option>
                  {currency?.map((item) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })}
                </select>
                <input
                  min={0}
                  style={{
                    // width: "75%",
                    border: "none",
                    display: "flex",
                    marginTop: "0",
                  }}
                  value={data?.price}
                  onChange={(e) => {
                    setData({ ...data, price: e.target.value });
                    setPrices(e.target.value);
                  }}
                  type="number"
                  placeholder="price"
                />

                {fieldErrors?.price && (
                  <span className="text_error">{fieldErrors?.price}</span>
                )}
                {prices && (
                  <span className="priceCount"
                    style={{
                     
                      ...(fieldErrors?.price && { right:'0'  }),
                    }}
                  >
                    {formatNumber(prices)}
                  </span>
                )}
              </div>

              {/* {data?.price && <label className="logo_label">{data?.currency} {data?.price}</label>} */}
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-12">
            <div className="d-flex align-items-center inp_login">
              <div className="inp_login">
                <label className="logo_label my-2">Installment available</label>
                <p>Enable if listing is available on installment</p>
              </div>
              <div className="switch mx-2">
                <input
                  checked={data?.installment}
                  onChange={(e) =>
                    setData({ ...data, installment: e.target.checked })
                  }
                  type="checkbox"
                  id={`toggle1`}
                />
                <label htmlFor={`toggle1`} className="slider"></label>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-12">
            <div className="d-flex align-items-center inp_login">
              <div className="inp_login">
                <label className="logo_label my-2">Ready for Possession</label>
                <p>Enable if listing is ready for possession</p>
              </div>
              <div className="switch mx-2">
                <input
                  checked={data?.possession}
                  onChange={(e) =>
                    setData({ ...data, possession: e.target.checked })
                  }
                  type="checkbox"
                  id={`toggle-possession-2`}
                />
                <label
                  htmlFor={`toggle-possession-2`}
                  className="slider"
                ></label>
              </div>
            </div>
          </div>
          {data?.installment && (
            <div className="row">
              <div className="col-lg-5 col-md-6 col-12">
                <div className="inp_login">
                  <label className="logo_label">Advance Amount</label>
                  <div
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "5px",
                      overflow: "hidden",
                      marginTop: "4",
                      display: "flex",
                    }}
                  >
                    <select
                      disabled={true}
                      value={data?.currency}
                      onChange={(e) =>
                        setData({ ...data, currency: e.target.value })
                      }
                      style={{
                        width: "100px",
                        padding: "10.5px 0.4rem 11.5px 0.4rem",
                        border: "none",
                        borderRadius: "0px",
                        background: "#F5F5F5",
                        cursor: "not-allowed",
                      }}
                    >
                      <option hidden>Select currency</option>
                      {currency?.map((item) => {
                        return <option value={item?.id}>{item?.name}</option>;
                      })}
                    </select>
                    <input
                      min={0}
                      style={{ border: "none", marginTop: "0" }}
                      value={data?.advanced_amount}
                      onChange={(e) =>
                        setData({ ...data, advanced_amount: e.target.value })
                      }
                      type="number"
                      placeholder="Advance Amount"
                    />
                    {fieldErrors?.advanced_amount && (
                      <span className="text_error">
                        {fieldErrors?.advanced_amount}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-12">
                <div className="inp_login">
                  <label className="logo_label">Monthly Installments</label>
                  <div
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "5px",
                      overflow: "hidden",
                      marginTop: "4",
                      display: "flex",
                    }}
                  >
                    <select
                      disabled={true}
                      value={data?.currency}
                      onChange={(e) =>
                        setData({ ...data, currency: e.target.value })
                      }
                      style={{
                        width: "100px",
                        padding: "10.5px 0.4rem 11.5px 0.4rem",
                        border: "none",
                        borderRadius: "0px",
                        background: "#F5F5F5",
                        cursor: "not-allowed",
                      }}
                    >
                      <option hidden>Select currency</option>
                      {currency?.map((item) => {
                        return <option value={item?.id}>{item?.name}</option>;
                      })}
                    </select>
                    <input
                      min={0}
                      style={{ border: "none", marginTop: "0" }}
                      value={data?.monthly_installments}
                      onChange={(e) =>
                        setData({
                          ...data,
                          monthly_installments: e.target.value,
                        })
                      }
                      type="number"
                      placeholder="Monthly Installments"
                    />
                    {fieldErrors?.monthly_installments && (
                      <span className="text_error">
                        {fieldErrors?.monthly_installments}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-12">
                <div className="inp_login">
                  <label className="logo_label">No of Installments</label>
                  <input
                    min={0}
                    value={data?.number_of_installments}
                    onChange={(e) =>
                      setData({
                        ...data,
                        number_of_installments: e.target.value,
                      })
                    }
                    type="number"
                    placeholder="No of Installments"
                  />
                  {fieldErrors?.number_of_installments && (
                    <span className="text_error">
                      {fieldErrors?.number_of_installments}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {data?.slug === "lease" && (
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="inp_login">
              <label className="logo_label">Duration</label>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  overflow: "hidden",
                  display: "flex",
                }}
              >
                <select
                  value={data?.lease?.duration_type}
                  onChange={(e) =>
                    setData({
                      ...data,
                      lease: {
                        ...data.lease,
                        duration_type: e.target.value,
                      },
                    })
                  }
                  style={{
                    width: "max-content",
                    padding: ".7rem .4rem",
                    border: "none",
                    borderRadius: "0px",
                    background: "#F5F5F5",
                  }}
                >
                  <option value={"month"}>Months</option>
                  <option value={"year"}>Years</option>
                </select>
                <input
                  onChange={(e) =>
                    setData({
                      ...data,
                      lease: {
                        ...data.lease,
                        duration: e.target.value,
                      },
                    })
                  }
                  style={{ border: "none" }}
                  value={data?.lease?.duration}
                  type="text"
                  placeholder="Enter Unit"
                />
              </div>
              {fieldErrors?.duration && (
                <span className="text_error">{fieldErrors?.duration}</span>
              )}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="inp_login">
              <label className="logo_label">
                Lease Rate 
              </label>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  overflow: "hidden",
                  display: "flex",
                }}
              >
                <select
                  value={data?.currency}
                  onChange={(e) =>
                    setData({ ...data, currency: e.target.value })
                  }
                  style={{
                    width: "100px",
                    padding: ".7rem .4rem",
                    border: "none",
                    borderRadius: "0px",
                    background: "#F5F5F5",
                  }}
                >
                  <option hidden>Select currency</option>
                  {currency?.map((item) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })}
                </select>
                <input
                  style={{ border: "none" }}
                  value={data?.price}
                  onChange={(e) => {
                    setData({ ...data, price: e.target.value });
                    setPrices(e.target.value);
                  }}
                  type="number"
                  placeholder="price"
                />

                {fieldErrors?.price && (
                  <span className="text_error">{fieldErrors?.price}</span>
                )}
                {prices && (
                  <span className="priceCount"
                   
                  >
                    {formatNumber(prices)}
                  </span>
                )}
              </div>
              {/* {data?.price && <label className="logo_label">{data?.currency} {data?.price}</label>} */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="inp_login">
              <label className="logo_label">Available Space</label>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  overflow: "hidden",
                  display: "flex",
                }}
              >
                <select
                  value={data?.area_unit}
                  onChange={(e) =>
                    setData({ ...data, area_unit: e.target.value })
                  }
                  style={{
                    width: "max-content",
                    padding: ".7rem .4rem",
                    border: "none",
                    borderRadius: "0px",
                    background: "#F5F5F5",
                  }}
                >
                  <option hidden>Unit</option>
                  {area_unit?.map((item) => {
                    return (
                      <option title={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
                <input
                  min={0}
                  style={{ border: "none" }}
                  value={data?.area}
                  onChange={(e) => setData({ ...data, area: e.target.value })}
                  type="number"
                  placeholder="Area"
                />

                {fieldErrors?.area && (
                  <span className="text_error">{fieldErrors?.area}</span>
                )}
              </div>
              {/* {data?.price && <label className="logo_label">{data?.currency} {data?.price}</label>} */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="inp_login">
              <label className="logo_label">Building Size</label>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  overflow: "hidden",
                  display: "flex",
                }}
              >
                <select
                  value={data?.lease?.building_unit_area}
                  onChange={(e) =>
                    setData({
                      ...data,
                      lease: {
                        ...data.lease,
                        building_unit_area: e.target.value,
                      },
                    })
                  }
                  style={{
                    width: "max-content",
                    padding: ".7rem .4rem",
                    border: "none",
                    borderRadius: "0px",
                    background: "#F5F5F5",
                  }}
                >
                  <option hidden>Unit</option>
                  {area_unit?.map((item) => {
                    return (
                      <option title={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
                <input
                  style={{ border: "none" }}
                  value={data?.lease?.building_area_size}
                  onChange={(e) =>
                    setData({
                      ...data,
                      lease: {
                        ...data.lease,
                        building_area_size: e.target.value,
                      },
                    })
                  }
                  type="number"
                  placeholder="Area"
                />

                {fieldErrors?.building_area_size && (
                  <span className="text_error">
                    {fieldErrors?.building_area_size}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="inp_login">
              <label className="logo_label">Lease Type</label>
              <select
                value={data?.lease?.type}
                onChange={(e) =>
                  setData({
                    ...data,
                    lease: {
                      ...data.lease,
                      type: e.target.value,
                    },
                  })
                }
                style={{ padding: ".7rem .4rem" }}
              >
                <option hidden>Select Type</option>
                <option value={"new"}>New</option>
                <option value={"sublease"}>sublease</option>
                <option value={"relet"}>Relet</option>
                <option value={"assignment"}>Assignment</option>
              </select>
              {fieldErrors?.lease_type && (
                <span className="text_error">{fieldErrors?.lease_type}</span>
              )}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="inp_login">
              <label className="logo_label">Available Type</label>
              <select
                value={data?.lease?.availability}
                onChange={(e) =>
                  setData({
                    ...data,
                    lease: {
                      ...data.lease,
                      availability: e.target.value,
                    },
                  })
                }
                style={{ padding: ".7rem .4rem" }}
              >
                <option hidden>Select Type</option>
                <option value={"now"}>Available</option>
                <option value={"soon"}>Available Soon</option>
              </select>
              {fieldErrors?.availability && (
                <span className="text_error">{fieldErrors?.availability}</span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PriceArea;
