import React from 'react'
import img2 from '../../Images/dummy/images.png'
function NoReacodFound() {
  return (
    <div className='row justify-content-center py-3 m-0'>
      <div style={{ width: "max-content" }}>
        <img style={{ width: "180px" }} src={img2} alt='not Found' />
      </div>

    </div>
  )
}

export default NoReacodFound