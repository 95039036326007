import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/AboutUs/Banner'
import Footer from '../Camponent/NavBarFooter/Footer'
import WhatWeDo from '../Camponent/AboutUs/WhatWeDo'
import Entrance from '../Camponent/AboutUs/Entrance'

function AboutUs() {
  return (
    <div>
      
      <NavBar/>
      <Banner/>
      <WhatWeDo/>
      <Entrance/>
      <Footer/> 
    </div>
  )
}

export default AboutUs
