import { createContext, useContext, useState } from "react";
export const Authdata = createContext();
export const Authprovider = ({ children }) => {
  const [loginData, setLogindata] = useState(null)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState([])
  const [projecttype, setProjectType] = useState([])
  const [propertyListCity, setPropertyList] = useState([])
  const [HomePage, setHomePage] = useState([])
  const [currencies, setcurrencies] = useState([])
  const [areaUnit, setareaUnit] = useState([])
  const [currentCityProject, setCurrentCityProject] = useState()
  const [projectFilterData, setProjectFilter] = useState({

  })
  const [cityData, setcityData] = useState([])
  const [currentCityAgency, setCurrentCityAgency] = useState({
    city_name: "",
    app_code: ""
  })
  const [aminitiesLoding,setAminitiesloading]=useState(false)
  const [dataAgency, setDataAgency] = useState({
    city_code: currentCityAgency?.app_code || "PP016"
  })
  const [allcity, setallcity] = useState([])
  const [category, setCategory] = useState([])
  const [button_loading, setButtonLoading] = useState(false)
  const [sub_category, setsub_category] = useState([])
  const [error, setError] = useState({
    message: "",
    color: ""
  });
  const [currentCity, setCurrentCity] = useState({
    city_name: "",
    app_code: ""
  })

  const [data, setData] = useState({
    city_code: "",
    location_id: "",
    property_type_id: "",
    sub_category_id: "",
    category_id: "",
    currency: "",
    unit_area: "",
    status: "",
    rooms: "",
    price: {
      min: "",
      max: ""
    },
    area_size: {
      min: "",
      max: ""
    },

  })
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  let userData = loginData ? loginData : JSON.parse(localStorage.getItem("agent_data"))
  let base_url = process.env.REACT_APP_API_URL;
  const globalHeaders = {
    'Authorization': userData ? `Bearer ${userData?.data?.token}` : '',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };
  const globalFetch = (url, options = {}) => {
    // Ensure options.headers exists and merge with globalHeaders
    options.headers = {
      ...globalHeaders,
      ...options.headers, // In case you need to add additional headers per request
    };
    // Call the native fetch function with the updated options
    return fetch(url, options);
  };
  const login = (credentials) => {
    setLoading(true);
    return globalFetch(`${base_url}auth/login`, {
      method: 'POST',
      body: JSON.stringify(credentials),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          setLogindata(result); // Store user data in state
          localStorage.setItem("agent_data", JSON.stringify(result)); // Save to localStorage
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const socailLogin = (credentials) => {
    setLoading(true);
    return globalFetch(`${base_url}auth/social-login`, {
      method: 'POST',
      body: JSON.stringify(credentials),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          setLogindata(result); // Store user data in state
          localStorage.setItem("agent_data", JSON.stringify(result)); // Save to localStorage
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };

  const registar = (data) => {
    setLoading(true);
    return globalFetch(`${base_url}auth/${data?.role}/register`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({ message: result?.message, color: "success" })
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const handleAddProperty = async (jsonState) => {
    setButtonLoading(true)
    const formData = new FormData();

    // Adding simple key-value pairs
    formData.append('description', jsonState.ad_description);
    formData.append('area_size', jsonState.area);
    formData.append('title', jsonState.ad_title);
    formData.append('user_id', userData?.data?.user?.id);
    formData.append('unit_area', jsonState.area_unit);
    formData.append('bathrooms', jsonState.bathrooms);
    formData.append('bedrooms', jsonState.bedrooms);
    formData.append('city_code', jsonState.city_id);
    formData.append('currency', jsonState.currency);
    formData.append('email', jsonState.email);
    if (jsonState?.installment) {
      formData.append('installments_available', jsonState.installment);
      formData.append('monthly_installments', jsonState.monthly_installments);
      formData.append('number_of_installments', jsonState.number_of_installments);
      formData.append('advanced_amount', jsonState.advanced_amount);
    }
    if (jsonState.land_line) {
      formData.append('land_line', "+92" + jsonState.land_line);
    }
    else {
      formData.append('land_line', "");
    }
    formData.append('plot_number', jsonState.plot_number);
    formData.append('ready_for_possession', jsonState.possession);
    formData.append('price', jsonState.price);
    formData.append('sub_category_id', jsonState.property_sub_type);
    formData.append('category_id', jsonState.property_type);
    formData.append('location_id', jsonState.state_id);
    formData.append('property_type_id', jsonState.type);
    formData.append('property_type_slug', jsonState.slug);
    // Handling arrays
    jsonState.mobile.forEach((mobile, index) => {
      formData.append(`contacts[${index}]`, "92" + mobile);
    });
    jsonState.video_links.forEach((link, index) => {
      formData.append(`video_links[${index}]`, link);
    });
    // Handling FileList
    for (let i = 0; i < jsonState.images.length; i++) {
      if (i === 0) {
        formData.append('cover_image', jsonState.images[i]);

      }
      formData.append(`images[${i}]`, jsonState.images[i]);
    }
    // Handling nested objects and arrays (like amenities)
    // Concatenate all the amenity data arrays into a single array
    const concatenatedAmenities = jsonState.amenities.reduce((acc, amenity) => {
      return acc.concat(amenity.data);
    }, []);
    // Iterate over the concatenated array and append to formData
    concatenatedAmenities.forEach((item, itemIndex) => {
      formData.append(`amenities[${itemIndex}][id]`, item.amenity_id);
      item.selected_options.forEach((option, optionIndex) => {
        formData.append(`amenities[${itemIndex}][value]`, option);
      });
    });
    if (jsonState.lease) {
      Object.keys(jsonState.lease).forEach(key => {
        formData.append(`lease[${key}]`, jsonState.lease[key]);
      });
    }

    // Send formData to backend
    try {
      const response = await fetch(`${base_url}agent/properties`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': userData ? `Bearer ${userData?.data?.token}` : '',
          'Accept': 'application/json',
        }
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        setButtonLoading(false)
        handleClick()
        setError({ message: jsonData?.message, color: "success" })
        return { success: true, data: jsonData };
      }
      else {
        setButtonLoading(false)

        // handleClick()

        // setError({message:jsonData?.data?.phone_number[0]?jsonData?.data?.phone_number[0]:jsonData?.data?.email[0],color:"error"})
      }
    } catch (error) {
      setButtonLoading(false)

    } finally {
      setButtonLoading(false)

      // setLoading(false); 
    }
  }
  const handleUpdateProperty = async (jsonState) => {
    const formData = new FormData();
    setButtonLoading(true)

    // Adding simple key-value pairs
    formData.append('description', jsonState.ad_description);
    formData.append('area_size', jsonState.area);
    formData.append('title', jsonState.ad_title);
    formData.append('user_id', userData?.data?.user?.id);
    formData.append('unit_area', jsonState.area_unit);
    formData.append('bathrooms', jsonState.bathrooms);
    formData.append('bedrooms', jsonState.bedrooms);
    formData.append('city_code', jsonState.city_id);
    formData.append('currency', jsonState.currency);
    formData.append('email', jsonState.email);
    formData.append('installments_available', jsonState.installment);
    if (jsonState.installment) {
      formData.append('monthly_installments', jsonState.monthly_installments);
      formData.append('number_of_installments', jsonState.number_of_installments);
      formData.append('advanced_amount', jsonState.advanced_amount);
    }
    if (jsonState.land_line) {
      formData.append('land_line', "+92" + jsonState.land_line);
    }
    else {
      formData.append('land_line', "");
    }
    formData.append('plot_number', jsonState.plot_number);
    formData.append('ready_for_possession', jsonState.possession);
    formData.append('price', jsonState.price);
    formData.append('sub_category_id', jsonState.property_sub_type);
    formData.append('category_id', jsonState.property_type);
    formData.append('location_id', jsonState.state_id);
    formData.append('_method', "put");
    if (jsonState?.deleted_media) {
      jsonState.deleted_media.forEach((item, index) => {
        formData.append(`deleted_media[${index}]`, item);
      });

    }
    formData.append('property_type_id', jsonState.type);
    formData.append('property_type_slug', jsonState.slug);

    // Handling arrays
    jsonState.mobile.forEach((mobile, index) => {
      formData.append(`contacts[${index}]`, "92" + mobile);
    });

    jsonState.video_links.forEach((link, index) => {
      formData.append(`video_links[${index}]`, link);
    });

    // Handling FileList
    for (let i = 0; i < jsonState.images.length; i++) {
      if (i === 0) {
        formData.append('cover_image', jsonState.images[i]);
      }
      formData.append(`images[${i}]`, jsonState.images[i]);
    }

    // Handling nested objects and arrays (like amenities)
    // Concatenate all the amenity data arrays into a single array
    const concatenatedAmenities = jsonState.amenities.reduce((acc, amenity) => {
      return acc.concat(amenity.data);
    }, []);

    // Iterate over the concatenated array and append to formData
    concatenatedAmenities.forEach((item, itemIndex) => {
      formData.append(`amenities[${itemIndex}][id]`, item.amenity_id);
      item.selected_options.forEach((option, optionIndex) => {
        formData.append(`amenities[${itemIndex}][value]`, option);
      });
    });

    // Send formData to backend
    try {
      const response = await fetch(`${base_url}agent/properties/${jsonState?.id}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': userData ? `Bearer ${userData?.data?.token}` : '',
          'Accept': 'application/json',
        }
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        handleClick()
        setButtonLoading(false)

        setError({ message: jsonData?.message, color: "success" })
        return { success: true, data: jsonData };
      }
      else {
        setButtonLoading(false)

      }
    } catch (error) {
      setButtonLoading(false)

    } finally {
      setButtonLoading(false)

    }
  }
  const getType = () => {
    return globalFetch(`${base_url}agent/property/types`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getcateGory = (id) => {
    return globalFetch(`${base_url}agent/properties/categories/${id}`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getcateGoryPublic = (id) => {
    return globalFetch(`${base_url}property/categories/${id}`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getsubCateGory = (id) => {
    return globalFetch(`${base_url}agent/properties/sub_categories/${id}`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getAmintites = (id) => {
    setAminitiesloading(true)
    return globalFetch(`${base_url}agent/properties/amenities/${id}`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          setAminitiesloading(false)

          return { success: true, data: result };
        } else {
          setAminitiesloading(false)

          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setAminitiesloading(false)

        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getProprtey = (data) => {
    setLoading(true)
    return globalFetch(`${base_url}agent/properties/all`, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          setLoading(false)
          return { success: true, data: result };
        } else {
          setLoading(false)

          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getProprteyById = (id) => {
    return globalFetch(`${base_url}agent/properties/${id}`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getCity = () => {
    return globalFetch(`${base_url}getCities`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getCities = (value) => {
    return globalFetch(`${base_url}search-cities?name=${value}`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getOrderLIst = () => {
    return globalFetch(`${base_url}agent/orders`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getLOcation = (data) => {

    return globalFetch(`${base_url}getLocationsByCity`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getProductList = () => {
    return globalFetch(`${base_url}agent/products`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const addToCart = (data) => {
    setLoading(true);
    return globalFetch(`${base_url}agent/carts`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({ message: result?.message, color: "success" })
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const updateCart = (data) => {
    setLoading(true);
    return globalFetch(`${base_url}agent/carts`, {
      method: 'PUT',
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({ message: result?.message, color: "success" })
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const craeteOrder = async (data) => {
    setLoading(true);
    const formData = new FormData();


    formData.append('image', data.image);
    try {
      const response = await fetch(`${base_url}agent/orders/pay-now`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': userData ? `Bearer ${userData?.data?.token}` : '',
          'Accept': 'application/json',
        }
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        setLoading(false);
        handleClick()
        setError({ message: jsonData?.message, color: "success" })
        return { success: true, data: jsonData };
      }
      else {
        handleClick()
        setError({ message: jsonData?.errors?.image?.length?jsonData?.errors?.image[0]:"Please Upload image jpeg, png, jpg", color: "error" })
        setLoading(false);
      }

    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const payLaterOrder = async () => {
    setLoading(true);
    return globalFetch(`${base_url}agent/orders/pay-later`, {
      method: 'POST',
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setLoading(false);
          setError({ message: result?.message, color: "success" })
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getCart = () => {
    return globalFetch(`${base_url}agent/carts`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const updateOrder = async (data) => {
    setLoading(true);
    const formData = new FormData();


    formData.append('image', data.image);
    try {
      const response = await fetch(`${base_url}agent/orders/${data?.orderId}`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': userData ? `Bearer ${userData?.data?.token}` : '',
          'Accept': 'application/json',
        }
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        setLoading(false)
        handleClick()
        setError({ message: jsonData?.message, color: "success" })
        return { success: true, data: jsonData };
      }
      else {

      }

    } catch (error) {
    } finally {

    }
  };
  const updateProfile = (data) => {
    setLoading(true);
    return globalFetch(`${base_url}profile`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({ message: "Profile Update Successfully", color: "success" })
          if (userData && userData.data && userData.data.user) {

            let newUserData = { ...result.data };

            let obj = {
              address: newUserData?.address,
              avatar: newUserData?.avatar,
              city_id: newUserData?.city?.app_code,
              currentCity: newUserData?.city,
              connected_account_id: userData?.data?.user?.connected_account_id,
              country_id: userData?.data?.user?.address?.country_id,
              created_at: userData?.data?.user?.created_at,
              created_by: userData?.data?.user?.created_by,
              description: userData?.data?.user?.description,
              device_token: userData?.data?.user?.device_token,
              email: userData?.data?.user?.email,
              email_verified_at: userData?.data?.user?.email_verified_at,
              guard_name: userData?.data?.user?.guard_name,
              id: userData?.data?.user?.id,
              is_active: userData?.data?.user?.is_active,
              name: userData?.data?.user.name,
              phone_number: newUserData?.phone_number,
              whatsapp_number: newUserData?.whatsapp_number,
              landline: newUserData?.landline,
              state_id: newUserData?.address,
              updated_at: userData?.data?.user?.updated_at,
              username: userData?.data?.user?.username
            }

            userData.data.user = obj;

            localStorage.setItem("agent_data", JSON.stringify(userData));
          }
          return { success: true, data: result };
        } else {
          if (result?.message) {
            handleClick()
            setError({ message: result?.message, color: "error" })

          }
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getQoutaLogs = (value) => {
    if (value) {
      return globalFetch(`${base_url}agent/quota/logs?date=${value}`, {
        method: "GET"
      })
        .then(async (response) => {

          const result = await response.json();

          if (response.ok) {

            return { success: true, data: result };
          } else {

            return { success: false, error: result };
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error('Login error:', error);
          return { success: false, error: 'An unexpected error occurred. Please try again.' };
        });
    }
    else {
      return globalFetch(`${base_url}agent/quota/logs`, {
        method: "GET"
      })
        .then(async (response) => {

          const result = await response.json();

          if (response.ok) {

            return { success: true, data: result };
          } else {

            return { success: false, error: result };
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error('Login error:', error);
          return { success: false, error: 'An unexpected error occurred. Please try again.' };
        });
    }
  };
  const getQoutaState = () => {
    return globalFetch(`${base_url}agent/quota/offers`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const changePassword = (data) => {
    setLoading(true);
    return globalFetch(`${base_url}auth/change_password`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          setLoading(false)
          handleClick()
          setError({ message: result?.message, color: "success" })
          return { success: true, data: result };
        } else {
          setLoading(false)
          handleClick()
          setError({ message: result?.message, color: "error" })
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const updateSetting = (data) => {
    setLoading(true);
    return globalFetch(`${base_url}profile-setting/update`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          setLoading(false)

          if (userData?.data?.user) {

            if (!userData.data.user.setting) {
              userData.data.user.setting = {};
            }


            userData.data.user.setting = result?.data;
          }


          localStorage.setItem("agent_data", JSON.stringify(userData));


          handleClick()
          setError({ message: "User Setting Updated", color: "success" })
          return { success: true, data: result };
        } else {
          setLoading(false)
          handleClick()
          setError({ message: result?.message, color: "error" })
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const [propertyList, setPropertyData] = useState([])
  const serchProperty = (data) => {
    setLoading(true);
    return globalFetch(`${base_url}properties`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          setLoading(false)
          setPropertyData(result?.data?.properties)
          return { success: true, data: result };
        } else {
          setLoading(false)
          handleClick()
          setError({ message: result?.message, color: "error" })
          setPropertyData([])
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        setPropertyData([])
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const deleteProperty = (id) => {
    return globalFetch(`${base_url}agent/properties/${id}?type=status`, {
      method: "DELETE"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  function formatPriceWithCommas(price) {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const getPropertyDetail = (id) => {
    setLoading(true)
    return globalFetch(`${base_url}properties/${id}`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          setLoading(false)
          return { success: true, data: result };
        } else {
          setLoading(false)

          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const getTypePublic = () => {
    return globalFetch(`${base_url}property/types-with-categories`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const upgradeProperty = (data) => {
    setLoading(true);
    return globalFetch(`${base_url}agent/properties/upgrade/${data?.property_id}`, {
      method: 'POST',
      body: JSON.stringify({ offer_id: data?.offer_id }),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({ message: result?.message, color: "success" })
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const agencyProfile = (data) => {
    setLoading(true);
    return globalFetch(`${base_url}agency-profile/create`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        setLoading(false);

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({ message: "Profile Update Successfully", color: "success" })
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const agencyCeo = (data) => {
    setLoading(true);
    return globalFetch(`${base_url}agency-profile/create-ceo-profile`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        setLoading(false);
        const result = await response.json();
        if (response.ok) {
          handleClick()
          setError({ message: "Profile Update Successfully", color: "success" })
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getagency = () => {
    setLoading(true);
    return globalFetch(`${base_url}agency-profile`, {
      method: 'GET',
    })
      .then(async (response) => {
        setLoading(false);
        const result = await response.json();
        if (response.ok) {
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Registration error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getDashboradListing = () => {
    return globalFetch(`${base_url}agent/property/listing`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const getDashboradAnalaytics = (data) => {
    return globalFetch(`${base_url}agent/property/analytics`, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const properyEnquery = (data) => {
    return globalFetch(`${base_url}property/inquiry/${data?.property_id}`, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({
            message: result?.message,
            color: "success"
          })
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  };
  const whatsappImpretion = (value, id) => {
    return globalFetch(`${base_url}property/${value}/${id}`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const newslater = (email) => {
    return globalFetch(`${base_url}subscribe-newsletter?email=${email}`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({
            message: result?.message,
            color: "success"
          })
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const [currency, setcurrencyDataList] = useState([])
  const currencyData = () => {
    return globalFetch(`${base_url}currencies`, {
      method: "GET"
    })
      .then(async (response) => {
        const result = await response.json();
        if (response.ok) {
          setcurrencyDataList(result?.data?.currencies)
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }

  const [area_unit, setareaUnitDataList] = useState([])
  const areaUnitData = () => {
    let array1 = ["Sq. Ft.","Sq. M.","Sq. Yd.","Marla","Kanal"]
          let array = ["Square Feet ( Sq. Ft.)","Square Meters (Sq. M.)","Square Yards ( Sq. Yd.)","Marla","Kanal"]?.map((item,i) => ({
            // name: item,
            name:array1[i],
            id: item,
          })) || [];
          setareaUnitDataList(array);
        
  }

  const neighbouringProperty = () => {
    return globalFetch(`${base_url}neighbouring-properties`, {
      method: "GET"
    })
      .then(async (response) => {
        const result = await response.json();
        if (response.ok) {
       
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const recentProperty = () => {
    return globalFetch(`${base_url}agent/property/recent-listing`, {
      method: "GET"
    })
      .then(async (response) => {
        const result = await response.json();
        if (response.ok) {

          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const rePostProperty = (id) => {
    return globalFetch(`${base_url}agent/properties/repost/${id}`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({
            message: result?.message,
            color: "success"
          })
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const featureAgency = () => {
    return globalFetch(`${base_url}agencies/featured`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const planAgency = () => {
    setLoading(true)
    return globalFetch(`${base_url}agencies/categories`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          setLoading(false)
          return { success: true, data: result };
        } else {
          setLoading(false)
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const cityAgency = () => {
    return globalFetch(`${base_url}agencies/cities`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const agencyById = (id) => {
    setLoading(true)
    return globalFetch(`${base_url}agency?agency_id=${id}`, {
      method: "GET"
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          setLoading(false)
          return { success: true, data: result };
        } else {
          setLoading(false)

          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const [featureAgencyList, setFeatureAgencyList] = useState()
  const agencyList = (data) => {
    return globalFetch(`${base_url}agencies`, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const [projectFilter, setProjectFilterData] = useState()

  const [projectTypeData, setProjectTypeData] = useState([])
  const projectType = () => {
    return globalFetch(`${base_url}project-types`, {
      method: "GET",

    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const [projectListing, setProjectListing] = useState([])
  const projectDataList = (data) => {
    return globalFetch(`${base_url}projects`, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const projectData = (id) => {
    return globalFetch(`${base_url}projects`, {
      method: "POST",
      body: JSON.stringify({ project_type_id: id })
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const [projectListingData, setProjectListingData] = useState([])

  const projectDetail = (id) => {
    setLoading(true)
    return globalFetch(`${base_url}projects/${id}`, {
      method: "GET",
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
        setLoading(false);

          return { success: true, data: result };
        } else {
        setLoading(false);

          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const bankDetail = () => {
    return globalFetch(`${base_url}bank-accounts`, {
      method: "GET",
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const agencyInquery = (data) => {
    return globalFetch(`${base_url}agency/inquiry/${data?.id}`, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({
            message: result?.message,
            color: "success"
          })
          return { success: true, data: result };
        } else {
          handleClick()
          setError({
            message: result?.message,
            color: "error"
          })
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const projectIquiry = (data) => {
    return globalFetch(`${base_url}projects/inquiry/${data?.id}`, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({
            message: result?.message,
            color: "success"
          })
          return { success: true, data: result };
        } else {
          handleClick()
          setError({
            message: result?.message,
            color: "error"
          })
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const contact_us = (data) => {
    return globalFetch(`${base_url}contact-us`, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({
            message: result?.message,
            color: "success"
          })
          return { success: true, data: result };
        } else {
          handleClick()
          setError({
            message: result?.message,
            color: "error"
          })
          return { success: false, error: result };
        }
      })
      .catch((error) => {

        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }


  const instant = (data) => {
    return globalFetch(`${base_url}instant-valuation-request`, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({
            message: result?.message,
            color: "success"
          })
          return { success: true, data: result };
        } else {
          handleClick()
          setError({
            message: result?.message,
            color: "error"
          })
          return { success: false, error: result };
        }
      })
      .catch((error) => {

        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }




  const sendEmailVerification = (token) => {
    return globalFetch(`${base_url}auth/email_verification?data=${token}`, {
      method: "GET",
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          return { success: true, data: result };
        } else {
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const sendEmailforget = (email) => {
    return globalFetch(`${base_url}auth/forgot_password?email=${email?.email}`, {
      method: "GET",
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({ message: result?.message, color: "success" })
          return { success: true, data: result };
        } else {
          handleClick()
          setError({ message: result?.message, color: "error" })
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const forget_password = (data) => {
    return globalFetch(`${base_url}auth/reset_password`, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({ message: result?.message, color: "success" })
          return { success: true, data: result };
        } else {
          handleClick()
          setError({ message: result?.message, color: "error" })
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  const resedEmail = (email) => {
    return globalFetch(`${base_url}auth/send_verification_email?email=${email}`, {
      method: "GET",
    })
      .then(async (response) => {

        const result = await response.json();

        if (response.ok) {
          handleClick()
          setError({ message: result?.message, color: "success" })
          return { success: true, data: result };
        } else {
          handleClick()
          setError({ message: result?.message, color: "error" })
          return { success: false, error: result };
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Login error:', error);
        return { success: false, error: 'An unexpected error occurred. Please try again.' };
      });
  }
  return (
    <Authdata.Provider value={{
      resedEmail,
      forget_password,
      socailLogin,
      sendEmailforget,
      sendEmailVerification,
      login,
      getAmintites,
      rePostProperty,
      serchProperty,
      getType,
      handleUpdateProperty,
      updateOrder,
      getCart,
      getcateGory,
      getsubCateGory,
      registar,
      setLogindata,
      loading,
      open,
      setOpen,
      handleClick,
      error,
      setError,
      handleAddProperty,
      getcateGoryPublic,
      getProprtey,
      getProprteyById,
      getCity,
      getLOcation,
      getProductList,
      addToCart,
      craeteOrder,
      updateCart,
      getOrderLIst,
      payLaterOrder,
      userData,
      updateProfile,
      getQoutaLogs,
      getCities,
      changePassword,
      updateSetting,
      deleteProperty,
      getQoutaState,
      formatPriceWithCommas,
      getPropertyDetail,
      getTypePublic,
      upgradeProperty,
      agencyProfile,
      agencyCeo,
      getagency,
      setType,
      type,
      getDashboradListing,
      getDashboradAnalaytics,
      properyEnquery,
      whatsappImpretion,
      newslater,
      currencyData,
      currency,
      areaUnitData,
      area_unit,
      neighbouringProperty,
      recentProperty,
      propertyList,
      setPropertyData,
      dataAgency, setDataAgency,
      data,
      setData,
      featureAgency,
      planAgency,
      cityAgency,
      agencyById,
      agencyList,
      featureAgencyList,
      setFeatureAgencyList,
      projectType,
      projectData,
      projectDetail,
      setProjectTypeData,
      setProjectFilterData,
      projectFilter,
      projectTypeData,
      projectListingData,
      aminitiesLoding,setAminitiesloading,
      projecttype,
      HomePage,
      currencies,
      areaUnit,
      cityData,
      allcity,
      setProjectType,
      setHomePage,
      setcurrencies,
      setareaUnit,
      setcityData,
      setallcity,
      currentCity,
      setCurrentCity,
      currentCityAgency,
      setCurrentCityAgency,
      category,
      setCategory,
      sub_category,
      setsub_category,
      propertyListCity, setPropertyList,
      contact_us,
      projectIquiry,
      agencyInquery,
      bankDetail,
      button_loading,
      instant,
      projectDataList,
      projectFilterData, setProjectFilter,
      currentCityProject, setCurrentCityProject,
      projectListing, setProjectListing
    }}>
      {children}
    </Authdata.Provider>
  )
}
export const useAuth = () => useContext(Authdata);