import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { AiFillCloseCircle } from 'react-icons/ai';
import HomeSection from './ModalSection/HomeSection';
// import PlotSection from './ModalSection/PlotSection';
// import Commercial from './ModalSection/Commercial';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    borderRadius: "5px"
};
function ModalFeature({ open, handleClose, data, setData }) {
    const [amenities, setAmenties] = useState({ amenities: data?.amenities })
    const handleAdd = () => {
        setData({ ...data, amenities: amenities?.amenities })
        handleClose()
    }
    useEffect(() => {
        setAmenties({ amenities: data?.amenities })
        // eslint-disable-next-line
    }, [open])
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="commonPopup">
                <div className='modal_feature p-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className='heading'>Feature and Amenities</h4>
                        <button onClick={handleClose} style={{ background: "none", border: "none", color: "#ECECEC", fontSize: "20px" }}><AiFillCloseCircle /></button>
                    </div>
                    <div className='feature_list'>
                        <HomeSection newData={data} data={amenities} setData={setAmenties} />
                        {/* {label==="plots"&&<PlotSection/>}
                     {label==="Commercial"&&<Commercial/>} */}
                    </div>
                    <div className='model_footer d-flex justify-content-between pt-3'>
                        <button onClick={handleAdd} className='add_btn'>Add Amenities</button>
                        <button onClick={handleClose} className='cancel_btn'>Cancel</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ModalFeature
