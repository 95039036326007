import React from 'react'
import { useNavigate } from 'react-router-dom';
import { FallbackImage } from '../FallbackImage';
import { IoDiamond, IoLocationOutline, IoMailOutline } from 'react-icons/io5';
import img from '../../Images/dummy/placeholder.png'
import img4 from '../../Images/Agents/Frame 1300192747.png'
import img5 from '../../Images/Agents/Frame 1300192750.png'
import { MdPhoneIphone } from 'react-icons/md';
import NoReacodFound from '../NavBarFooter/NoReacodFound';
function ListAgents({ featureAgencyList, currentCity }) {
    const navigate = useNavigate()
    return (
        <div className="agents conatiner-fluid px-4 mt-3">
            <div className="row m-0 px-1 justify-content-center">
                <div className="col-lg-8 col-12 m-0 p-0 mt-3">
                    <h5>
                        {featureAgencyList?.length} Property Agents in {currentCity?.city_name?` ${currentCity?.city_name}`:"Pakistan Property"}
                    </h5>
                </div>
                {featureAgencyList?.length ? featureAgencyList?.map((item) => {
                    return (
                        <div style={{ cursor: "pointer" }} className="col-lg-8 col-12 special-col p-0 mt-2">
                            <div className="card-1 new_card_1 d-flex justify-content-between p-2 rounded">
                                <div className='d-flex'>
                                <div  onClick={() => navigate(`/agent-profile/${item?.id||item?.agency_profile_id}`)} style={{ width: "max-content" }} className="card-img h-100">
                                    <FallbackImage style={{ width: "150px", height: "120px", objectFit: "contain", borderRadius: "5px" }} src={item?.agency_image} alt={"agency ptofile"} fallbackSrc={img} />
                                </div>
                                <div  onClick={() => navigate(`/agent-profile/${item?.id||item?.agency_profile_id}`)}  className="card-text px-1 agent_card_text_box ms-md-0 ms-3">
                                    <div className="first-heading py-0">
                                        {item?.agency_name}
                                    </div>
                                    <div className='list-agent d-sm-none d-flex'>
                                    {item?.package? <div className="primium-card px-0 my-1">
                                        <IoDiamond className='me-1' style={{marginLeft:'1px'}} /> {item?.package?.name}
                                    </div>:""}
                                    </div>
                                    <div className="location d-flex mt-md-2 mt-0">
                                        <div className='location-icon'><IoLocationOutline /></div>
                                        <div className='location-details'>{item?.city?.name} </div>
                                    </div>
                                    <div className="house-box d-flex ">
                                        <div className="box-1 d-flex">
                                            <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                            <div className="box1-text">{item?.sell_count} for Sale</div>
                                        </div>
                                        <div className="box-1 d-flex ">
                                            <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                            <div className="box1-text">{item?.rent_count} for Rent</div>
                                        </div>
                                    </div>
                                    <p className='agentlist pera p-0 m-0'>{item?.description}</p>
                                </div>
                                </div>
                                <div style={{ width: "20%" }} className='list-agent d-flex justify-content-sm-between justify-content-end flex-column align-items-end'>
                                   {item?.package? <div className="primium-card d-sm-flex d-none">
                                        <IoDiamond className='mx-1' /> {item?.package?.name}
                                    </div>:""}
                                    <div className='d-flex'>
                                    <a style={{textDecoration:"none"}} href={`mailto:${item?.email}`}>
                                        <button className='btn-1 mx-2'><IoMailOutline className='me-1' />Email</button>
                                        </a>
                                        <a style={{textDecoration:"none"}} href={`https://wa.me/${item?.phone_number}`} target="_blank" rel="noopener noreferrer">
                                        <button> <MdPhoneIphone className='me-1' />
                                            Call</button>
                                            </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) :
                <NoReacodFound/>
                }
            </div>
        </div>
    )
}

export default ListAgents