import React, { useEffect, useState } from 'react'
import { useAuth } from '../../Context/ContextProvider'
import OrderDetail from './OrderDetail';
import { PiEyeLight } from 'react-icons/pi';

function OrderHistory() {
    const [orderList,setOrderLIst]=useState([])
    const [orderStatus,setOrderStatus]=useState()
    const [orderData,setOrderData]=useState({
        products:[],
        orderID:"",
        order_status:""
    })
    const {getOrderLIst}=useAuth()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const getStatusClass = (status) => {
        switch (status) {
          case 'Pending for Approval':
            return 'status-approval';
          case 'Saved':
            return 'status-saved';
          case 'Approved':
            return 'status-approved';
          case 'Rejected':
            return 'status-rejected';

          default:
            return '';
        }
      };
      const fetchData = async () => {
        try {
            let result = await getOrderLIst();
            if (result?.success) {
                setOrderLIst(result?.data?.data?.orders);
            }
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
        }
    };
    useEffect(() => {

        
        fetchData();
        // eslint-disable-next-line
    }, []);
  return (
    <div className='add_propertey_main agency_setting'>
       
            <div className='content_box'>
                <div className='d-flex justify-content-between'>
            <h3 className='heading m-0 iconHeading my-2'><svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.34212 11.8736H2.42791C1.97838 11.8755 1.54456 11.7083 1.21257 11.4052C0.880586 11.1021 0.674712 10.6853 0.635817 10.2374L0.0016581 3.27952C-0.00339147 3.22434 0.00312277 3.16871 0.0207847 3.11619C0.0384467 3.06367 0.0668681 3.01541 0.104234 2.9745C0.141599 2.93358 0.187088 2.90091 0.237793 2.87856C0.288499 2.85622 0.343308 2.84469 0.398718 2.84473H11.3713C11.4267 2.84475 11.4815 2.85631 11.5322 2.87867C11.5829 2.90104 11.6283 2.93371 11.6657 2.97461C11.703 3.01552 11.7314 3.06376 11.7491 3.11625C11.7668 3.16875 11.7734 3.22435 11.7684 3.27952L11.134 10.2374C11.0952 10.6853 10.8894 11.1022 10.5575 11.4053C10.2255 11.7084 9.79165 11.8756 9.34212 11.8736ZM0.494365 3.33078L1.11997 10.1933C1.14828 10.5202 1.29852 10.8244 1.54082 11.0457C1.78313 11.2669 2.09979 11.3889 2.42791 11.3875H9.34212C9.67023 11.389 9.98688 11.267 10.2292 11.0457C10.4714 10.8245 10.6216 10.5201 10.6498 10.1933L11.2757 3.33078H0.494365Z" fill="#1C9093" />
                    <path d="M9.02508 4.85547C8.99316 4.85549 8.96155 4.84922 8.93205 4.83701C8.90256 4.8248 8.87576 4.8069 8.85319 4.78433C8.83062 4.76176 8.81272 4.73496 8.80051 4.70547C8.7883 4.67598 8.78203 4.64437 8.78205 4.61245V3.18679C8.78205 2.47051 8.49751 1.78357 7.99102 1.27708C7.48454 0.770598 6.7976 0.486057 6.08132 0.486057C5.36504 0.486057 4.6781 0.770598 4.17161 1.27708C3.66513 1.78357 3.38059 2.47051 3.38059 3.18679V4.61245C3.38059 4.6769 3.35498 4.73872 3.30941 4.78429C3.26383 4.82987 3.20202 4.85547 3.13756 4.85547C3.0731 4.85547 3.01129 4.82987 2.96571 4.78429C2.92014 4.73872 2.89453 4.6769 2.89453 4.61245V3.18679C2.89453 2.76829 2.97696 2.35389 3.13711 1.96726C3.29726 1.58062 3.532 1.22931 3.82792 0.933388C4.12384 0.637468 4.47515 0.402731 4.86179 0.24258C5.24843 0.0824287 5.66282 0 6.08132 0C6.49981 0 6.91421 0.0824287 7.30085 0.24258C7.68749 0.402731 8.0388 0.637468 8.33472 0.933388C8.63064 1.22931 8.86537 1.58062 9.02552 1.96726C9.18568 2.35389 9.2681 2.76829 9.2681 3.18679V4.61245C9.26812 4.64437 9.26185 4.67598 9.24964 4.70547C9.23743 4.73496 9.21953 4.76176 9.19696 4.78433C9.17439 4.8069 9.14759 4.8248 9.1181 4.83701C9.08861 4.84922 9.057 4.85549 9.02508 4.85547Z" fill="#1C9093" />
                    <path d="M8.22064 7.03928H3.94225C3.87779 7.03928 3.81598 7.01368 3.7704 6.9681C3.72482 6.92252 3.69922 6.86071 3.69922 6.79625C3.69922 6.7318 3.72482 6.66998 3.7704 6.6244C3.81598 6.57883 3.87779 6.55322 3.94225 6.55322H8.22064C8.2851 6.55322 8.34691 6.57883 8.39249 6.6244C8.43806 6.66998 8.46367 6.7318 8.46367 6.79625C8.46367 6.86071 8.43806 6.92252 8.39249 6.9681C8.34691 7.01368 8.2851 7.03928 8.22064 7.03928Z" fill="#1C9093" />
                    <path d="M7.33074 8.76975H4.83092C4.76646 8.76975 4.70465 8.74414 4.65907 8.69857C4.6135 8.65299 4.58789 8.59118 4.58789 8.52672C4.58789 8.46226 4.6135 8.40045 4.65907 8.35487C4.70465 8.3093 4.76646 8.28369 4.83092 8.28369H7.33074C7.3952 8.28369 7.45701 8.3093 7.50259 8.35487C7.54817 8.40045 7.57377 8.46226 7.57377 8.52672C7.57377 8.59118 7.54817 8.65299 7.50259 8.69857C7.45701 8.74414 7.3952 8.76975 7.33074 8.76975Z" fill="#1C9093" />
                </svg> Order History</h3>
                </div>
                <div className='locationPurpos my-3'>
                <div className='listing_table p-0'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Order ID</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Date & Time</th>
                                    <th scope="col">Order Status      </th>
                                    <th scope="col">Price (PKR)</th>
                                    <th scope="col">Action </th>
                                 
                                </tr>
                            </thead>
                            <tbody>{orderList?.length===0?<tr><td colSpan='6' className='text-center'>No Record Found</td></tr>:orderList?.reverse()?.map((item)=>{
                                        const date = new Date(item?.created_at);
                                        const formattedTime = date.toLocaleTimeString('en-US', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: true
                                        });
                                        const formattedDate = date.toLocaleDateString('en-US', {
                                          year: 'numeric',
                                          month: 'short',
                                          day: 'numeric'
                                        });
                                    return(
                                        <tr>
                                        <td>{item?.order_code}</td>
                                        <td > 
                                            <div className='d-flex align-items-center'>
                                            {item?.products?.length}<div onClick={()=>{
                                                    handleOpen1()
                                                    setOrderStatus(item?.order_status?.name)
                                                    setOrderData({
                                                        products:item?.products,
                                                        orderID:item?.id,
                                                        order_status:item?.order_status?.name
                                                    })
                                                }} className='d-flex align-items-center justify-content-center ms-2' title='View' style={{cursor:"pointer", border:"1px solid #E3FEFF",height:"25px",width:"25px",fontSize:"15px",color:"#1C9093",background:"#F5F6FA",borderRadius:"50%"}}><PiEyeLight/></div> 

                                            </div>
                                            </td>
                                        <td> {`${formattedTime}, ${formattedDate}`}</td>
                                        <td><span className={`${getStatusClass(item?.order_status?.name)} status`}>{item?.order_status?.name}</span></td>
                                        <td>Rs {item?.total_price}</td>
                                        <td>
                                            <div className='table_action_button'>

                                            {item?.order_status?.name!=="Saved"?<div style={{background:"#1C9093",width:"max-content",borderRadius:"5px"}} className='action_btn d-flex'><button onClick={()=>{
                                                    handleOpen1()
                                                    setOrderStatus(item?.order_status?.name)
                                                    setOrderData({
                                                        products:item?.products,
                                                        orderID:item?.id,
                                                        order_status:item?.order_status?.name
                                                    })
                                                }} className='py-0'>View</button></div>:
                                            <div style={{background:"#1C9093",width:"max-content",borderRadius:"5px"}} className='action_btn d-flex'>
                                                <button onClick={()=>{
                                                    handleOpen()
                                                    setOrderData({
                                                        products:item?.products,
                                                        orderID:item?.id
                                                    })
                                                }} style={{borderRadius:"5px 0 0 5px",borderRight:"1px solid lightgray",}} className='btn1 py-0'>Pay Now</button>
                                                <button onClick={()=>{
                                                    handleOpen1()
                                                    setOrderStatus(item?.order_status?.name)
                                                    setOrderData({
                                                        products:item?.products,
                                                        orderID:item?.id,
                                                        order_status:item?.order_status?.name
                                                    })
                                                }} style={{borderRadius:"0 5px 5px 0"}} className='btn2 py-0'>View</button>
                                            </div>}
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                })}
                                
                              
                            </tbody>
                        </table>
                    </div>

                </div>
                                <OrderDetail orderStatus={orderStatus} fetchData={fetchData} order_status={orderData?.order_status} handleOpen={handleOpen} orderId={orderData?.orderID} cartData={orderData?.products} open={open} setOpen={setOpen} open1={open1} setOpen1={setOpen1}/>
            </div>

        </div>
  )
}

export default OrderHistory