import React from 'react'
import Slider from 'react-slick'; // React-Slick Carousel
import { IoMdCheckmark } from 'react-icons/io';
import { SlLocationPin } from 'react-icons/sl';
import img from '../../Images/Mask Group.png';
import img1 from '../../Images/logo.png';
import img2 from '../../Images/Group.png';
import img3 from '../../Images/Vector (1).png';
import img4 from '../../Images/Group 1000014144.png';
import img5 from '../../Images/Vector (2).png';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import NoReacodFound from '../NavBarFooter/NoReacodFound';
import { FallbackImage } from '../FallbackImage';
  const CustomPrevArrow = ({ onClick }) => (
    <button className="custom-prev-arrow" onClick={onClick}>
      <FiArrowLeft />
    </button>
  );
  
  const CustomNextArrow = ({ onClick }) => (
    <button className="custom-next-arrow" onClick={onClick}>
      <FiArrowRight />
    </button>
  );
  




function TopProject({projectList}) {


  function formatPrice(value) {
    if (value >= 10000000) {
        // Convert to Crore
        return (
            <>
                {(value / 10000000).toFixed(2)} Cr
            </>
        );
    } else if (value >= 100000) {
        // Convert to Lakh
        return (
            <>
                {(value / 100000).toFixed(2)} Lakh
            </>
        );
    } else if (value >= 1000) {
        // Convert to Thousand
        return (
            <>
                {(value / 1000).toFixed(2)} Thousand
            </>
        );
    } else {
        // Less than 1000, show as is
        return value?.toString();
    }
}
  


  const settings = {
    dots: false, // Disable scroll numbers
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const navigate=useNavigate()
  const renderProjectCard = (project) => (
    <div onClick={()=>navigate(`/project-detail/${project?.id}`)} className='col-lg-4 col-sm-6 col-12  p-2 '>
      <div style={{cursor:"pointer"}} className='project-card projctmainCard'>
      <FallbackImage style={{objectFit:"cover"}} src={project?.images?.length?project?.images[0]?.url:img} alt={'...'} className={'main_img'} fallbackSrc={img}/>
        <div className='over_lay'></div>
        <div className='project-logo'>
          <div className='p-fold'>
            <div className='markit-project'>
              <div className='p-markit'>Marketed By</div>
              <div className='project-icon'>
                <img src={img1} alt='...' />
              </div>
            </div>
            {/* <div className='fold-card'>
        <span><AiOutlineFire/></span>
        <h3>Trending</h3>
        </div> */}
          </div>
          <div className='project-img'>
            <div className='pro-icon'>
              <img src={img5} alt='...' />
            </div>
            <div className='pro-icon'>
              <img src={img2} alt='...' />
            </div>
            <div className='pro-icon'>
              <img src={img3} alt='...' />
            </div>
            <div style={{ border: 'none' }} className='pro-icon'>
              <img src={img4} alt='...' />
            </div>
          </div>
        </div>
       {project?.is_treanding&& <div style={{ top: "80px" }} className='position-relative'>
          <div className="position-absolute  pt-1 px-3"><img src='./image/Frame.svg' alt='...' /></div>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector 2" d="M8 8L0 0H8V8Z" fill="#046B6D" />
          </svg>
        </div>}
        <div className='project-heading'>
          <div className='tik'>
            <span style={{ marginLeft: '6px', marginTop: '-4px', color: ' #1C9093', fontSize: '16px' }}><IoMdCheckmark /></span>
            <h4>Verified</h4>
          </div>
          <h2>{project?.project_title}</h2>
          <span className='d-flex align-items-center my-2'><SlLocationPin className='me-2' />{project?.location?.name},{project?.city?.city}</span>
          {/* <span className='d-flex align-items-center my-2'><SlLocationPin className='me-2' />5 Marla, 10 Marla, 1 Kanal</span> */}
          <div>
            <p>PKR  {formatPrice(project?.min_price)} to {formatPrice(project?.max_price)}</p>
          </div>
        </div>
      </div>
    </div>
  );


  return (
    <>
    <div className="similar-projects">
        <div className="top-project px-4 my-4 pt-2">
          <h2>Top Projects</h2>
        <div className="row m-0">
        {projectList?.length?projectList?.length<3?<>{projectList?.map(renderProjectCard)}</> : <Slider {...settings}>
            {projectList?.map(renderProjectCard)}
          </Slider>:<NoReacodFound/>}
        </div>
       
        </div>
      </div>
    </>
  )
}

export default TopProject
