import React from 'react'
import img from '../../Images/property1/PXL_20240118_175451250.NIGHT-min.jpg'
import img1 from '../../Images/property1/2Y8A2371-min (1).jpg'
import img2 from '../../Images/property1/2Y8A2493-min.jpg'
import img3 from '../../Images/property1/2Y8A2754-min.jpg'
import img4 from '../../Images/property1/2Y8A2781-min.jpg'
import img5 from '../../Images/property1/2Y8A2456-min.jpg'
function WhatWeDo() {
    return (
        <>
            <div className="container-fluid  we-container main_banner_home">
                <div className="row  mb-sm-3 mb-2">
                    <div className="main-heading aboutWhat mt-sm-5 mt-3">WHAT WE DO</div>
                </div>
                <div className="row ">
                    <div className="col-lg-6 col-12 px-3">
                        <div className="main-images-box">
                            <div className="row mt-2 about-img-row">
                                <div className="col-md-5 col-sm-6 col-12 mx-1">
                                    <div className="about-img">
                                        <img src={img} alt="" style={{ height:'238px'}} />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-5 col-12 my-sm-0 my-4">
                                    <div className="about-img" >
                                        <img src={img1} alt="" style={{ height:'238px'}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-sm-4 my-0 py-sm-3 py-0 about-img-row">
                                <div className="col-md-5 col-sm-6 col-12 img-col ">
                                    <div className="about-img" >
                                        <img src={img2} alt="" style={{ height:'238px'}} />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 p-sm-0 col-12 my-sm-0 my-4">
                                    <div className="about-img">
                                        <img src={img3} alt="" style={{height:'238px'}} />
                                    </div>
                                </div>
                            </div>
                            <div className="row about-img-row">
                                <div className="col-sm-4 col-12">
                                    <div className="about-img">
                                        <img src={img4} alt=""  style={{height:'238px'}} />
                                    </div>
                                </div>
                                <div className="col-sm-7 col-12 mx-1 mt-sm-0 mt-4 " >
                                    <div className="about-img">
                                        <img src={img5} alt="" style={{ height:'238px'}}  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-lg-0 mt-sm-5 mt-3 pt-lg-0 pt-sm-4 textbox-col">
                        <div className="textbox-1 row p-0">
                            <div className="textbox1-heading same-padding aboutTextbox">
                                PakistanProperty.com: Your Partner in Finding the Perfect Home
                            </div>
                            <div className="textbox1-paragraph1 my-2 same-padding aboutTextboxpara">
                                At PakistanProperty.com, we believe that finding a home is more than just a transaction; it's about finding a place that truly resonates with you. We understand that the search for the perfect property can be both exciting and daunting.
                            </div>

                            <div className="textbox1-paragraph2 my-3 same-padding aboutTextboxpara">
                                That's why we've created a platform that empowers both real estate agents and clients, providing a seamless and secure environment for all your property needs.
                            </div>

                        </div>
                        <div className="textbox-2 row p-0">
                            <div className="texbox2-heading1 my-1 same-padding mx-0 aboutTextbox">
                                Empowering Agents and Clients
                            </div>
                            <div className="textbox2-heading2 my-2 same-padding aboutTextbox mx-0">
                                For Real Estate Agents:
                            </div>
                            <div className="textbox2-listdata mx-0 same-space">
                                <ul className='ul-tag'>
                                    <li className='my-1 aboutTextboxpara'>Showcase your listings with ease using our intuitive property management tools.</li>
                                    <li className='my-1 aboutTextboxpara'>Expand your reach and connect with a wider audience of potential buyers and renters.</li>
                                    <li className='my-1 aboutTextboxpara'>Streamline your workflow and boost efficiency with our comprehensive agent tools.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="textbox-3 row p-0 my-2 ">
                            <div className="textbox3-heading same-padding mb-2 aboutTextbox">
                                For Clients:
                            </div>
                            <div className="textbox3-listdata mx-0 same-space">
                                <ul className='ul-tag'>
                                    <li className='my-1 aboutTextboxpara'>Effortlessly search and discover properties that match your criteria.</li>
                                    <li className='my-1 aboutTextboxpara'>Access detailed property information and make informed decisions.</li>
                                    <li className='my-1 aboutTextboxpara'>Connect with agents seamlessly and conduct secure transactions.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="textbox-4 row p-0">
                            <div className="texbox4-heading my-1 same-padding mx-0 aboutTextbox">
                                Our Commitment
                            </div>
                            <div className="textbox4-paragraph my-2 same-padding aboutTextboxpara mx-0">
                                We are dedicated to revolutionizing the real estate landscape in Pakistan. By focusing on empowerment, efficiency, and innovation, we aim to:
                            </div>
                            <div className="textbox4-listdata mx-0 same-space">
                                <ul className='ul-tag'>
                                    <li className='my-1 aboutTextboxpara'>Bridge the gap between real estate agents and clients.</li>
                                    <li className='my-1 aboutTextboxpara'>Foster transparency and trust throughout the property search process.</li>
                                    <li className='my-1 aboutTextboxpara'>Provide a secure and reliable platform for all transactions.</li>
                                    <li className='my-1 aboutTextboxpara'>Continuously innovate and adapt to the evolving needs of the market.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="textbox-5 row p-0">
                            <div className="texbox5-heading my-1 same-padding mx-0 aboutTextbox">
                                Join the Future of Real Estate One Home at a time
                            </div>
                            <div className="textbox5-paragraph mt-1 mb-4 pb-2 same-padding mx-0 aboutTextboxpara">
                                PakistanProperty.com invites you to embark on a journey to redefine the standards of property management and transactions in Pakistan. Together, let's create a future where finding your perfect home is a seamless and enjoyable experience.</div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default WhatWeDo
