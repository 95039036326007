import React, { useEffect, useRef, useState } from "react";
import img from "../../Images/Agents/Frame 1300193233.jpg";
import { CiSearch } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa";
import { HiOutlineHome } from "react-icons/hi";
import { useAuth } from "../../Context/ContextProvider";
import { Divider } from "@mui/material";
function Banner({ allcity, type, searchData }) {
  const {
    getLOcation,
    currentCityAgency,
    dataAgency,
    setDataAgency,
    setCurrentCityAgency,
  } = useAuth();
  const [show, setShow] = useState(false);

  const [city, setcity] = useState(allcity);
  const [show1, setShow1] = useState(false);
  const [location, setLocation] = useState([]);
  const [city_value, setCityValue] = useState({
    city: currentCityAgency?.city_name || "Lahore",
    location: "",
  });

  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShow1(false);
      setShow(false);
      if (!dataAgency?.city_code && !dataAgency?.location_id) {
        setCityValue({
          city: currentCityAgency?.city_name || "Lahore",
          location: "",
        });
      } else if (!dataAgency?.city_code) {
        setCityValue({
          ...city_value,
          city: currentCityAgency?.city_name || "Lahore",
        });
      } else {
        setCityValue({
          ...city_value,
          location: "",
        });
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="container-fluid main_banner_home">
      <div
        style={{ padding: "1rem", minHeight: "320px" }}
        className="banner_section"
      >
        <img src={img} id="bgImage" alt="banner-image" />
        <div class="content new-content new_content_box">
          <div className="p-0 m-0">
            <h1 className="mt-3 mb-2">
              Find Top Real Estate Agencies in Pakistan
            </h1>
            <p className="mt-sm-4 mt-2 pt-1 mb-3">
              Search the real estate agents in Pakistan dealing in properties
              for sale and rent.
            </p>
          </div>
          <div className="tab_banner new-tab-banner new_tab_banner p-0 m-0">
            <div className="d-flex justify-content-between tab_section new-tab-section mb-4 ">
              <div className="d-flex input_new_parent">
                <div
                  style={{ position: "relative" }}
                  className="select_banner agent_select_banner"
                >
                  <HiOutlineHome className="text-dark  home_icon" />
                  <input
                    style={{ width: "150px" }}
                    onClick={() => setShow(true)}
                    value={city_value?.city}
                    onChange={async (e) => {
                      setCityValue({ ...city_value, city: e.target.value });
                      if (e.target.value) {
                        setShow(true);
                        let array = allcity?.filter((item) =>
                          item?.city
                            ?.toLowerCase()
                            .includes(e.target.value?.toLowerCase())
                        );

                        setcity(array);
                      } else {
                        setShow(false);
                        setcity(allcity);
                      }
                    }}
                    type="text"
                    placeholder="Search City"
                  />{" "}
                  <FaChevronDown style={{ color: "lightgray" }} />
                  {show && (
                    <ul
                      ref={wrapperRef}
                      style={{
                        position: "absolute",
                        top: "110%",
                        color: "black",
                        textAlign: "start",
                      }}
                    >
                      {city?.map((item) => {
                        return (
                          <li
                            onClick={() => {
                              setCityValue({ ...city_value, city: item?.city });
                              setDataAgency({
                                ...dataAgency,
                                city_code: item?.app_code,
                              });
                              setCurrentCityAgency({
                                city_name: item?.city,
                                app_code: item?.app_code,
                              });
                              setShow(false);
                            }}
                          >
                            {item?.city}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
                <Divider
                  className="first-line"
                  style={{
                    borderColor: "black",
                    height: "20px",
                    border: "1px solid black",
                    marginTop: "10px",
                  }}
                  orientation="vertical"
                  flexItem
                />
                <div className="select_banner">
                  <select
                    onChange={(e) =>
                      setDataAgency({
                        ...dataAgency,
                        propery_type: e.target.value,
                      })
                    }
                    className="property-tab agentSelect"
                  >
                    {type?.map((item) => {
                      return <option value={item?.slug}>{item?.name}</option>;
                    })}
                  </select>
                </div>
                <Divider
                  className="second-line"
                  style={{
                    borderColor: "black",
                    height: "20px",
                    border: "1px solid black",
                    marginTop: "10px",
                  }}
                  orientation="vertical"
                  flexItem
                />
                <div className="select_banner">
                  <div
                    style={{ position: "relative" }}
                    className="select_banner ps-lg-2"
                  >
                    <CiSearch className="text-dark" />
                    <input
                      value={city_value?.location}
                      onClick={() => setShow1(true)}
                      onChange={async (e) => {
                        setCityValue({
                          ...city_value,
                          location: e.target.value,
                        });
                        if (e.target.value) {
                          setShow1(true);
                        } else {
                          setShow1(false);
                        }
                        let result = await getLOcation({
                          city_code: dataAgency?.city_code,
                          keyword: e.target.value,
                        });
                        if (result?.success) {
                          setLocation(result?.data);
                        }
                      }}
                      type="search"
                      placeholder="Search by location"
                    />{" "}
                    <FaChevronDown style={{ color: "lightgray" }} />
                    {show1 && (
                      <ul
                        ref={wrapperRef}
                        style={{
                          position: "absolute",
                          top: "110%",
                          color: "black",
                          textAlign: "start",
                        }}
                      >
                        {location?.map((item) => {
                          return (
                            <li
                              onClick={() => {
                                setCityValue({
                                  ...city_value,
                                  location: item?.name,
                                });
                                setDataAgency({
                                  ...dataAgency,
                                  location: item?.id,
                                });
                                setShow1(false);
                              }}
                            >
                              {item?.name}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
                <Divider
                  className="third-line"
                  style={{
                    borderColor: "black",
                    height: "20px",
                    border: "1px solid black",
                    marginTop: "10px",
                  }}
                  orientation="vertical"
                  flexItem
                />
                <div className="select_banner ms-lg-2">
                  <CiSearch className="text-dark" />
                  <input
                    value={dataAgency?.name}
                    onChange={(e) =>
                      setDataAgency({ ...dataAgency, name: e.target.value })
                    }
                    type="search"
                    placeholder="Search by Company"
                  />
                </div>
              </div>
              <div className="d-flex select_btn_parent justify-content-end">
                <div className="select_banner_btn">
                  {/* <button style={{ borderRight: "1px solid lightgray" }} className='px-2' onClick={() => setMoreOption(!moreOption)}>More Option</button> */}
                  <button onClick={() => searchData(dataAgency)}>Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
