import React from 'react'
import img from '../../Images/property1/blog-cover.png'
function PropertyVlaue() {
  return (
    <>
      <div className='property-value px-4'>
        <p>Factors Beyond Our Online Valuations</p>
        <div className='p-v'>
          <div className='row m-0 my-4 p-0'>
            <div className='col-lg-7'>
              <div className='property-card'>
                <p>The factors listed below are not included in our online valuations but can
                  significantly influence a property's value:</p>
                <div className='value-text'>
                  <h3>Unique features</h3>
                  <p>Special elements like a wine cellar or a stunning view add distinctive value to
                    a property. Unfortunately, our online tool cannot account for these attributes
                    that make your home truly special.</p>
                </div>
                <div className='value-text'>
                  <h3>Recent Renovations</h3>
                  <p>Upgrades such as a modern kitchen or a spa-inspired bathroom undoubtedly
                    increase a home's value. However, our tool doesn't assess these changes, as
                    we prefer leaving such evaluations to industry experts.</p>
                </div>
                <div className='value-text'>
                  <h3>Property Condition</h3>
                  <p>The condition of your home, whether pristine or aging, greatly influences its
                    value. While our online valuation offers a helpful starting point, a
                    professional assessment ensures a more accurate evaluation.</p>
                  <p>Note that while our online valuation provides a helpful estimate, a
                    comprehensive and accurate assessment is best achieved through the
                    expertise of a qualified real estate professional.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-5 p-0'>
              <div className='value-img'>
                <img src={img} alt='...' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PropertyVlaue
