import React, { useEffect, useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Properties from '../Camponent/Agent Profile/Properties'
import Footer from '../Camponent/NavBarFooter/Footer'
import Profile from '../Camponent/Agent Profile/Profile'
import { useAuth } from '../Context/ContextProvider'
import { useParams } from 'react-router-dom'

function AgentsProfile() {
  const { agencyById } = useAuth()
  const [featureAgencyList, setFeatureAgencyList] = useState()
  
  const parms=useParams()
  useEffect(() => {
      const fetchCart = async () => {
          try {
              let result = await agencyById(parms?.id);
              if (result?.success) {
                  let propertyData = result?.data;
                  setFeatureAgencyList(propertyData)

              }
          } catch (error) {
              console.error("An error occurred while fetching data:", error);
          }
      };
 
      fetchCart()
     
  }, [])

  return (
   <>
   <NavBar/>
   <Profile agencyData={featureAgencyList}/>
   <Properties agencyDataProfile={featureAgencyList} agencyData={featureAgencyList?.property_types}/>
   <Footer/>
   </>
  )
}

export default AgentsProfile
