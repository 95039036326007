import React from "react";
import { IoCheckmarkOutline } from "react-icons/io5";
import icon from "../../Images/youtube.png";
import { RiDeleteBinLine } from "react-icons/ri";
function PropertyImageVideo({ data, setData, setImages, image,fieldErrors }) {
  const handleremove = (i) => {
    let newData = data?.video_links?.filter((_, idx) => idx !== i);
    setData({ ...data, video_links: newData });
  };
  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"]; // Supported image formats
    const maxImageSize = 10 * 1024 * 1024; // 10 MB
    const minWidth = 450;
    const minHeight = 250;
    let validFiles = [];
    let base64Strings = [];
    let validationPromises = [];
    files.forEach((file) => {
      if (validImageTypes.includes(file.type) && file.size <= maxImageSize) {
        // Validate image dimensions
        const dimensionValidation = new Promise((resolve, reject) => {
          const img = new Image();
          const reader = new FileReader();
          reader.onload = (e) => {
            img.src = e.target.result;
          };
          img.onload = () => {
            if (img.width >= minWidth && img.height >= minHeight) {
              resolve(file);
            } else {
              alert(
                `${file.name} has invalid dimensions. Minimum dimensions are ${minWidth}x${minHeight}.`
              );
              reject();
            }
          };
          img.onerror = reject;
          reader.readAsDataURL(file);
        });

        validationPromises.push(dimensionValidation);
      } else {
        // Notify the user about the invalid file
        alert(
          `${file.name} is invalid. Please upload images under 5 MB (jpeg/png)`
        );
      }
    });

    // Wait for dimension validation to complete
    Promise.allSettled(validationPromises)
      .then((results) => {
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            validFiles.push(result.value);
          }
        });

        if (validFiles.length > 0) {
          setData({ ...data, images: [...data.images || [], ...validFiles] });

          const fileReaders = validFiles.map((file) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (e) => {
                base64Strings.push(e.target.result);
                resolve();
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            });
          });
          Promise.all(fileReaders)
            .then(() => {
              setImages([...image, ...base64Strings]);
            })
            .catch((error) => {
              console.error("Error reading files:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Dimension validation error:", error);
      });
      event.target.value = null;
  };
  return (
    <div className="  locationPurpos propertyimage my-sm-3 my-3">
      <div className="d-flex align-items-center p-0">
        <svg
          className="location_svg_2"
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="24"
          viewBox="0 0 28 24"
          fill="none"
        >
          <path
            d="M22.5 18C22.2033 18 21.9133 18.088 21.6666 18.2528C21.42 18.4176 21.2277 18.6519 21.1142 18.926C21.0006 19.2001 20.9709 19.5017 21.0288 19.7926C21.0867 20.0836 21.2296 20.3509 21.4393 20.5607C21.6491 20.7704 21.9164 20.9133 22.2074 20.9712C22.4983 21.0291 22.7999 20.9994 23.074 20.8858C23.3481 20.7723 23.5824 20.58 23.7472 20.3334C23.912 20.0867 24 19.7967 24 19.5C24 19.1022 23.842 18.7206 23.5607 18.4393C23.2794 18.158 22.8978 18 22.5 18ZM28 17.5V22.5C28 22.8978 27.842 23.2794 27.5607 23.5607C27.2794 23.842 26.8978 24 26.5 24H18.5C18.1022 24 17.7206 23.842 17.4393 23.5607C17.158 23.2794 17 22.8978 17 22.5V17.5C17 17.1022 17.158 16.7206 17.4393 16.4393C17.7206 16.158 18.1022 16 18.5 16H19.19L20.05 14.28C20.0921 14.1966 20.1564 14.1263 20.2358 14.077C20.3151 14.0276 20.4065 14.001 20.5 14H24.5C24.5935 14.001 24.6849 14.0276 24.7642 14.077C24.8436 14.1263 24.9079 14.1966 24.95 14.28L25.81 16H26.5C26.8978 16 27.2794 16.158 27.5607 16.4393C27.842 16.7206 28 17.1022 28 17.5ZM25 19.5C25 19.0055 24.8534 18.5222 24.5787 18.1111C24.304 17.7 23.9135 17.3795 23.4567 17.1903C22.9999 17.0011 22.4972 16.9516 22.0123 17.048C21.5273 17.1445 21.0819 17.3826 20.7322 17.7322C20.3826 18.0819 20.1445 18.5273 20.048 19.0123C19.9516 19.4972 20.0011 19.9999 20.1903 20.4567C20.3795 20.9135 20.7 21.304 21.1111 21.5787C21.5222 21.8534 22.0055 22 22.5 22C23.163 22 23.7989 21.7366 24.2678 21.2678C24.7366 20.7989 25 20.163 25 19.5Z"
            fill="#1C9093"
          />
          <path
            d="M15.5 18.9956H13V13.4956C13 13.363 12.9473 13.2358 12.8536 13.142C12.7598 13.0483 12.6326 12.9956 12.5 12.9956H7.5C7.36739 12.9956 7.24022 13.0483 7.14645 13.142C7.05268 13.2358 7 13.363 7 13.4956V18.9956H3V9.51559L10 5.08559L17 9.51559V14.5156C17 14.6482 17.0527 14.7754 17.1464 14.8691C17.2402 14.9629 17.3674 15.0156 17.5 15.0156C17.6326 15.0156 17.7598 14.9629 17.8536 14.8691C17.9473 14.7754 18 14.6482 18 14.5156V10.1456L19.23 10.9256C19.3079 10.9694 19.3957 10.9924 19.485 10.9924C19.5743 10.9924 19.6622 10.9694 19.74 10.9256C19.8174 10.8832 19.8823 10.8211 19.9279 10.7456C19.9736 10.6701 19.9985 10.5838 20 10.4956V6.49559C19.9999 6.41193 19.9787 6.32964 19.9386 6.25627C19.8984 6.18289 19.8404 6.12077 19.77 6.07559L10.27 0.0755901C10.1886 0.0261472 10.0952 0 10 0C9.90478 0 9.81138 0.0261472 9.73 0.0755901L0.230002 6.07559C0.159588 6.12077 0.101636 6.18289 0.0614531 6.25627C0.0212701 6.32964 0.000141232 6.41193 1.8631e-06 6.49559V10.4956C-0.000243643 10.5855 0.0237784 10.6739 0.0695366 10.7513C0.115295 10.8288 0.181095 10.8924 0.260002 10.9356C0.337852 10.9794 0.425674 11.0024 0.515002 11.0024C0.60433 11.0024 0.692152 10.9794 0.770002 10.9356L2 10.1456V19.4956C2 19.6282 2.05268 19.7554 2.14645 19.8491C2.24022 19.9429 2.36739 19.9956 2.5 19.9956H15.5C15.6326 19.9956 15.7598 19.9429 15.8536 19.8491C15.9473 19.7554 16 19.6282 16 19.4956C16 19.363 15.9473 19.2358 15.8536 19.142C15.7598 19.0483 15.6326 18.9956 15.5 18.9956ZM1 9.59559V6.77559L10 1.08559L19 6.77559V9.59559L10.27 4.07559C10.1904 4.02142 10.0963 3.99245 10 3.99245C9.9037 3.99245 9.80962 4.02142 9.73 4.07559L1 9.59559ZM12 18.9956H8V13.9956H12V18.9956Z"
            fill="#B7B7B7"
          />
        </svg>
        <h4 className="new_heading ">Property Images and Videos</h4>
      </div>
      <div className="row">
        <div className="col-md-10 col-12 ">

      <h5 className="new_sub_heading">Upload Images of your Property</h5>
      <div className=" image_section">
        <div className="row">
          <div className="col-12">
            <div style={{position:"relative"}} className="d-flex justify-content-between align-items-center row m-0">
              <div className="order-sm-first order-last p-0 d-flex align-items-center justify-content-between ">
                <ul className="p-0">
                  <li className="d-flex">
                    <div>

                    <IoCheckmarkOutline
                      style={{ marginRight: ".7rem", color: "#1C9093" }}
                    />
                                        </div>
                    <div>Ads with images receive 5x more views.</div>
                  </li>
                  <li className="d-flex">
                    <div>

                    <IoCheckmarkOutline
                      style={{ marginRight: ".7rem", color: "#1C9093" }}
                    />
                                        </div>
                    <div>
                      Upload high-quality pictures with proper lighting and
                      dimensions (minimum width: 450px, minimum height: 250px).
                    </div>
                  </li>
                  <li className="d-flex">
                    <div>

                    <IoCheckmarkOutline
                      style={{ marginRight: ".7rem", color: "#1C9093" }}
                    />
                                        </div>
                    <div>Each image should not exceed 10 MB.</div>
                  </li>
                </ul>
                <div>
                  <label className="btn1 w-100" for="upload_image">
                    Upload Image
                  </label>
                  {/* <button className='btn2 w-100 mt-2'>Image Bank</button> */}
                  <input
                    onChange={handleFileInputChange}
                    multiple
                    hidden
                    type="file"
                    id="upload_image"
                  />
                </div>
              </div>
        {fieldErrors?.images&&<span style={{top:"90%"}} className='text_error'>{fieldErrors?.images}</span>}
            </div>
          </div>
          <div className="col-12">
            <div
              style={{
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              className="p-2"
            >
              {image?.map((item, i) => {
                return (
                  <div
                    className="image_main my-2"
                    key={i}
                    draggable
                    onDragStart={(e) => e.dataTransfer.setData("dragIndex", i)}
                    onDragOver={(e) => e.preventDefault()} // Necessary to allow dropping
                    onDrop={(e) => {
                      e.preventDefault();
                      const dragIndex = parseInt(
                        e.dataTransfer.getData("dragIndex"),
                        10
                      );

                      if (dragIndex === i) return;

                      // Rearrange images array
                      setImages((prevImages) => {
                        const updatedImages = [...prevImages];
                        const [draggedImage] = updatedImages.splice(
                          dragIndex,
                          1
                        );
                        updatedImages.splice(i, 0, draggedImage);
                        return updatedImages;
                      });
                    }}
                    style={{
                      position: "relative",
                      width: "100px",
                      height: "100px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item?.url ? item.url : item}
                      alt="..."
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />

                    <div
                      onClick={() => {
                        if (item?.media_id) {
                          setData((prevData) => ({
                            ...prevData,
                            deleted_media: [
                              ...(prevData?.deleted_media || []),
                              item.media_id,
                            ],
                            images: data?.images.filter((_, idx) => idx !== i)
                          }));
                        }
                        else{
                          setData((prevData) => ({
                            ...prevData,
                            images: data?.images.filter((_, idx) => idx !== i)
                          }));
                        }

                        // Remove the item from the image array
                        setImages((prevImages) =>
                          prevImages.filter((_, idx) => idx !== i)
                        );
                      }}
                      style={{
                        position: "absolute",
                        background: "white",
                        right: "10px",
                        top: "5px",
                        height: "20px",
                        width: "20px",
                        padding: "0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      <RiDeleteBinLine style={{ color: "#1C9093" }} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <h3 className="new_heading">Add Videos of your Property</h3>
      <p className="new_sub_heading">
        Add videos of your property from Youtube. Upload on Youtube and paste
        the link below.
      </p>
      <div className="row">
        <div className=" col-12">
          <div className="video_link">
            {data?.video_links?.map((item, i) => {
              return (
                <div className="d-flex justify-content-between flex-wrap my-2">
                  <label>Video Link</label>
                  {i > 1 && (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleremove(i)}
                    >
                      <RiDeleteBinLine className="mx-2" />
                      Delete Video
                    </span>
                  )}
                  <div
                    style={{
                      width: "100%",
                      border: "1px solid lightgray",
                      padding: ".5rem",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className="w-100"
                  >
                    <img
                      style={{
                        width: "20px",
                        marginLeft: ".5rem",
                        marginRight: ".5rem",
                      }}
                      src={icon}
                      alt="..."
                    />
                    <input
                      value={item}
                      onChange={(e) => {
                        let newdata = [...data?.video_links];
                        newdata[i] = e.target.value;
                        setData({ ...data, video_links: newdata });
                      }}
                      style={{
                        width: "95%",
                        background: "none",
                        border: "none",
                        outline: "none",
                      }}
                      type="url"
                      placeholder="Place Video Link Here"
                    />
                  </div>
                </div>
              );
            })}
            <button
              onClick={() =>
                setData({ ...data, video_links: [...data?.video_links, ""] })
              }
              className="add_btn"
            >
              Add Another Video
            </button>
          </div>
        </div>
      </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyImageVideo;
