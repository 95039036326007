import React, {  useState } from "react";
import img from "../../../Images/view-icon-big.png";
import img1 from "../../../Images/clicks-icon-big.png";
import img2 from "../../../Images/leads-icon-gig.png";
import img3 from "../../../Images/call-icon-big.png";
import img5 from "../../../Images/sms-icon-big.png";
import img6 from "../../../Images/email-icon-big.png";
import img7 from "../../../Images/whatsapp-icon-big.png";
import { CiMoneyBill} from "react-icons/ci";
import { IoHomeOutline, IoKeyOutline } from "react-icons/io5";
import { TbHomeStats } from "react-icons/tb";
function DashBoardState({ analytics }) {
  const [buy, setBuy] = useState("all");
  const [tab, setTab] = useState(1);
  return (
    <>
      <div className="popular_properties">
        <h5 className="heading">Analytics</h5>
        <div className="d-flex flex-wrap justify-content-between">
          <div className="btn_popular">
            <button
              onClick={() => setBuy("all")}
              style={{
                background: `${buy === "all" ? "white" : "none"}`,
                border: `${buy === "all" ? "1px solid lightgray" : "none"}`,
              }}
            >
              <IoHomeOutline /> All
            </button>
            <button
              onClick={() => setBuy("rent")}
              style={{
                background: `${buy === "rent" ? "white" : "none"}`,
                border: `${buy === "rent" ? "1px solid lightgray" : "none"}`,
              }}
            >
              <IoKeyOutline /> Rent
            </button>
            <button
              onClick={() => setBuy("buy")}
              style={{
                background: `${buy === "buy" ? "white" : "none"}`,
                border: `${buy === "buy" ? "1px solid lightgray" : "none"}`,
              }}
            >
              <CiMoneyBill /> Buy
            </button>
            <button
              onClick={() => setBuy("lease")}
              style={{
                background: `${buy === "lease" ? "white" : "none"}`,
                border: `${buy === "lease" ? "1px solid lightgray" : "none"}`,
              }}
            >
              <TbHomeStats /> Lease
            </button>
          </div>
          
        </div>
      </div>
      <div className="card_main_listing satate_dashboard mt-3">
        <div className="new-scroll-box">
          <div className="dashboard_listing_page">
            <div
              className={tab === 1 ? "new_active_tab box" : "box"}
              onClick={() => setTab(1)}
            >
              <div className="icons">
                <img src={img} alt="..." />
              </div>
              <div className="ms-2">
                <p
                  className="m-0 title"
                  style={{ color: `${tab === 1 ? "black" : ""}` }}
                >
                  Active
                </p>
                <h6>
                    {
                        buy === "all"&&
                        <>
                         {analytics?.all?.active}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.active}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.active}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.active}
                        </>
                    }
                    
                    {/* {analytics?.all?.active} */}
                    </h6>
                <p className="m-0 count">
                {
                        buy === "all"&&
                        <>
                         {analytics?.all?.active===0&&"No "}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.active===0&&"No "}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.active===0&&"No "}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.active===0&&"No "}
                        </>
                    }
                 
                   Data
                </p>
              </div>
            </div>
            <div
              className={tab === 2 ? "new_active_tab box" : "box"}
              onClick={() => setTab(2)}
            >
              <div className="icons">
                <img src={img1} alt="..." />
              </div>
              <div className="ms-2">
                <p
                  className="m-0 title"
                  style={{ color: `${tab === 2 ? "black" : ""}` }}
                >
                  Clicks
                </p>
                <h6> {
                        buy === "all"&&
                        <>
                         {analytics?.all?.clicks}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.clicks}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.clicks}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.clicks}
                        </>
                    }</h6>

                <p className="m-0 count">
                {
                        buy === "all"&&
                        <>
                         {analytics?.all?.clicks===0&&"No "}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.clicks===0&&"No "}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.clicks===0&&"No "}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.clicks===0&&"No "}
                        </>
                    }
                  {/* {analytics?.clicks === 0 && "No"} */}
                   Data
                </p>
              </div>
            </div>
            <div
              className={
                tab === 3
                  ? "new_active_tab new_long_tab d-flex"
                  : "new_long_tab d-flex"
              }
              style={{ background: "none" }}
              onClick={() => setTab(3)}
            >
              <div
                className={tab === 3 ? "new_active_tab box" : "box"}
                style={{ border: "none" }}
              >
                <div className="icons">
                  <img src={img2} alt="..." />
                </div>
                <div className="ms-2">
                  <p
                    className="m-0 title"
                    style={{ color: `${tab === 3 ? "black" : ""}` }}
                  >
                    Leads
                  </p>
                  <h6>{
                        buy === "all"&&
                        <>
                         {analytics?.all?.leads}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.leads}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.leads}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.leads}
                        </>
                    }</h6>

<p className="m-0 count">
                {
                        buy === "all"&&
                        <>
                         {analytics?.all?.leads===0&&"No "}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.leads===0&&"No "}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.leads===0&&"No "}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.leads===0&&"No "}
                        </>
                    }
                 
                   Data
                </p>
                </div>
              </div>
              <div className="box extraBox">
                <div className="icons">
                  <img src={img3} alt="..." />
                </div>
                <div className="ms-2">
                  <p className="m-0 title">Call</p>
                  <h6>{
                        buy === "all"&&
                        <>
                         {analytics?.all?.call}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.call}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.call}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.call}
                        </>
                    }</h6>

<p className="m-0 count">
                {
                        buy === "all"&&
                        <>
                         {analytics?.all?.call===0&&"No "}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.call===0&&"No "}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.call===0&&"No "}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.call===0&&"No "}
                        </>
                    }
                 
                   Data
                </p>
                </div>
              </div>
               {/* <div className="box">
                <div className="icons">
                  <img src={img5} alt="..." />
                </div>
                <div className="ms-2">
                  <p className="m-0 title">SMS</p>
                  <h6>{
                        buy === "all"&&
                        <>
                         {analytics?.all?.sms}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.sms}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.sms}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.sms}
                        </>
                    }</h6>

<p className="m-0 count">
                {
                        buy === "all"&&
                        <>
                         {analytics?.all?.sms===0&&"No "}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.sms===0&&"No "}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.sms===0&&"No "}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.sms===0&&"No "}
                        </>
                    }
                 
                   Data
                </p>
                </div>
              </div>  */}
              <div className="box extraBox">
                <div className="icons">
                  <img src={img6} alt="..." />
                </div>
                <div className="ms-2">
                  <p className="m-0 title">Email</p>
                  <h6>{
                        buy === "all"&&
                        <>
                         {analytics?.all?.email}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.email}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.email}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.email}
                        </>
                    }</h6>

<p className="m-0 count">
                {
                        buy === "all"&&
                        <>
                         {analytics?.all?.email===0&&"No "}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.email===0&&"No "}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.email===0&&"No "}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.email===0&&"No "}
                        </>
                    }
                 
                   Data
                </p>
                </div>
              </div>
              <div className="box extraBox">
                <div className="icons">
                  <img src={img7} alt="..." />
                </div>
                <div className="ms-2">
                  <p className="m-0 title">WhatsApp</p>
                  <h6>{
                        buy === "all"&&
                        <>
                         {analytics?.all?.whatsapp}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.whatsapp}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.whatsapp}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.whatsapp}
                        </>
                    }</h6>

<p className="m-0 count">
                {
                        buy === "all"&&
                        <>
                         {analytics?.all?.whatsapp===0&&"No "}
                        </>
                    }
                    {
                        buy === "rent"&&
                        <>
                         {analytics?.rent?.whatsapp===0&&"No "}
                        </>
                    }
                    {
                        buy === "buy"&&
                        <>
                         {analytics?.sell?.whatsapp===0&&"No "}
                        </>
                    }
                    {
                        buy === "lease"&&
                        <>
                         {analytics?.lease?.whatsapp===0&&"No "}
                        </>
                    }
                 
                   Data
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-0" />
        <div className="chart d-flex justify-content-center align-items-center">
          <div className="text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="39"
              viewBox="0 0 42 39"
              fill="none"
            >
              <path
                d="M9.84172 21.0821L16.3365 15.3166L20.8995 19.8809L29.3027 11.4777L31.0824 13.2587L32.6229 7.5127L26.8769 9.0519L28.3836 10.5586L20.8995 18.0427L16.3898 13.5317L8.97852 20.1097L9.84172 21.0821Z"
                fill="#1C9093"
              />
              <path
                d="M0 1.3H3.8974V27.3039H0V28.6039H16.7297L12.1147 38.4488L13.2925 39L16.1434 32.916H25.454L28.3049 39L29.4827 38.4488L24.8677 28.6039H41.6V27.3039H37.7026V1.3H41.6V0H0V1.3ZM24.8456 31.616H16.7531L18.1649 28.6039H23.4325L24.8456 31.616ZM36.4026 27.3039H5.1974V1.3H36.4026V27.3039Z"
                fill="#1C9093"
              />
            </svg>
            <h6 className="mt-3">View In-Depth Insights</h6>
            <p>
              See the number of views, clicks and leads that your listing has
              received.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashBoardState;
