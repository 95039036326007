import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Context/ContextProvider";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FiEye } from "react-icons/fi";
import { MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

function TableDashboard() {
  function formatPrice(value) {
    if (value >= 10000000) {
      // Convert to Crore
      return (value / 10000000).toFixed(2) + " Cr";
    } else if (value >= 100000) {
      // Convert to Lakh
      return (value / 100000).toFixed(2) + " Lakh";
    } else if (value >= 1000) {
      // Convert to Thousand
      return (value / 1000).toFixed(2) + " Thousand";
    } else {
      // Less than 1000, show as is
      return value.toString();
    }
  }
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const { recentProperty, getQoutaState, upgradeProperty, deleteProperty } =
    useAuth();
  const [propertyList, setPropertyList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [property_ids, setPropoertyID] = useState("");

  useEffect(() => {
    const fetchCart = async () => {
      try {
        let result = await recentProperty();
        if (result?.success) {
          let propertyData = result?.data?.data?.properties;
          setPropertyList(propertyData);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };
    const fetchData2 = async () => {
      try {
        let result = await getQoutaState();
        if (result?.success) {
          setProductData(result?.data?.data?.offer_logs);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };
    fetchCart();
    fetchData2();
    // eslint-disable-next-line
  }, []);
  const navigate = useNavigate();
  function formatDate(dateString) {
    const date = new Date(dateString);

    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  }

  return (
    <div className="add_propertey_main p-0 mt-2">
      <h5 className="heading">Recent Listings</h5>
      <div className="locationPurpos mb-4">
        <div className="listing_table">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Property</th>
                <th scope="col">Price</th>
                {/* <th scope="col">Health</th> */}
                <th scope="col ">Plateform</th>
                <th scope="col" className="text-center">
                  Clicks
                </th>
                <th scope="col" className="text-center">
                  Leads
                </th>
                <th scope="col">Posted On</th>
                <th scope="col" className="status-col">
                  Status
                </th>
                <th scope="col" className="text-center">
                  Quota
                </th>
                <th scope="col">Upgrades</th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {
                propertyList?.length?
                <>
              {propertyList?.map((item, i) => {
                if(item?.status==="active"){
                  return (
                    <tr>
                      <th scope="row" >
                        {item?.property_number}
                      </th>
                      <td className="table-title cursors" title={`${item?.title}`}>
                        <span>{item?.title}</span>
                      </td>
                      <td>{formatPrice(item?.price)}</td>
                      {/* <td><GoDotFill style={{ color: "#FFB7A0", fontSize: "20px" , marginLeft:'10px' }} /></td> */}
                      <td>
                        <span className="plateform">Pakistan Property</span>
                      </td>
                      <td className="text-center">{item?.total_clicks}</td>
                      <td
                        className="text-center cursors"
                        title={`Calls(${item?.total_calls}) , Emails(${item?.total_emails})  , SMS(${item?.total_sms}) , Whatsapp Impression(${item?.total_whatsapp_impressions})`}
                      >
                        {item?.total_leads}
                      </td>
                      <td>{item?.started_at?formatDate(item?.started_at):"-"}</td>
                      <td>
                        <span className="status orderStatus">{item?.status}</span>
                      </td>
                      <td className="text-center">{item?.quota}</td>
               
                            <td>
                              <div className="d-flex align-items-center">
                                {productData?.map((items) => {
                                  if (items?.name !== "Listing") {
                                    return (
                                      <span
                                        title={items?.name}
                                        onClick={() => {
                                          if (items?.available) {
                                            upgradeProperty({
                                              offer_id: items?.offer_id,
                                              property_id: item?.id,
                                            });
                                          }
                                        }}
                                        style={{
                                          cursor: `${
                                            items?.available
                                              ? "pointer"
                                              : "not-allowed"
                                          }`,
                                        }}
                                        className="icons"
                                      >
                                        <img src={items?.image} alt="..." />
                                      </span>
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                            </td>
  
                      <td>
                        <div className="d-flex align-items-center">
                          <a href={`${window?.location?.origin}/property/${item?.id}/${item?.title?.replace(/ /g, "_")}`} style={{color:"grey"}}  target="_blank" className="icons">
                            <FiEye />
                          </a>
                          <span className="icons">
                            <MdOutlineEdit
                              onClick={() => {
                                navigate(`/agent/add-property/${item?.id}`);
                              }}
                            />
                          </span>
                          <HiOutlineDotsVertical
                            aria-label="more"
                            id="long-button"
                            aria-controls={open1 ? "long-menu" : undefined}
                            aria-expanded={open1 ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              setPropoertyID(item?.id);
                              handleClick1(e);
                            }}
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        </div>
                      </td>
                    </tr>
                  );

                }
                return null
              })}
                </>
                :
                <tr>
                <td className="text-center p-5" colSpan={12}>
                  <span>Record not found!</span>
                </td>
              </tr>
              }

            </tbody>
          </table>
        </div>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl1}
          open={open1}
          className="locationPurpos_menu p-0"
          onClose={handleClose1}
          slotProps={{
            paper: {
              style: {
                // maxHeight: ITEM_HEIGHT * 4.5,
                width: "100px",
                padding: "0",
              },
            },
          }}
        >
          <MenuItem
            style={{ paddingY: "0" }}
            onClick={() => {
              handleClose1();
              deleteProperty(property_ids);
            }}
          >
            <div className="icons_back me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
              >
                <path
                  d="M4.63672 6.375V10.125"
                  stroke="#646C6D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.36426 6.375V10.125"
                  stroke="#646C6D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M0.545898 3.875H11.455"
                  stroke="#646C6D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.90918 3.875H6.00009H10.091V10.75C10.091 11.7856 9.17525 12.625 8.04554 12.625H3.95463C2.82496 12.625 1.90918 11.7856 1.90918 10.75V3.875Z"
                  stroke="#646C6D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.95508 2.625C3.95508 1.93464 4.5656 1.375 5.31871 1.375H6.68235C7.43549 1.375 8.04599 1.93464 8.04599 2.625V3.875H3.95508V2.625Z"
                  stroke="#646C6D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>{" "}
            Delete
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default TableDashboard;
