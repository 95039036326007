import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { RxMagnifyingGlass } from 'react-icons/rx';

export default function Banner() {
    const [openIndex, setOpenIndex] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const faqData = [
        {
            faq: [
                {
                    question: "1. What types of properties can I find on pakistan property?",
                    answer: "You can browse residential, commercial, and rental properties across various cities in pakistan.",
                },
                {
                    question: "2. How do I search for properties in a specific city or area?",
                    answer: "Use the search bar to filter properties by city, area, price range, or property type.",
                },
                {
                    question: "3. Can I list my property for sale or rent on this website?",
                    answer: `Yes, you can easily list your property by signing up and submitting the required details.`,
                },
                {
                    question: "4. Are the property listings verified?",
                    answer: `We strive to ensure all property listings are accurate and updated. However, we recommend contacting the seller or agent for verification.`,
                },
                {
                    question: "5. How can I contact the property owner or agent?",
                    answer: `Each listing includes the contact details of the seller or agent, allowing you to connect directly.`,
                },
                {
                    question: "6. How do I schedule a visit to a property I'm interested in?",
                    answer: `You can contact the property owner or agent directly through the contact details provided in the listing to arrange a visit.`
                }, {
                    question: "7. How can I register as an agent or agency?",
                    answer: "You can register by entering your name, email, password, and phone number, and selecting your role (Agent or Agency), then agreeing to the terms and conditions.",
                },
                {
                    question: "8. What is required for agency registration?",
                    answer: "Agencies must complete a verification process, which begins after purchasing a package and clearing the payment.",
                },
            ]
            , name: "General",
            id: 1
        },
        {
            faq: [

                {
                    question: "1. What is the process for adding a property?",
                    answer: `Users fill out the "Add Property" form with the required details, submit it, and wait for admin approval before the property becomes visible to buyers or renters.`,
                },
                {
                    question: "2. What happens if I add a property but don’t have an ad quota?",
                    answer: `The property will appear in the "Inactive" section, and you'll be directed to purchase an ad slot.`,
                },
                {
                    question: "3. What is the process for property approval and listing?",
                    answer: `After submission, properties require admin approval. During this time, they remain in "Pending." Once approved, they appear in "Active," and rejected properties move to "Rejected."`,
                },
                {
                    question: "4. What happens when a property’s duration expires or is deleted?",
                    answer: `Expired properties move to the "Expired" section, and deleted properties are listed under "Deleted."`,
                },
            ]
            , name: "Property Management",
            id: 1
        },
        {
            faq: [
                {
                    question: "1. What is the difference between Listing Services and Credits-Based Services?",
                    answer: "Listing Services highlight the new property listings with options like Basic, Hot, and Super-Hot listings, while Credits-Based Services are used to refresh and update already posted listings.",
                },
                {
                    question: "2. How long does a property stay visible with an ad slot?",
                    answer: "The duration of visibility depends on the type of ad slot you purchase, typically measured in the number of days specified during the listing process.",
                },
                {
                    question: "3. What does a Super-Hot Listing mean?",
                    answer: `A Super-Hot Listing prominently displays your property at the top of search results, guaranteeing maximum exposure.`,
                },
                {
                    question: "4. How do Refresh Credits work?",
                    answer: `Refresh Credits update your listing's time log, bringing it back to the top of search results to increase visibility to users`,
                },
                {
                    question: "5. Can I use credit-based services for new listings?",
                    answer: `No, Credits-Based Services are only applicable to listings that have already been posted.`,
                },

            ]
            , name: "Features and Services",
            id: 2
        }
    ];
    const [active, setActive] = useState(faqData[0].name);
    const [activeData, setActiveData] = useState(faqData[0].faq);
    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredFAQs = activeData.filter((faq) => {
        const questionMatch = faq.question.toLowerCase().includes(searchQuery);
        const answerMatch = faq.answer.toLowerCase().includes(searchQuery);
        return questionMatch || answerMatch;
    });

    return (
        <>
            <div className="container-fluid faqas_mianbox p-sm-4 px-2 py-4">
                <div style={{ display: 'flex', justifyContent: 'center' }} className="row m-0 mian-faqa">
                    <div className="container">
                        <div className="row m-0">
                            <div className="col-lg-4 col-md-4 col-12 m-0 p-0">
                                <div className="item-box my-sm-4 mt-4 mb-0   mx-0">
                                    <div className="faqs-heading">
                                        <h2 className='px-2'>Frequently Asked Questions</h2>
                                    </div>
                                </div>
                                <div className="item-box my-md-4 my-1 mx-0">
                                    <div className="first-item mb-2 me-2"></div>

                                    {faqData?.map((item) => {
                                        return (
                                            <>
                                                {active === item?.name ? (
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setActive(item?.name);
                                                            setActiveData(item?.faq);
                                                        }}
                                                        className="second-item px-2 mb-3 me-2"
                                                    >
                                                        <span className="item-line"></span> {item?.name}
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setActive(item?.name);
                                                            setActiveData(item?.faq);
                                                        }}
                                                        className="first-item my-2"
                                                    >
                                                        {item?.name}
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8 col-12 p-0 my-3">
                                <div className="faq-mian-box">
                                    <div className="faq-input">
                                        <input
                                            type="text"
                                            placeholder="Write your question here"
                                            value={searchQuery}
                                            onChange={handleSearch}
                                        />
                                        <span>
                                            <RxMagnifyingGlass />
                                        </span>
                                    </div>
                                    <div className="fa-mian-services">
                                        {filteredFAQs.length > 0 ? (
                                            filteredFAQs.map((faq, index) => (
                                                <div
                                                    key={index}
                                                    className={`faq-services my-3 ${openIndex === index ? 'active' : ''}`}
                                                >
                                                    <span
                                                        className="d-flex justify-content-between"
                                                        onClick={() => toggleFAQ(index)}
                                                    >
                                                        <h3>{faq.question}</h3>
                                                        <span style={{ color: '#1C9093' }}>
                                                            {openIndex === index ? <AiOutlineMinus /> : <AiOutlinePlus />}
                                                        </span>
                                                    </span>
                                                    {openIndex === index && (
                                                        <p style={{ marginTop: '10px' }}>{faq.answer}</p>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <p>No matching words found.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
