import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../Context/ContextProvider";
import Slider from "react-slick";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function QoutaAndCredets() {
  const { getQoutaLogs, getProductList, getQoutaState } = useAuth();
  const [header, setHeader] = useState([]);
  const [header1, setHeader1] = useState([]);
  const [data, setData] = useState([]);
  const [logs, setlogs] = useState([]);
  const [productLogs, setproductLogs] = useState([]);
  const processLogs = (logs, headers) => {
    return logs.map((log) => {
      const processedData = {};

      headers.forEach((header) => {
        const matchingQuota =
          log.quota?.filter((q) => q.offer_slug === header.offer_slug) || [];
        const matchingCredits =
          log.credits?.filter((c) => c.offer_slug === header.offer_slug) || [];

        // Combine both quota and credits under the same header
        if (matchingQuota.length > 0 || matchingCredits.length > 0) {
          processedData[header.offer_slug] = [
            ...matchingQuota,
            ...matchingCredits,
          ];
        }
      });

      return processedData;
    });
  };
  useEffect(() => {
    const fetchData = async (creditsHeaders, listingsHeaders) => {
      try {
        let result = await getQoutaLogs();

        if (result?.success) {
          const organizedLogs = await processLogs(result?.data?.data?.logs, [
            ...listingsHeaders,
            ...creditsHeaders,
          ]);
          setData(organizedLogs);
          setlogs(result?.data?.data?.logs);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };
    const fetchqouta = async () => {
      try {
        let result = await getQoutaState();

        if (result?.success) {
          setproductLogs(result?.data?.data?.offer_logs);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };
    const fetchProduct = async () => {
      try {
        let result = await getProductList();
        if (result?.success) {
          let product = result?.data?.data?.products;
          const listingsHeaders = product.filter(
            (header) => header.type === "listings"
          );
          const creditsHeaders = product.filter(
            (header) => header.type === "credits"
          );
          setHeader(listingsHeaders);
          setHeader1(creditsHeaders);

          fetchData(creditsHeaders, listingsHeaders);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };
    fetchProduct();
    fetchqouta();
    // eslint-disable-next-line
  }, []);

  const ProductCard = ({ product, log }) => (
    <div style={{ marginRight: "1rem" }} className="credits_box">
      <h6 className="m-0 p-0">
        {product.name} ({log?.total || 0})
      </h6>
      <div className="m-0 d-flex justify-content-between">
        <p className="m-0">Available</p>
        <p className="m-0">{log?.available || 0}</p>
      </div>
      <div className="m-0 d-flex justify-content-between">
        <p className="m-0">Used</p>
        <p className="m-0">{log?.used || 0}</p>
      </div>
      <div className="m-0 d-flex justify-content-between">
        <p className="m-0">Total</p>
        <p className="m-0">{log?.total || 0}</p>
      </div>
    </div>
  );

  const [isOverflowing, setIsOverflowing] = useState(false);
  const boxRef = useRef(null);

  // Check if the content overflows the parent
  useEffect(() => {
    const checkOverflow = () => {
      const box = boxRef.current;
      if (box) {
        setIsOverflowing(box.scrollWidth > box.clientWidth);
      }
    };
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  const PrevArrow = ({ onClick }) => (
    <button
      className="slick-prev slick-arrow"
      style={{ opacity: `${isOverflowing ? "1" : "0"}`, left: "-29px" }}
      onClick={onClick}
    >
      <MdKeyboardArrowLeft
        style={{ fontSize: "26px", backgroundColor: "transparent" }}
      />
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button
      className="slick-next slick-arrow"
      style={{ opacity: `${isOverflowing ? "1" : "0"}` }}
      onClick={onClick}
    >
      <MdKeyboardArrowRight
        style={{ fontSize: "26px", backgroundColor: "transparent" }}
      />
    </button>
  );

  const products = [...header, ...header1];
  console.log(products.length);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    prevArrow: <PrevArrow />, // Custom Prev Arrow
    nextArrow: <NextArrow />, // Custom Next Arrow
    slidesToShow: products.length,
    slidesToScroll: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleChange = async (value) => {
    try {
      let result = await getQoutaLogs(value);

      if (result?.success) {
        const organizedLogs = await processLogs(result?.data?.data?.logs, [
          ...header,
          ...header1,
        ]);
        setData(organizedLogs);
        setlogs(result?.data?.data?.logs);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };
  const inputRef = useRef(null);
  const handleClear = () => {
    // Clear the input value
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    // Trigger the API call with an empty value
    handleChange("");
  };
  const navigate = useNavigate("");
  return (
    <div className="add_propertey_main">
      <div className="heading_title_main d-flex justify-content-between align-items-center row m-0">
        <div className="col-sm-6 col-12 order-sm-first order-last p-0">
          <h3 className="heading m-0 iconHeading"><svg width="15"
            height="15" viewBox="0 0 13 13" stroke="#1C9093" stroke-width="0.7" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.07724 1.05539C6.0118 0.995061 5.93466 0.948816 5.85061 0.91952C5.76656 0.890224 5.67739 0.878501 5.58863 0.885078C4.05887 1.00608 2.6318 1.70227 1.59484 2.83343C0.55788 3.96458 -0.0119257 5.44665 0.000189296 6.98113C0.0123043 8.51562 0.60544 9.98851 1.66013 11.1031C2.71482 12.2178 4.15271 12.8914 5.68418 12.9882C5.81 12.996 5.9351 12.9999 6.05947 12.9999C7.58489 12.9954 9.05325 12.4194 10.1749 11.3856C11.2966 10.3518 11.9901 8.93517 12.1187 7.41517C12.1255 7.32639 12.1138 7.23717 12.0845 7.1531C12.0552 7.06902 12.0089 6.9919 11.9484 6.92656C11.8872 6.8605 11.813 6.80777 11.7305 6.77165C11.648 6.73552 11.559 6.71678 11.4689 6.71659H6.50388C6.44642 6.71659 6.3913 6.69377 6.35067 6.65313C6.31003 6.6125 6.2872 6.55738 6.2872 6.49991V1.5349C6.28707 1.44483 6.26835 1.35576 6.23222 1.27325C6.1961 1.19074 6.14334 1.11658 6.07724 1.05539ZM6.50388 7.14996H11.4689C11.4993 7.14979 11.5293 7.15602 11.5571 7.16822C11.585 7.18043 11.6099 7.19835 11.6303 7.22081C11.6503 7.24215 11.6657 7.26743 11.6754 7.29504C11.6851 7.32265 11.6889 7.35198 11.6867 7.38115C11.6011 8.45379 11.21 9.47947 10.5599 10.3369C9.90973 11.1943 9.02762 11.8476 8.01788 12.2195C7.00814 12.5914 5.91302 12.6663 4.86204 12.4354C3.81105 12.2045 2.84817 11.6775 2.08728 10.9166C1.32639 10.1558 0.799314 9.19292 0.568395 8.14194C0.337475 7.09097 0.412372 5.99584 0.784227 4.98609C1.15608 3.97634 1.80934 3.0942 2.66673 2.44401C3.52413 1.79383 4.5498 1.40278 5.62243 1.31714H5.63911C5.69261 1.31718 5.74407 1.33764 5.78299 1.37434C5.80534 1.39468 5.8232 1.41948 5.8354 1.44713C5.8476 1.47478 5.85388 1.50468 5.85384 1.5349V6.49991C5.85384 6.67232 5.92233 6.83766 6.04424 6.95956C6.16614 7.08147 6.33148 7.14996 6.50388 7.14996Z" fill="gray" />
            <path d="M12.3511 6.2836C12.441 6.28365 12.53 6.26513 12.6124 6.22921C12.6948 6.19329 12.7688 6.14074 12.83 6.07485C12.8911 6.00897 12.938 5.93116 12.9677 5.84632C12.9974 5.76147 13.0092 5.67141 13.0025 5.58177C12.8942 4.13777 12.2715 2.78052 11.2475 1.75661C10.2236 0.732699 8.86632 0.110063 7.42231 0.00182296C7.3327 -0.00488809 7.24266 0.00697528 7.15784 0.0366702C7.07302 0.0663652 6.99524 0.113252 6.92938 0.174395C6.86352 0.235538 6.81099 0.309621 6.77508 0.392004C6.73917 0.474387 6.72066 0.563296 6.7207 0.653164V5.63226C6.72093 5.80494 6.78963 5.97047 6.91173 6.09258C7.03383 6.21468 7.19937 6.28337 7.37204 6.2836H12.3511ZM7.15406 5.63226V0.653164C7.15392 0.622681 7.16017 0.592506 7.17241 0.564588C7.18465 0.536671 7.20261 0.511631 7.22513 0.491087C7.26461 0.454077 7.31664 0.433404 7.37074 0.433233C7.37638 0.433233 7.38223 0.433233 7.38786 0.433233C8.7292 0.533505 9.99004 1.11173 10.9412 2.06281C11.8924 3.01389 12.4707 4.27468 12.5711 5.61601C12.5735 5.6457 12.5697 5.67557 12.5598 5.70368C12.55 5.73179 12.5343 5.75752 12.5139 5.77917C12.4933 5.80169 12.4683 5.81965 12.4404 5.83189C12.4124 5.84414 12.3823 5.85039 12.3518 5.85024H7.37204C7.31425 5.85018 7.25884 5.8272 7.21797 5.78633C7.17711 5.74547 7.15412 5.69006 7.15406 5.63226Z" fill="gray" />
            <path d="M7.80355 5.41709C7.86101 5.41708 7.91612 5.39424 7.95674 5.3536L10.5569 2.75344C10.5964 2.71257 10.6182 2.65784 10.6177 2.60103C10.6172 2.54422 10.5944 2.48987 10.5543 2.44969C10.5141 2.40952 10.4597 2.38673 10.4029 2.38624C10.3461 2.38574 10.2914 2.40759 10.2505 2.44706L7.65036 5.04722C7.62006 5.07752 7.59943 5.11613 7.59108 5.15815C7.58272 5.20018 7.58701 5.24374 7.60341 5.28333C7.6198 5.32291 7.64757 5.35675 7.68319 5.38056C7.71882 5.40437 7.7607 5.41708 7.80355 5.41709Z" fill="gray" />
            <path d="M8.23696 3.68338C8.36552 3.68338 8.4912 3.64526 8.5981 3.57383C8.705 3.5024 8.78831 3.40088 8.83751 3.2821C8.88671 3.16332 8.89959 3.03262 8.87451 2.90653C8.84942 2.78043 8.78751 2.6646 8.6966 2.57369C8.60569 2.48278 8.48987 2.42087 8.36377 2.39579C8.23768 2.37071 8.10697 2.38358 7.9882 2.43278C7.86942 2.48198 7.76789 2.5653 7.69647 2.6722C7.62504 2.7791 7.58691 2.90478 7.58691 3.03334C7.58691 3.20574 7.6554 3.37108 7.77731 3.49299C7.89921 3.6149 8.06455 3.68338 8.23696 3.68338ZM8.23696 2.81666C8.27981 2.81666 8.3217 2.82937 8.35734 2.85318C8.39297 2.87699 8.42074 2.91083 8.43714 2.95042C8.45354 2.99002 8.45783 3.03358 8.44947 3.07561C8.44111 3.11765 8.42047 3.15625 8.39017 3.18656C8.35987 3.21686 8.32126 3.2375 8.27923 3.24586C8.2372 3.25422 8.19363 3.24993 8.15404 3.23353C8.11444 3.21713 8.0806 3.18936 8.05679 3.15372C8.03298 3.11809 8.02027 3.0762 8.02027 3.03334C8.02027 2.97587 8.0431 2.92076 8.08374 2.88013C8.12437 2.83949 8.17949 2.81666 8.23696 2.81666Z" fill="white" />
            <path d="M9.97035 4.1167C9.84179 4.1167 9.71611 4.15482 9.60921 4.22625C9.50231 4.29768 9.41899 4.3992 9.36979 4.51798C9.32059 4.63676 9.30772 4.76746 9.3328 4.89356C9.35789 5.01965 9.4198 5.13548 9.51071 5.22639C9.60162 5.3173 9.71744 5.37921 9.84354 5.40429C9.96963 5.42937 10.1003 5.4165 10.2191 5.3673C10.3379 5.3181 10.4394 5.23478 10.5108 5.12788C10.5823 5.02098 10.6204 4.89531 10.6204 4.76674C10.6204 4.59434 10.5519 4.429 10.43 4.30709C10.3081 4.18519 10.1428 4.1167 9.97035 4.1167ZM9.97035 4.98342C9.9275 4.98342 9.88561 4.97071 9.84997 4.9469C9.81434 4.92309 9.78657 4.88925 9.77017 4.84966C9.75377 4.81007 9.74948 4.7665 9.75784 4.72447C9.7662 4.68244 9.78683 4.64383 9.81714 4.61352C9.84744 4.58322 9.88605 4.56258 9.92808 4.55422C9.97011 4.54586 10.0137 4.55015 10.0533 4.56655C10.0929 4.58295 10.1267 4.61073 10.1505 4.64636C10.1743 4.68199 10.187 4.72388 10.187 4.76674C10.187 4.82421 10.1642 4.87932 10.1236 4.91996C10.0829 4.96059 10.0278 4.98342 9.97035 4.98342Z" fill="white" />
        </svg> Quota and Credits</h3>
        </div>
        <div className="col-sm-6 col-12 order-sm-last order-first mb-sm-0 mb-4 p-0 d-flex justify-content-end">
          
          <button onClick={() => navigate("/agent/buy-product")}>Buy Quota and Credits</button>
        </div>
      </div>
      <div className="content_box qouta_main mt-3">
        <div
          className="scroll-bo"
         
          ref={boxRef}
        >
          {isOverflowing ? (
            <Slider {...settings}>
              {products.map((product) => {
                const log = productLogs?.find(
                  (log) => log.offer_slug === product.offer_slug
                );
                return (
                  <div key={product.id}>
                    <ProductCard product={product} log={log} />
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div className="qouta_box_mian no-overflow">
              {products.map((product) => {
                const log = productLogs?.find(
                  (log) => log.offer_slug === product.offer_slug
                );
                return (
                  <ProductCard key={product.id} product={product} log={log} />
                );
              })}
            </div>
          )}
        </div>
        <div className="mt-3">
          <h3 className="heading" style={{ marginBottom: "18px" }}>
            Logs
          </h3>
          <div className="d-flex align-items-center">
            <div className="inp_date">
              <input
                ref={inputRef}
                type="date"
                placeholder="Select date"
                onChange={(e) => handleChange(e.target.value)}
              />
            </div>
            <button className="clearBTn"
              onClick={handleClear}
            
            >
              Clear
            </button>
          </div>
        </div>
        <div className="qouta_table_main">
          <div className="listing_table scroll-box">
            <table className="table">
              <thead>
                <tr style={{ borderColor: "white" }}>
                  <th
                    style={{
                      background: "white",
                      color: "#1C9093",
                      width: "100px",
                    }}
                    scope="col"
                  ></th>
                  <th
                    className="table_heading"
                    colSpan={header.length + 1}
                    style={{ textAlign:'center' }}
                  >
                    Quota
                  </th>
                  <th
                    className="table_heading"
                    colSpan={header1.length + 1}
                    style={{ textAlign:'center', borderLeft:'3px solid #fff'}}
                  >
                    Credits
                  </th>
                  <th
                    style={{
                      background: "white",
                      color: "#1C9093",
                      width: "100px",
                    }}
                    scope="col"
                  ></th>
                </tr>
                <tr style={{ borderColor: "white" }}>
                  <th
                    style={{
                      background: "white",
                      color: "#1C9093",
                      width: "100px",
                    }}
                    scope="col"
                  >
                    Purchase Date
                  </th>
                  {header?.map((item, index) => (
                    <th
                      key={index}
                      style={{ background: "#F0F3F8" }}
                      scope="col"
                    >
                      {item.name}
                    </th>
                  ))}
                  <th style={{ background: "#F0F3F8" }} scope="col">
                    Expiry Date
                  </th>
                  {header1?.map((item, index) => (
                    <th
                      key={index}
                      style={{ background: "#F0F3F8" }}
                      scope="col"
                    >
                      {item.name}
                    </th>
                  ))}
                  <th style={{ background: "#F0F3F8" }} scope="col">
                    Expiry Date
                  </th>
                  <th
                    style={{
                      background: "white",
                      color: "#1C9093",
                      width: "100px",
                    }}
                    scope="col"
                  >
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.length ? (
                  <>
                    {data?.map((log, index) => {
                      const totalQuantity = Object.values(log)
                        .flat()
                        .reduce((sum, item) => sum + item.quantity, 0);
                      return (
                        <tr key={index}>
                          <td>{logs[index]?.purchase_date}</td>

                          {header?.map((header) => (
                            <td key={header.name}>
                              {log[header.offer_slug]
                                ? log[header.offer_slug].map((item) => (
                                    <div key={item.product_id}>
                                      {item.quantity}
                                    </div>
                                  ))
                                : "-"}
                            </td>
                          ))}
                          <td>
                            {header?.find((header) => log[header.offer_slug])
                              ?.offer_slug &&
                            log[
                              header?.find((header) => log[header.offer_slug])
                                ?.offer_slug
                            ]?.[0]?.expiry_date
                              ? log[
                                  header?.find(
                                    (header) => log[header.offer_slug]
                                  )?.offer_slug
                                ]?.[0]?.expiry_date
                              : "-"}
                          </td>
                          {header1?.map((header) => (
                            <td key={header.offer_slug}>
                              {log[header.offer_slug]
                                ? log[header.offer_slug].map((item) => (
                                    <div key={item.product_id}>
                                      {item.quantity}
                                    </div>
                                  ))
                                : "-"}
                            </td>
                          ))}

                          <td>
                            {header1?.find((header) => log[header.offer_slug])
                              ?.offer_slug &&
                            log[
                              header1?.find((header) => log[header.offer_slug])
                                ?.offer_slug
                            ]?.[0]?.expiry_date
                              ? log[
                                  header1?.find(
                                    (header) => log[header.offer_slug]
                                  )?.offer_slug
                                ]?.[0]?.expiry_date
                              : "-"}
                          </td>

                          <td className="ps-4">
                            {totalQuantity < 10
                              ? `0${totalQuantity}`
                              : totalQuantity}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td className="text-center p-4" colSpan={12}>
                      <span>Data Not Found !</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default QoutaAndCredets;
